<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.category')"
    :size="size"
    class="w-100"
    filterable 
    clearable
  >
    <el-option
      v-for="(category, index) in data"
      :key="'category-' + index"
      :label="category.name"
      :value="category.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    category_ids: {
      default: Array,
    },
    data: {
      default: Array,
    },
  },
  watch: {
    category_ids: {
      handler: function () {
        this.selected = this.category_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
        category_list: [],
    };
  },

  mounted() {
    if (this.categories && this.categories.length === 0) 
      this.updateInventory()
        .then(res => {
          this.category_list = JSON.parse(JSON.stringify(this.categories)).filter(item => item.has_child === true)
        });
  },
  computed: {
    ...mapGetters({
      categories: "categories/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "categories/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>