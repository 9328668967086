<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.edit_m", {
                m: (title || $t("message.client")),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="beforeSubmit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div v-loading="loadingData">
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item
                    :label="$t('message.name')"
                    prop="name"
                    class="label_mini"
                  >
                    <crm-input
                      :size="'medium'"
                      :inputValue="form.name"
                      v-model="form.name"
                      :placeholder="$t('message.name')"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <!-- end col -->
                
                <el-col :span="8">
                  <crm-phone-input
                      :inputValue="form.phone" 
                      v-model="form.phone"
                      @getPhoneStatus="getPhoneStatus"
                      :form_prop="'phone'"
                      :country_code="form.phone_country_code"
                  ></crm-phone-input>
                </el-col>
                <!-- end col -->
                <el-col :span="8">
                  <el-form-item
                    :label="$t('message.country_id')"
                    prop="country_id"
                    class="label_mini"
                  >
                    <select-country
                      :size="'medium'"
                      :placeholder="columns.country_id.title"
                      :id="form.country_id"
                      v-model="form.country_id"
                    >
                    </select-country>
                  </el-form-item>
                </el-col>
                <!-- end col -->
                <el-col :span="8">
                  <el-form-item
                    :label="$t('message.email')"
                    prop="email"
                    class="label_mini"
                  >
                    <crm-input
                      :size="'medium'"
                      :inputValue="form.email"
                      v-model="form.email"
                      :placeholder="$t('message.email')"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :span="8">
                  <el-form-item
                    :label="$t('message.inn')"
                    prop="inn"
                    class="label_mini"
                  >
                    <crm-input
                      :size="'medium'"
                      :inputValue="form.inn"
                      v-model="form.inn"
                      :placeholder="$t('message.inn')"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                
                <!-- end col -->
                <el-col :span="8" v-if="!authUser.client_id">
                  <el-form-item
                    :label="$t('message.clientTypes')"
                    class="label_mini"
                  >
                    <select-client-types
                      ref="clientTypes"
                      v-model="form.clientType_ids"
                      :clientType_ids="form.clientType_ids"
                      :size="'medium'"
                      :placeholder="$t('message.clientTypes')"
                    >
                    </select-client-types>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :span="8">
                  <el-form-item
                    :label="$t('message.type')"
                    prop="type_id"
                    class="label_mini"
                  >
                    <select-client-type
                      :size="'medium'"
                      :placeholder="columns.type_id.title"
                      :id="form.type_id"
                      v-model="form.type_id"
                    >
                    </select-client-type>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <div v-show="form.type_id === 1">
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('message.gender')"
                      prop="gender"
                      class="label_mini"
                    >
                      <select-client-gender
                        :size="'medium'"
                        :placeholder="columns.gender_id.title"
                        :id="form.gender_id"
                        v-model="form.gender_id"
                      >
                      </select-client-gender>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col v-if="form.country_id == iin_countries" :span="8">
                    <el-form-item :label="$t('message.identification_type')" class="label_mini">
                      <el-switch
                        v-model="form.has_iin"
                        :active-text="$t('message.passport_iin')"
                        :inactive-text="$t('message.passport')">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col v-if="form.has_iin" :span="8">
                    <el-form-item
                      :label="$t('message.passport_iin')"
                      prop="passport_iin"
                      class="label_mini"
                    >
                      <el-input
                        :size="'medium'"
                        :maxlength="3"
                        :inputValue="form.passport_iin"
                        v-model="form.passport_iin"
                        :placeholder="$t('message.passport_iin')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col  v-if="!form.has_iin" :span="8">
                    <el-form-item
                      :label="$t('message.passport_series')"
                      prop="passport_series"
                      class="label_mini"
                    >
                      <el-input
                        :size="'medium'"
                        :maxlength="3"
                        :inputValue="form.passport_series"
                        v-model="form.passport_series"
                        :placeholder="$t('message.passport_series')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col v-if="!form.has_iin" :span="8">
                    <el-form-item
                      :label="$t('message.passport_number')"
                      prop="passport_number"
                      class="label_mini"
                    >
                      <crm-input
                        :size="'medium'"
                        :type="'number'"
                        :inputValue="form.passport_number"
                        v-model="form.passport_number"
                        :placeholder="$t('message.passport_number')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col v-if="!form.has_iin" :span="8">
                    <el-form-item
                      :label="$t('message.passport_given_by')"
                      prop="passport_given_by"
                      class="label_mini"
                    >
                      <crm-input
                        :size="'medium'"
                        :inputValue="form.passport_given_by"
                        v-model="form.passport_given_by"
                        :placeholder="$t('message.passport_given_by')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col v-if="!form.has_iin" :span="8">
                    <el-form-item 
                      prop="passport_given_date" 
                      :label="$t('message.passport_given_date')" 
                      class="label_mini"
                    >
                      <el-date-picker
                        v-model="form.passport_given_date"
                        type="date"
                        class="w-100"
                        size="medium"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :placeholder="$t('message.passport_given_date')">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                </div>

                <div v-show="form.type_id === 2">
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('message.mfo')"
                      prop="mfo"
                      class="label_mini"
                    >
                      <crm-input
                        :size="'medium'"
                        :inputValue="form.mfo"
                        v-model="form.mfo"
                        :placeholder="$t('message.mfo')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('message.okonx')"
                      prop="okonx"
                      class="label_mini"
                    >
                      <crm-input
                        :size="'medium'"
                        :inputValue="form.okonx"
                        v-model="form.okonx"
                        :placeholder="$t('message.okonx')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('message.oked')"
                      prop="oked"
                      class="label_mini"
                    >
                      <crm-input
                        :size="'medium'"
                        :inputValue="form.oked"
                        v-model="form.oked"
                        :placeholder="$t('message.oked')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('message.rkp_nds')"
                      prop="rkp_nds"
                      class="label_mini"
                    >
                      <crm-input
                        :size="'medium'"
                        :inputValue="form.rkp_nds"
                        v-model="form.rkp_nds"
                        :placeholder="$t('message.rkp_nds')"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                </div>


                <el-col :span="24">
                  <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    action="/"
                    :on-change="updateImageList"
                    list-type="picture-card"
                    accept="image/jpeg, image/png"
                    :auto-upload="false"
                  >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                </el-col>

              </el-row>
            </el-form>
          </div>
        </div>
        <!-- end app-modal__body -->

        <!-- Additional Info -->
        <!-- <contact-people ref="contactPeople"></contact-people>
        <client-account ref="clientAccount"></client-account> -->

      </div>
    </div>
  </div>
</template>
<script>
import selectClientType from "@/components/inventory/select-client-type";
import selectClientGender from "@/components/inventory/select-client-gender";
import contactPeople from "./clientPerson/contact-people";
import clientAccount from "./clientPerson/client-accounts";
import selectClientTypes from "@/components/multiSelects/multi-select-client-types";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  components: {
    selectClientType,
    selectClientGender,
    contactPeople,
    clientAccount,
    selectClientTypes,
    selectCountry,
    selectCity,
  },
  mixins: [form, drawer, show],
  props:{
    title:{
      type: String
    }
  },
  data() {
    return {
      updateImage: [],
      imageUrl: "", 
      is_phone_valid: false
    };
  },
  created() {},
  watch: {
      "form.country_id": {
          handler: async function(newVal, oldVal) {
            if(newVal == this.iin_countries && this.model.country_id != newVal){
              this.form.has_iin = true;
            }else if (this.model.country_id != newVal){
              this.form.has_iin = false;
            }else{
              this.form.has_iin = this.model.has_iin;
            }
          },
          deep: true,
          immediate: true
      },
  },
  computed: {
    ...mapGetters({
      system_settings: "auth/system_settings",
      rules: "clients/rules",
      model: "clients/model",
      columns: "clients/columns",
      authUser: "auth/user",
    }),
    iin_countries(){
      return (this.system_settings && this.system_settings.iin_countries) ? this.system_settings.iin_countries : '';
    },
  },
  methods: {
    ...mapActions({
      update: "clients/update",
      show: "clients/show",
      empty: "clients/empty",
    }),
    getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
    },
    afterOpen() {
      this.fetchData();
    },
    afterLeave() {
      this.empty();
    },
    fetchData() {
      if (!this.loadingData) {
        this.loadingData = true;
        this.show(this.selectedItem.id)
          .then((res) => {
            if (this.form.avatar) {
              this.imageUrl = this.form.image_url + '/' + this.form.avatar[0].path;
            }
            if (this.form.client_contact_people && this.form.client_contact_people.length > 0) {
              this.$refs.contactPeople.object = JSON.parse(JSON.stringify(this.form.client_contact_people));
            }
            if (this.form.client_checking_accounts && this.form.client_checking_accounts.length > 0) {
              this.$refs.clientAccount.object = JSON.parse(JSON.stringify(this.form.client_checking_accounts));
            }
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    updateImageList(file) { 
      if(file.size < 5200000){
        this.imageUrl = URL.createObjectURL(file.raw);
        this.updateImage = [];
        this.updateImage.push(file.raw);
      }else{
        this.$message({
          type: "warning",
          message: "Размер изображения должен быть меньше 5 МБ"
        });
      }
    },
    beforeSubmit(close = true){
      if(this.form.country_id != this.model.country_id ){
        this.$confirm(
          this.$t('message.client_country_changed_id_of_the_client_will_be_changed'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.submit(close);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
      }else{
        this.submit(close);
      }
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(this.is_phone_valid){

            if(this.$refs.contactPeople){
              this.form.client_contact_people = this.$refs.contactPeople.object;
            }
            if(this.$refs.clientAccount){
              this.form.client_checking_accounts = this.$refs.clientAccount.object;
            }

            let formData = new FormData();

            for (const key in this.form) {
              if (key != "avatar" && key !== 'client_contact_people' && key !== 'client_checking_accounts' && key !== 'clientType_ids') {
                if (this.form[key]) {
                  formData.append(key, this.form[key]);
                } else {
                  formData.append(key, "");
                }
              }else if(key === 'client_contact_people' || key === 'client_checking_accounts' || key === 'clientType_ids'){
                formData.append(key, JSON.stringify(this.form[key]));
              }
            }
            if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                if (this.updateImage.hasOwnProperty(key)) {
                  const element = this.updateImage[key];
                  formData.append("avatar", element);
                }
              }
            }
            formData.append('_method', 'PUT');

            this.loadingButton = true;
            this.update(formData)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.$emit('newInventoryItem',  res.data.result.data.client);
                this.parent().listChanged();
                if (close) this.close();
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          }else{
            this.$notify({
                title: this.$t('message.validation'),
                type: "error",
                offset: 130,
                message: this.$t('message.phone_number_is_not_valid')
            });
          }
       
        }
      });
    },
  },
};
</script>
