<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.product')" filterable
      :size="size" class="w-100">
      <el-option v-for="(product, index) in products" :key="'products-' + index" :label="product.name" :value="product.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      filial_id:{
        default: null
      }
    },
    watch:{
      filial_id:{
        handler: function() {
          if(this.filial_id){
            this.updateInventory(this.filial_id) 
          }
        },
        immediate: true
      },
      id:{
        handler: function() {
            this.selected = this.id;
            this.selected = null;
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    computed: {
      ...mapGetters({
        products: 'products/filialProducts'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'products/filialProducts'
      }),
      dispatch(e) {
        this.$emit('get_selected_product', e)
        this.$emit('input', e)
        this.selected = e
      },
    },
  }

</script>
