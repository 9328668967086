<template>
  <div>
    <el-select :value="selected" @input="dispatch" :multiple="multiple" :placeholder="placeholder || $t('message.directionTariff')" 
      filterable remote :remote-method="searchWithLoad" :loading="loadingData"
      :size="size" class="w-100">
      <el-option v-for="(item, index) in list" :key="'item-' + item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      from_filial_id:{
        default: null
      },
      to_filial_id:{
        default: null
      },
      show_all:{
        default: false
      },
      multiple:{
        default: false
      },
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      watchFilials:{
        handler: function() {
            if((this.from_filial_id && this.to_filial_id) || this.show_all){
              let query = {}; 
              if(!this.show_all){
                query = { 
                  from_filial_id: this.from_filial_id, 
                  to_filial_id: this.to_filial_id
                }
              }
                
              this.updateInventory(query).then( res => {
                  this.list = JSON.parse(JSON.stringify(this.inventoryItems));
                  if(this.firstUpdate){
                    this.selected = this.id;
                    this.firstUpdate = false;
                  }else{
                    this.selected = null;
                    this.$emit('input', null);
                  }
              });
            }else{
                this.list = [];
            }
        },
        deep: true,
        immediate: true
      },
    },
    data() {
      return {
        selected: null,
        firstUpdate: true,
        loadingData: false,
        list: [],
      }
    },
    computed: {
      ...mapGetters({
        inventoryItems: 'directionTariffs/inventory',
      }),
      watchFilials() {
        return this.from_filial_id + this.to_filial_id;
      }
    },
    methods: {
      ...mapActions({
        updateInventory: 'directionTariffs/inventory',
      }),
      emptyInput() {
        this.selected = null
      },
      dispatch(e) {
        this.$emit('input', e);
        this.selected = e;
        this.findAndEmit(e);
      },
      searchWithLoad(val){
        this.searchFilials(val, this);
      },
      searchFilials: _.debounce(
        async function(val, self) {
          if(val) {
            self.loadingData = true;
            let query = {search: val}; 
            if(!self.show_all){
              query = { 
                from_filial_id: self.from_filial_id, 
                to_filial_id: self.to_filial_id,
                search: val
              }
            }
            await self.updateInventory(query)
              .then(res => {
                self.list = JSON.parse(JSON.stringify(self.inventoryItems));
                self.loadingData = false;
              })
              .catch(err => {
                self.loadingData = false;
              });
          }
          
        }, 500), 
      findAndEmit(id) {
        if(id){
          let item = this.list.find(el => el.id == id);
          this.$emit('getSelectedItem', item);
        }
      },
    },
  }

</script>
