<template>
    <div class="timeline-wrap" v-loading="loadingData">
        <div class="timeline-card p-relative" >
            <div class="timeline-items d-flex f-wrap mb-5">
                <div class="timeline-items__left">
                    <div
                        class="
                            timeline-icon-circle
                            text-center text-white
                            p-relative
                        "
                    >
                        <i class="el-icon-chat-square"></i>
                    </div>
                </div>
                <div class="timeline-items__right rounded-sm alimer">
                    <el-tabs
                        v-model="activeName"
                        @tab-click="handleClick"
                        class="tab-right"
                    >
                        <el-tab-pane
                            v-for="type in types"
                            :key="type.id"
                            :label="type.name"
                            :name="type.id + '-tab'"
                            v-loading="loadingTab"
                        >
                            <div class="timeline-card-body p-4">
                                <el-form
                                    :ref="'form-' + type.id"
                                    :model="form"
                                    :rules="rules"
                                >
                                    <div class="tab-btunn">
                                        <el-form-item
                                            :label="$t('message.file')"
                                            class="label_mini d-flex"
                                        >
                                            <el-upload
                                                v-model="form.files"
                                                class="upload-demo"
                                                action="/"
                                                :before-remove="beforeRemove"
                                                :limit="file_limit"
                                                :accept="fileAccepts"
                                                :on-exceed="handleExceed"
                                                :on-remove="handleRemove"
                                                :on-change="createImageList"
                                                :file-list="fileList"
                                                :auto-upload="false"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                >
                                                    {{ $t('message.loading') }}
                                                </el-button>
                                            </el-upload>
                                        </el-form-item>

                                        <div class="btn" v-if="type.id === 7">
                                            <el-button
                                                size="small"
                                                type="warning"
                                                @click="emailOpen(type.id)"
                                            >
                                                {{ $t('message.message') }}
                                                <span style="color: red;">
                                                    ( <i class="el-icon-message" > </i> / {{ total_email_message_count }} )
                                                </span>
                                            </el-button>
                                        </div>
                                    </div>

                                    <el-form-item
                                        :label="$t('message.text')"
                                        class="label_mini"
                                    >
                                        <textarea
                                            v-model="form.text"
                                            :placeholder="$t('message.text')"
                                            value="asdasdasd"
                                            class="
                                                timeline-textarea
                                                w-100
                                                rounded-sm
                                                p-3
                                            "
                                        ></textarea>
                                    </el-form-item>
                                    
                                    

                                        <div class="text-descr d-flex" v-if="type.id === 7">
                                            <div class="mmq">
                                               <el-form-item
                                                    class="label_mini"
                                                >
                                                    <el-checkbox v-model="form.send_message" :label="$t('message.Do you want to send a message to the client')" border></el-checkbox>
                                                </el-form-item>
                                                
                                            </div>
                                            <div class="mmq" v-if="form.send_message">
                                                 <el-form-item
                                                    class="label_mini"
                                                >
                                                    <select-user-email
                                                        :size="'large'"
                                                        placeholder="User Email"
                                                        :id="form.userEmailId"
                                                        :user_id="authUser.id"
                                                        v-model="form.userEmailId"
                                                    >
                                                    </select-user-email>
                                                </el-form-item>

                                            </div>
                                        </div>

                                    <el-button
                                        v-if="permissions.some(per => per.slug == 'dealCorrespondences.create')"
                                        @click="submit(type.id)"
                                        v-loading="loadingButton"
                                        type="primary"
                                        size="medium"
                                        class="font-medium mt-3"
                                        >{{$t('message.send')}}</el-button
                                    >
                                </el-form>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>

            <div class="timeline-date d-flex justify-center p-relative mb-5">
                <div
                    class="
                        timeline-date__text
                        text-white
                        px-3
                        py-2
                        rounded-pill
                    "
                >
                    <span>{{$t('message.message')}}</span>
                </div>
            </div>
        </div>

        <div
            v-for="message in list"
            :key="message.id"
            class="timeline-card p-relative"
        >
            <div class="timeline-items d-flex f-wrap mb-5">
                <div class="timeline-items__left">
                    <div
                        class="
                            timeline-icon-circle
                            text-center text-white
                            p-relative
                        "
                        :class="message.type.colorClass"
                    >
                        <i :class="message.type.icon"></i>
                    </div>
                </div>
                <div class="timeline-items__right rounded-sm">
                    <div class="timeline-card-header p-4">
                        <p class="m-0 m-d-i">
                            <strong class="mr-2">
                                {{ message.type.name }} <span>{{ message.created_at }}</span>
                            </strong>
                          
                            <span>
                                <i :class="'el-icon-'+(message.message_exchange_status === 1 ? 'bottom' : (message.message_exchange_status === 2 ? 'top' : ''))" style="color: blue;"></i> 
                                {{ message.message_exchange_status === 1 ? message.message_to : message.message_from }} 

                                <span class="ml-2">{{ message.message_exchange_status === 1 ? message.message_source_date : (message.message_exchange_status === 2 ? message.created_at : '') }}</span>
                            
                            </span>
                        </p>
                    </div>

                    <div v-if="message.text" class="p-4 timeline-card-header">
                        <p class="lh-base font-medium m-0">
                            {{ message.text }}
                        </p>
                    </div>
                    <div class="p-4">
                        <div
                            @click="fileDownload(message.id, file.name)"
                            class="d-flex align-center mir font-medium pb-4"
                            v-for="file in message.files"
                            :key="file.id"
                        >
                            <span class="text-descr__text  text-reset"> 
                            <i class="el-icon-document-checked"></i> {{
                            file ? file.name : ""
                            }} 
                            </span>
                            <span class="text-descr__text  text-reset" 
                                > 
                                <i class="el-icon-download"></i> 
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <div
                v-if="message.id == last_id"
                class="timeline-date d-flex justify-center p-relative mb-5"
            >
                <div
                    class="
                        timeline-date__text
                        text-white
                        px-3
                        py-2
                        rounded-pill
                    "
                >
                    <span>{{$t('message.message')}}</span>
                </div>
            </div>
        </div>

        <div class="text-center">
            <el-button
                v-if="count !== list.length"
                v-loading="loadDealCorrespondenceList"
                @click="getMore()"
                type="success"
                size="small"
                round
                >{{$t('message.more')}}</el-button
            >
        </div>
        <div class="app-modal app-modal__full">
            <el-drawer
                :visible.sync="email"
                direction="rtl"
                size="70%"
                :append-to-body="true"
                :with-header="false"
                custom-class="ge"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <Email
                    :deal_id="deal_id"
                    :deal_email="deal_email"
                    :type_id="selected_type_id"
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></Email>
            </el-drawer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import child_list from "@/utils/mixins/child_list";

import selectUserEmail from "@/components/inventory/select-user-email";
import Email from "./email";

export default {
    mixins: [form, drawer, child_list],
    props: {
        deal_id: {
            default: null,
        },
        deal_email: {
            default: null,
        },
    },
    components: {
        selectUserEmail,
        Email,
    },
    data() {
        return {
            email: false,
            loadingData: false,
            selected_deal_id: null,
            activeName: "1-tab",
            fileList: [],
            updateFile: [],
            updateImage: [],
            blobType: "",
            fileName: "",
            file_limit: 10,
            selected_type_id: null,
            loadDealCorrespondenceList: false,
            loadingTab: false,
            fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
        };
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            authUser: "auth/user",
            list: "dealCorrespondences/list",
            count: "dealCorrespondences/count",
            total_email_message_count: "dealCorrespondences/total_email_message_count",
            model: "dealCorrespondences/model",
            rules: "dealCorrespondences/rules",
            types: "dealCorrespondences/types",
            last_id: "dealCorrespondences/last_id",
        }),
    },
    watch: {
        deal_id: function (val) {
            if (val) {
                this.selected_deal_id = val;
                this.getTypes();
                this.getMore();
            }
        },
    },
    methods: {
        ...mapActions({
            save: "dealCorrespondences/store",
            updateList: "dealCorrespondences/getAll",
            getTypes: "dealCorrespondences/getTypes",
            getEmailsCount: "dealCorrespondences/getEmailsCount",
            empty: "dealCorrespondences/empty",
            emptyDealCorrespondences: "dealCorrespondences/emptyList",
            downloadDealCorrespondence:"dealCorrespondences/downloadDealCorrespondence",
        }),
        afterLeave(){
            this.form = {};
            if(this.types.length > 0){
                this.activeName = JSON.parse(JSON.stringify(this.types[0].id)) + '-tab';
            }
        },
        beforeRemove(file, fileList) {
            this.form.file_path = file.raw;
        },
        handleClick(tab, event) {
            this.form = JSON.parse(JSON.stringify(this.model));
            this.updateFile = [];
            let type_id = JSON.parse(JSON.stringify(this.activeName.replace('-tab', '')));
            if(type_id == 7 && !this.loadingTab && this.deal_id){
                if(this.deal_email){
                    this.loadingTab = true;
                    this.getEmailsCount({type_id: type_id, deal_id: this.deal_id})
                        .then(res => {
                            this.loadingTab = false;
                        }).catch(err => {
                            this.$notify({
                                title: this.$t('message.error_m'),
                                type: "error",
                                offset: 130,
                                message: this.$t('message.something_wrong_with_connection_of_email')
                            });
                            this.loadingTab = false;
                        })
                }else{
                    this.$notify({
                        title: this.$t('message.error_m'),
                        type: "error",
                        offset: 130,
                        message: this.$t('message.There is no email in the transaction to search for messages!')
                    });
                }

            }
        },
        emailOpen(type_id){
            this.email = true;
            this.selected_type_id = type_id;
        },
        handleExceed(files, fileList) {
            this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
        },
        handleRemove(file, fileList){
            if(file.id){
                this.deleteFile({deal_id: this.selectedItem.id, file: file.name})
            }
            this.updateFile.forEach((element, index) => {
                if(element.name === file.name ){
                    this.updateFile.splice(index, 1);
                }
            });
        },
        submit(type_id) {
            if((this.form.send_message && this.form.userEmailId) || !this.form.send_message){

                let refName = "form-" + type_id.toString();
                if (!this.form.text && this.updateFile.length === 0) {
                    return this.$message({
                        type: "warning",
                        message: this.$t('message.Enter one from text or file'),
                    });
                }

                this.form.type_id = type_id;
                this.form.deal_id = this.selected_deal_id;
                let formData = new FormData();
                for (const key in this.form) {
                    if (this.form[key]) {
                        formData.append(key, this.form[key]);
                    } else {
                        formData.append(key, "");
                    }
                }
                if (_.size(this.updateFile) > 0) {
                    for (const key in this.updateFile) {
                        if (this.updateFile.hasOwnProperty(key)) {
                            const element = this.updateFile[key];
                            formData.append(`files[${key}]`, element);
                        }
                    }
                }
                this.loadingButton = true;
                this.dataSaved = true;
                this.save(formData)
                    .then((res) => {
                        this.loadingButton = false;
                        this.$emit('listChanged');
                        if(res.data.result.success){
                            this.$alert(res);
                            this.updateFile = [];
                            this.emptyDealCorrespondences()
                            .then(() => {
                                this.fileList = [];
                                this.getMore();
                            });
                            this.empty();
                        }else{
                            this.$notify({
                                title: this.$t('message.error_m'),
                                type: "error",
                                offset: 130,
                                message: res.data.result.message
                            });
                        }
                       
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }else{
                return this.$message({
                        type: "warning",
                        message: this.$t('message.Please select an email address if you would like to send an email'),
                    });
            }
        
        },
        getMore() {
            if (this.list.length !== this.count && !this.loadDealCorrespondenceList) {
                this.loadDealCorrespondenceList = true;
                this.loadingData = true;
                let data = {
                    deal_id: this.selected_deal_id,
                    last_id: this.last_id,
                };
                this.updateList(data)
                    .then(() => {
                        this.loadDealCorrespondenceList = false;
                        this.loadingData = false;
                    })
                    .catch((err) => {
                        this.loadDealCorrespondenceList = false;
                        this.loadingData = false;
                    });
            }
            if (this.count === 0) {
                let data = {
                    deal_id: this.selected_deal_id,
                    last_id: this.last_id,
                };
                this.updateList(data);
            }
        },
        createImageList(file, fileList) {
            if (file.size > 5000000) {
                fileList.pop();
                return this.$message({
                    type: "warning",
                    message: this.$t('message.file_size_limit_warning', {number: 5}),
                    });
            }
            if(this.updateFile.some(item => item.name === file.name && item.size === file.size)) {
                this.updateFile.forEach((element, index) => {
                    if(element.name === file.name && element.size === file.size){
                        fileList.pop();
                        return this.$message({
                            type: "warning",
                            message: this.$t('message.file_already_exists'),
                        });
                    } 
                });
            }else{
                this.updateFile.push(file.raw);
            }       
        },
        fileDownload(dealCorrespondence_id, fileName) {
            let data = { dealCorrespondence_id: dealCorrespondence_id, file: fileName };
            this.downloadDealCorrespondence(data)
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
    },
};
</script>


<style>
</style>
