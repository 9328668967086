<template>
    <div class="home" style="margin-bottom: 50px" >
        <div
            class="
                dashboard-main-bg
                dashboard-main-bg__full
                dashboard-main-bg_image-filter
                dashboard-main-bg_animated
            "
        > </div>
        <div class="">
            <div v-loading="loadingData">
                <div class="filter-filyal  mb-3">
                    <el-form
                        ref="form"
                        :model="filterForm"
                        class="d-flex custom__justify el-form-item-0"
                    >
                        <div class="d-flex"> 
                            <el-form-item :class="mode ? 'select__formday' : 'select__formnight'">
                                <select-filial
                                    :size="'medium'"
                                    :placeholder="$t('message.filial')"
                                    :id="filterForm.filial_id"
                                    v-model="filterForm.filial_id"
                                >
                                </select-filial>
                            </el-form-item>
                            <el-form-item :class="mode ? 'select__formday' : 'select__formnight'" v-if="user.is_super_admin">
                                <select-user
                                    :size="'medium'"
                                    :placeholder="$t('message.user')"
                                    :id="filterForm.user_id"
                                    v-model="filterForm.user_id"
                                >
                                </select-user>
                            </el-form-item>
                            <el-form-item :class="mode ? 'select__formday' : 'select__formnight'">
                                <select-client
                                    :size="'medium'"
                                    :placeholder="$t('message.client')"
                                    :id="filterForm.client_id"
                                    :company_id="filterForm.company_id"
                                    v-model="filterForm.client_id"
                                >
                                </select-client>
                            </el-form-item>
                        </div>
                        <div>
                            <select-day v-model="allPeriodChange"  />
                        </div>
                    </el-form>
                </div>

                <div class="">
                    <div class="grid-item bg-pr">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <div class="sausage-chart">
                                    <div
                                        class="sausage-chart__item text-center"
                                    >
                                        <div
                                            class="
                                                sausage-chart__item_haeder
                                                pl-1
                                                pr-1
                                            "
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_haeder_top
                                                    text-white
                                                "
                                            ></div>
                                            <span
                                            
                                                class="
                                                    sausage-chart__item_haeder_fot
                                                    text-white
                                                "
                                            >
                                            </span>
                                            <div
                                                class="
                                                mobile__version
                                                    sausage-chart__item-header-color
                                                "
                                            ></div>
                                        </div>
                                        <div
                                            class="
                                                sausage-chart__item_body
                                                bg-none
                                            "
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                "
                                            >
                                                + {{ filterOptions.map(item => item.count).reduce((a, b) => parseInt(a) + parseInt(b), 0) }}
                                            </div>
                                            <span
                                            
                                                class="
                                                    sausage-chart__item_body_fot
                                                    text-white
                                                "
                                            >
                                                <!-- something -->
                                            </span>
                                        </div>
                                        <div
                                            class="
                                                sausage-chart__item_footer
                                                pl-1
                                                pr-1
                                            "
                                        >
                                            <div
                                                class="
                                                    sausage-chart__item-header-color
                                                "
                                            ></div>
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_footer_top
                                                    text-white
                                                "
                                            >
                                                <!-- something -->
                                            </div>
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_footer_top
                                                    text-white
                                                "
                                            >
                                               <!-- something -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- sausage-chart__item end -->

                                    <div
                                        class="sausage-chart__item text-center"
                                        v-for="(filterOption, index) in filterOptions"
                                        :key="filterOption.id"
                                    >
                                        <div
                                            class="
                                                sausage-chart__item_haeder
                                                pl-1
                                                pr-1
                                            "
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_haeder_top
                                                    text-white
                                                "
                                            >
                                                {{ filterOption.title }}
                                            </div>
                                        </div>
                                        <div
                                            class="sausage-chart__item_body"
                                            :class="stageColor(filterOption.id, index)"
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                "
                                            >
                                                {{ filterOption.count }}
                                            </div>
                                            <span
                                            
                                                class="
                                                    sausage-chart__item_body_fot
                                                    text-white
                                                "
                                            >
                                            <!-- should be money -->
                                                {{
                                                    filterOption.count
                                                }}
                                            </span>
                                        </div>
                                        <div
                                            class="
                                                sausage-chart__item_footer
                                                pl-1
                                                pr-1
                                            "
                                        >
                                            <div
                                                class="
                                                    sausage-chart__item-header-color
                                                "
                                                :style="
                                                    (index+1) === filterOptions.length
                                                        ? 'background-color: rgba(0, 197, 97, 0.7)'
                                                        : 'background-color: #009bf9ad'
                                                "
                                            ></div>
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_footer_top
                                                    text-white
                                                "
                                            >
                                                {{ filterOption.count }} {{ $t('message.deal_c') }}
                                            </div>
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_footer_top
                                                    text-white
                                                "
                                            >
                                            <!-- should be money -->
                                                <!-- {{ filterOption.count }} -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- sausage-chart__item end -->

                                </div>
                            </el-col>
                            <!-- end col  -->
                        </el-row>
                        <!-- end row  -->
                    </div>
                    <!-- grid-item end -->
                    <!-- end row  -->
                </div>
                <!-- end  -->

                <div class="mt-3">
                    <el-row :gutter="10">
                        <el-col :xs="12" :sm="24" :md="12" :lg="4" :xl="4">
                            <div class="task-home-two-left">
                                    <div>
                                        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3">
                                            
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                {{ taskCounts.delayed }}
                                            </div>
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                    {{ $t('message.overdue_tasks') }}
                                                </span>
                                            </h4>
                                            
                                        </div>
                                    </div>
                                    <!-- end col -->

                                        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 ">
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                {{ taskCounts.finishedToday }}
                                            </div>
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                    {{ $t('message.completed_tasks_for_today') }}
                                                </span>
                                            </h4>
                                        </div>
                                    <!-- end col -->
                            </div>
                        </el-col>
                        <el-col :xs="12" :sm="24" :md="12" :lg="4" :xl="4"> 
                             <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3">
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                {{ taskCounts.unFinished }}
                                            </div>
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                    {{ $t('message.task_to_complete') }}
                                                </span>
                                            </h4>
                                        </div>
                                    <!-- end col -->

                                    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3">
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                {{ taskCounts.finished }}
                                            </div>
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                   {{ $t('message.completed_tasks') }}
                                                </span>
                                            </h4>
                                    </div>
                        </el-col>
                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-center h__responssive">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.deal_source') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="fullSize = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="sourcePeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    
                                    <div class="fixed__pos">
                                        <div v-show="fullSize" :class="[fullSize ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                                            <div class="graph__header">
                                                <div class="filter_home">
                                                    <el-radio-group
                                                        v-model="sourcePeriod"
                                                        size="medium"
                                                        class=""
                                                    >
                                                        <el-radio-button label="weekly">
                                                            {{
                                                                $t("message.weekly")
                                                            }}</el-radio-button
                                                        >
                                                        <el-radio-button label="monthly"
                                                            >{{ $t("message.monthly") }}
                                                        </el-radio-button>
                                                        <el-radio-button label="yearly"
                                                            >{{ $t("message.yearly") }}
                                                        </el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                                                    <a @click="fullSize = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                                        <span class="graph__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <chartMultiple
                                            :filterForm="filterForm"
                                            :size="600"
                                            :key="update"
                                            :period="sourcePeriod"
                                        />
                                        </div>
                                    </div>
                                    <chartMultiple
                                        :filterForm="filterForm"
                                        :period="sourcePeriod"
                                    />
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="mb-5">
                                        <div class="filterblock_s">
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                    {{ $t('message.deals_by_managers') }}
                                                </span>
                                            </h4>
                                            <div class="filter_home">
                                                <el-radio-group
                                                    v-model="userDeals"
                                                    size="medium"
                                                    class=""
                                                >
                                                    <el-radio-button
                                                        label="weekly"
                                                    >
                                                        {{
                                                            $t("message.weekly")
                                                        }}</el-radio-button
                                                    >
                                                    <el-radio-button
                                                        label="monthly"
                                                        >{{
                                                            $t(
                                                                "message.monthly"
                                                            )
                                                        }}
                                                    </el-radio-button>
                                                    <el-radio-button
                                                        label="yearly"
                                                        >{{
                                                            $t("message.yearly")
                                                        }}
                                                    </el-radio-button>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div
                                            v-if="usersDeals.length > 0"
                                            class="
                                                d-flex
                                                f-between
                                                align-center
                                            "
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                {{ parseFloat(usersDeals.reduce((a, b) => parseFloat(a) + (parseFloat(b.deal_count) || 0), 0)) }}
                                            </div>
                                            <div>
                                                <div
                                                    class="
                                                        dashboard-tile__item-bottom-small-count
                                                        js-resize-txt
                                                        mt-0
                                                    "
                                                >
                                                    {{ parseFloat(usersDeals.reduce((a, b) => parseFloat(a) + (parseFloat(b.money_amount) || 0), 0)) | formatMoney(1) }}
                                                </div>
                                                <div
                                                    class="
                                                        dashboard-tile__item-bottom-subcaption
                                                        mt-0
                                                    "
                                                >
                                                    {{ $t("message.per") }}
                                                    {{
                                                        userDeals === "weekly"
                                                            ? $t(
                                                                  "message.weekly"
                                                              )
                                                            : ""
                                                    }}
                                                    {{
                                                        userDeals === "monthly"
                                                            ? $t(
                                                                  "message.monthly"
                                                              )
                                                            : ""
                                                    }}
                                                    {{
                                                        userDeals === "yearly"
                                                            ? $t(
                                                                  "message.yearly"
                                                              )
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="task-scroll-khan"
                                        v-loading="loadingUsersDeals"
                                        v-if="usersDeals.length > 0"
                                    >
                                        <div
                                            class="
                                                itme-task-menjra
                                                mb-4
                                                progress-color-lin
                                            "
                                            v-for="user in usersDeals"
                                            :key="user.id"
                                        >
                                            <span class="input--label d-block"
                                                >{{ user.name }}
                                                <span style="margin-left: 10px" ></span >
                                                - {{ user.money_amount | formatMoney }}
                                                <span style="margin-left: 10px" ></span >
                                            </span>
                                            <el-progress
                                                :percentage="parseFloat(user.percentage)"
                                            ></el-progress>
                                        </div>
                                    </div>
                                    <div v-if="usersDeals.length === 0" class="eror">
                                        <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                                        <h1>
                                            {{ $t('message.no_data_yet') }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-left">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s mb-3">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.goals') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <el-radio-group
                                                v-model="dealGoalsPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            d-flex
                                            align-center
                                            justify-center
                                            mb-3
                                        "
                                    >
                                        <el-radio-group
                                            v-model="dealGoalsByAmounts"
                                        >
                                            <el-radio-button class="goal__button" label="by_count">{{
                                                $t("message.by_count")
                                            }}</el-radio-button>
                                            <el-radio-button
                                                class="goal__button"
                                                label="by_money_amount"
                                                >{{
                                                    $t(
                                                        "message.by_money_amount"
                                                    )
                                                }}</el-radio-button
                                            >
                                            <el-radio-button
                                                class="goal__button"
                                                label="by_profit"
                                                >{{
                                                    $t("message.by_profit")
                                                }}</el-radio-button
                                            >
                                        </el-radio-group>
                                    </div>
                                    <!-- <chartMultiple /> -->
                                    <div class="el-progress-ceter">
                                        <el-progress
                                            type="dashboard"
                                            :percentage="
                                                dealGoalsForm.percentage
                                            "
                                            :color="colors"
                                            :stroke-width="16"
                                        ></el-progress>
                                    </div>
                                    <div class="summa-progress text-center">
                                        <div
                                            class="
                                                sausage-chart__item_body_top
                                                font18
                                                mt-2
                                                mb-2
                                                colorGr
                                            "
                                        >
                                            {{
                                                dealGoalsForm.amount +
                                                " " +
                                                (dealGoalsForm.symbol ? dealGoalsForm.symbol : '')
                                            }}
                                        </div>
                                        <div
                                            class="
                                                sausage-chart__item-header-color
                                                pl-5
                                                pr-5
                                            "
                                            style="
                                                background-color: #99ccff;
                                                width: 70%;
                                            "
                                        ></div>
                                        <div
                                            class="
                                                dashboard-tile__item-bottom-subcaption
                                                line-height20
                                                mt-0
                                            "
                                        >
                                            {{ $t('message.goal_in') }}
                                            {{
                                                $t(
                                                    "message." + dealGoalsPeriod
                                                ) +
                                                " " +
                                                dealGoalsForm.fact_amount +
                                                " " +
                                                (dealGoalsForm.symbol ? dealGoalsForm.symbol : '')
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="mb-5">
                                        <div class="filterblock_s">
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                    {{ $t('message.deals_by_country') }}
                                                </span>
                                            </h4>
                                            <div class="filter_home">
                                                <el-radio-group
                                                    v-model="countryDealsPeriod"
                                                    size="medium"
                                                    class=""
                                                >
                                                    <el-radio-button
                                                        label="weekly"
                                                    >
                                                        {{
                                                            $t("message.weekly")
                                                        }}</el-radio-button
                                                    >
                                                    <el-radio-button
                                                        label="monthly"
                                                        >{{
                                                            $t(
                                                                "message.monthly"
                                                            )
                                                        }}
                                                    </el-radio-button>
                                                    <el-radio-button
                                                        label="yearly"
                                                        >{{
                                                            $t("message.yearly")
                                                        }}
                                                    </el-radio-button>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div
                                            v-if="countryDeals.length > 0"
                                            class="
                                                d-flex
                                                f-between
                                                align-center
                                            "
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                {{ countryDeals.length > 0 ? countryDeals.map(item => item.count).reduce((prev, next) => prev + next) : 0 }}
                                            </div>
                                            <div>
                                                <div
                                                    class="
                                                        dashboard-tile__item-bottom-small-count
                                                        js-resize-txt
                                                        mt-0
                                                    "
                                                >
                                                    {{
                                                        countryDeals.length > 0 ? totalOfCounryDealsMoneyAmount : 0
                                                            | formatMoney
                                                    }}
                                                </div>
                                                <div
                                                    class="
                                                        dashboard-tile__item-bottom-subcaption
                                                        mt-0
                                                    "
                                                >
                                                    {{ $t("message.per") }}
                                                    {{
                                                        countryDealsPeriod ===
                                                        "weekly"
                                                            ? $t(
                                                                  "message.weekly"
                                                              )
                                                            : ""
                                                    }}
                                                    {{
                                                        countryDealsPeriod ===
                                                        "monthly"
                                                            ? $t(
                                                                  "message.monthly"
                                                              )
                                                            : ""
                                                    }}
                                                    {{
                                                        countryDealsPeriod ===
                                                        "yearly"
                                                            ? $t(
                                                                  "message.yearly"
                                                              )
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div  v-if="countryDeals.length === 0" class="eror">
                                            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                                            <h1>
                                                {{ $t('message.no_data_yet') }}
                                            </h1>
                                        </div>
                                    </div>

                                    <div
                                        class="task-scroll-khan"
                                        v-loading="loadingUsersDeals"
                                    >
                                        <div
                                            class="
                                                itme-task-menjra
                                                mb-4
                                                progress-color-lin
                                            "
                                            v-for="country in countryDeals"
                                            :key="country.id"
                                        >
                                            <span class="input--label d-block"
                                                >{{ country.name }}<span
                                                    style="margin-left: 10px"
                                                ></span
                                                >-
                                                {{
                                                    country.money_amount
                                                        | formatMoney
                                                }}<span
                                                    style="margin-left: 10px"
                                                ></span
                                                >- (
                                                {{
                                                    country.profit | formatMoney
                                                }}
                                                )</span
                                            >
                                            <el-progress
                                                :percentage="Math.round(country.money_amount * 100 / totalOfCounryDealsMoneyAmount)"
                                            ></el-progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{
                                                    $t("message.sale_by_period")
                                                }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="saleGraph = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="saleByPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="fixed__pos">
                                        <div v-show="saleGraph" :class="[saleGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                                            <div class="graph__header">
                                                <div class="filter_home">
                                                    <el-radio-group
                                                        v-model="saleByPeriod"
                                                        size="medium"
                                                        class=""
                                                    >
                                                        <el-radio-button label="weekly">
                                                            {{
                                                                $t("message.weekly")
                                                            }}</el-radio-button
                                                        >
                                                        <el-radio-button label="monthly"
                                                            >{{ $t("message.monthly") }}
                                                        </el-radio-button>
                                                        <el-radio-button label="yearly"
                                                            >{{ $t("message.yearly") }}
                                                        </el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                                                    <a @click="saleGraph = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                                        <span class="graph__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <ShareGrossProfit
                                                :filterForm="filterForm"
                                                :period="saleByPeriod"
                                                :key="update"
                                            ></ShareGrossProfit>
                                        </div>
                                    </div>
                                    <ShareGrossProfit
                                        :filterForm="filterForm"
                                        :period="saleByPeriod"
                                    ></ShareGrossProfit>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="mb-5">
                                        <div class="filterblock_s">
                                            <h4
                                                class="
                                                    dashboard-tile__item-title
                                                    short
                                                "
                                            >
                                                <span
                                                
                                                    class="
                                                        dashboard-tile__item-title-txt
                                                        text-white
                                                    "
                                                >
                                                    Активность
                                                    {{ $t("message.per") }}
                                                    {{
                                                        activityPeriod ===
                                                        "weekly"
                                                            ? $t(
                                                                  "message.weekly"
                                                              )
                                                            : ""
                                                    }}
                                                    {{
                                                        activityPeriod ===
                                                        "monthly"
                                                            ? $t(
                                                                  "message.monthly"
                                                              )
                                                            : ""
                                                    }}
                                                    {{
                                                        activityPeriod ===
                                                        "yearly"
                                                            ? $t(
                                                                  "message.yearly"
                                                              )
                                                            : ""
                                                    }}
                                                </span>
                                            </h4>
                                            <div class="filter_home">
                                                <el-radio-group
                                                    v-model="activityPeriod"
                                                    size="medium"
                                                    class=""
                                                >
                                                    <el-radio-button
                                                        label="weekly"
                                                    >
                                                        {{
                                                            $t("message.weekly")
                                                        }}</el-radio-button
                                                    >
                                                    <el-radio-button
                                                        label="monthly"
                                                        >{{
                                                            $t(
                                                                "message.monthly"
                                                            )
                                                        }}
                                                    </el-radio-button>
                                                    <el-radio-button
                                                        label="yearly"
                                                        >{{
                                                            $t("message.yearly")
                                                        }}
                                                    </el-radio-button>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div
                                            class="
                                                d-flex
                                                f-between
                                                align-center
                                            "
                                        >
                                            <div
                                            
                                                class="
                                                    sausage-chart__item_body_top
                                                    text-white
                                                    mt-2
                                                    mb-2
                                                "
                                            >
                                                + {{ activityMark }}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="
                                            itme-task-menjra
                                            mb-4
                                            progress-color-lin
                                        "
                                        v-for="activity in activities"
                                        :key="activity.id"
                                    >
                                        <span class="input--label d-block">
                                            <i :class="activity.iconClass"></i>
                                            {{ activity.name }}
                                            <b>- {{ activity.count }}</b>
                                        </span>
                                        <el-progress
                                            class="none-icon-p"
                                            :percentage="
                                                activity.percentage < 0
                                                    ? -activity.percentage
                                                    : activity.percentage
                                            "
                                            :status="
                                                activity.percentage > 0
                                                    ? 'success'
                                                    : 'exception'
                                            "
                                        ></el-progress>
                                    </div>
                                    <!-- end itme-task-menjra -->
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="mb-5">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.latest_files') }}
                                            </span>
                                        </h4>
                                        <div
                                            v-if="latestFiles.length === 0"
                                            class="text-block-tr"
                                        >
                                            <div class="eror">
                                                <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                                                <h1> 
                                                    <i class="el-icon-warning"></i>
                                                   <span> {{ $t('message.no_files') }} </span>
                                                </h1>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div
                                        class="doc-style"
                                        v-for="file in latestFiles"
                                        :key="file.id"
                                    >
                                        <span
                                            @click="downloadLatestFile(file)"
                                            class="spancha d-block w50"
                                        >
                                            <i class="el-icon-document"></i>
                                            {{
                                                file
                                                    ? file.name.substring(0, 20)
                                                    : ""
                                            }}
                                            <!-- ДокументыСделкиСделки.jpg -->
                                        </span>
                                        <span
                                            @click="downloadLatestFile(file)"
                                            class="spancha d-block w20"
                                        >
                                            {{ $t('message.deals') }} - {{ file.deal_id }}
                                        </span>
                                        <span
                                            @click="downloadLatestFile(file)"
                                            class="spancha d-block w20"
                                        >
                                            {{ file ? file.date : "" }}
                                        </span>
                                        <span class="w10">
                                            <i
                                                @click="showDeal(file.deal_id)"
                                                class="el-icon-view style-view"
                                            ></i>
                                        </span>
                                    </div>

                                    <!-- end doc-style -->
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="24" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.deal_delete_reason') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="deleteDeal = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="reasonForDelete"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="fixed__pos">
                                        <div v-show="deleteDeal" :class="[deleteDeal ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                                            <div class="graph__header">
                                                <div class="filter_home">
                                                    <el-radio-group
                                                        v-model="reasonForDelete"
                                                        size="medium"
                                                        class=""
                                                    >
                                                        <el-radio-button label="weekly">
                                                            {{
                                                                $t("message.weekly")
                                                            }}</el-radio-button
                                                        >
                                                        <el-radio-button label="monthly"
                                                            >{{ $t("message.monthly") }}
                                                        </el-radio-button>
                                                        <el-radio-button label="yearly"
                                                            >{{ $t("message.yearly") }}
                                                        </el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                                                    <a @click="deleteDeal = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                                        <span class="graph__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <DeletTask
                                                :filterForm="filterForm"
                                                :period="reasonForDelete"
                                                :size="500"
                                                :key="update"
                                            ></DeletTask>
                                        </div>
                                    </div>
                                    <DeletTask
                                        :filterForm="filterForm"
                                        :period="reasonForDelete"
                                    ></DeletTask>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="24" :lg="16" :xl="16">
                            <div class="task-home-two-center h__responssive">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.payments_by_period') }}

                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="sellGraph = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="paymentPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    
                                    <PaymentChart
                                        :filterForm="filterForm"
                                        :period="paymentPeriod"
                                        @dataPointSelection="
                                            paymentPointSelection
                                        "
                                        @dataPointAmount="paymentPointAmount"
                                    ></PaymentChart>
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="24" :lg="8" :xl="8">
                            <div class="task-home-two-right">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4>
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.dealConditions') }}

                                            </span>
                                        </h4>
                                        <div class="filter_column chart-f">
                                            <div class="d-flex">
                                                <select-country
                                                    :size="'mini'"
                                                    :placeholder="$t('message.country')"
                                                    :id="filterPayment.country_id"
                                                    v-model="filterPayment.country_id"
                                                >
                                                </select-country>
                                                <el-date-picker
                                                    class="w-100"
                                                    size="mini"
                                                    v-model="filterPayment.day"
                                                    type="date"
                                                    placeholder="По дням"
                                                    format="yyyy-MM-dd"
                                                    value-format="yyyy-MM-dd"
                                                ></el-date-picker>
                                            </div>
                                            <div class="d-flex">
                                                <el-date-picker
                                                    class="w-100"
                                                    size="mini"
                                                    v-model="filterPayment.month"
                                                    type="month"
                                                    placeholder="По месяцам"
                                                    format="yyyy-MM"
                                                    value-format="yyyy-MM"
                                                ></el-date-picker>
                                                <el-date-picker
                                                    class="w-100"
                                                    size="mini"
                                                    v-model="filterPayment.year"
                                                    type="year"
                                                    placeholder="По годам"
                                                    format="yyyy"
                                                    value-format="yyyy"
                                                ></el-date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <MoneyType :filterForm="filterPayment"></MoneyType>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="24" :lg="16" :xl="16">
                            <div class="task-home-two-center h__responssive">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.payments_by_period') }}

                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="dealTerm = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="dealPaymentTermsPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="fixed__pos">
                                        <div v-show="dealTerm" :class="[dealTerm ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                                            <div class="graph__header">
                                                <div class="filter_home">
                                                    <el-radio-group
                                                        v-model="dealPaymentTermsPeriod"
                                                        size="medium"
                                                        class=""
                                                    >
                                                        <el-radio-button label="weekly">
                                                            {{
                                                                $t("message.weekly")
                                                            }}</el-radio-button
                                                        >
                                                        <el-radio-button label="monthly"
                                                            >{{ $t("message.monthly") }}
                                                        </el-radio-button>
                                                        <el-radio-button label="yearly"
                                                            >{{ $t("message.yearly") }}
                                                        </el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                                                    <a @click="dealTerm = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                                        <span class="graph__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <DealPaymentTerms
                                                :filterForm="filterForm"
                                                :period="dealPaymentTermsPeriod"
                                                :key="update"
                                            ></DealPaymentTerms>
                                        </div>
                                    </div>
                                    <DealPaymentTerms
                                        :filterForm="filterForm"
                                        :period="dealPaymentTermsPeriod"
                                    ></DealPaymentTerms>
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="12" :lg="8" :xl="8">
                            <div class="task-home-two-center h__responssive">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.selling_product_statistic') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <el-radio-group
                                                v-model="productSellStatisticsPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <ProductSellStatisticsChart
                                        :filterForm="filterForm"
                                        :period="productSellStatisticsPeriod"
                                    ></ProductSellStatisticsChart>
                                </div>
                            </div>
                        </el-col>
                    </el-row>                    
                    <el-row :gutter="10">                   
                        <el-col :sm="24" :md="24" :lg="24" :xl="24">
                            <div  :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer  mb-3">
                                <div class="task-home-two-center h__responssive" >
                                     <div class="filterblock_s mb-5">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.conter_agent') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="counterGraph = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="clientChartPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                        
                                    </div>
                                    <div class="fixed__pos">
                                        <div v-show="counterGraph" :class="[counterGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                                            <div class="graph__header">
                                                <div class="filter_home">
                                                    <el-radio-group
                                                        v-model="clientChartPeriod"
                                                        size="medium"
                                                        class=""
                                                    >
                                                        <el-radio-button label="weekly">
                                                            {{
                                                                $t("message.weekly")
                                                            }}</el-radio-button
                                                        >
                                                        <el-radio-button label="monthly"
                                                            >{{ $t("message.monthly") }}
                                                        </el-radio-button>
                                                        <el-radio-button label="yearly"
                                                            >{{ $t("message.yearly") }}
                                                        </el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                                                    <a @click="counterGraph = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                                        <span class="graph__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="agents__flex">
                                                <div class="agents__full">
                                                    <counter-column
                                                        :key="update" 
                                                        :filterForm="filterForm"
                                                        :period="clientChartPeriod" 
                                                        @getClientColors="getClientColors" 
                                                    />
                                                </div>
                                                <div class="agents__full task-scroll-khan">
                                                    <table class="agents__table">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th>{{ $t('message.deals') }}</th>
                                                                <th>{{ $t('message.tasks') }}</th>
                                                                <th>{{ $t('message.money_amount') }}</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(client, index) in clientStatistics" :key="client.id">
                                                                <td>
                                                                    <span class="agents__circle" :style="'background:'+clientColors[index]"></span>
                                                                </td>
                                                                <td>
                                                                    <span> {{ client.name }} </span>
                                                                </td>
                                                                <td class="agents__center">{{ client.deal_count }}</td>
                                                                <td class="agents__center">{{ client.task_count }}</td>
                                                                <td class="agents__center">{{ client.money_amount | formatMoney(2)}}</td>
                                                                <td class="agents__view"><span @click="showSelectedClient(client)" class="el-icon-view"></span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div class="agents__box">
                                        <div class="agents__half">
                                            <counter-column 
                                                :filterForm="filterForm"
                                                :period="clientChartPeriod" 
                                                @getClientColors="getClientColors" 
                                            />
                                        </div>
                                        <div class="agents__half task-scroll-khan">
                                            <table class="agents__table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>{{ $t('message.deals') }}</th>
                                                        <th>{{ $t('message.tasks') }}</th>
                                                        <th>{{ $t('message.money_amount') }}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(client, index) in clientStatistics" :key="client.id">
                                                        <td>
                                                            <span class="agents__circle" :style="'background:'+clientColors[index]"></span>
                                                        </td>
                                                        <td>
                                                            <span> {{ client.name }} </span>
                                                        </td>
                                                        <td class="agents__center">{{ client.deal_count }}</td>
                                                        <td class="agents__center">{{ client.task_count }}</td>
                                                        <td class="agents__center">{{ client.money_amount | formatMoney(2)}}</td>
                                                        <td class="agents__view"><span @click="showSelectedClient(client)" class="el-icon-view"></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row> 
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="24" :lg="8" :xl="8">
                            <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                <div class="task-home-two-center h__responssive" >
                                    <div class="filterblock_s mb-5">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                            
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                                {{ $t('message.top_deals') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            
                                            <el-radio-group
                                                v-model="topSuccessfulDealsPeriod"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div v-if="topSuccessfulDeals.length > 0"
                                        class="task-scroll-khan"
                                    >
                                        <div
                                            class="
                                                itme-task-menjra
                                                mb-4
                                                progress-color-lin
                                            "
                                            v-for="(deal, index) in topSuccessfulDeals"
                                            :key="deal.id"
                                        >
                                            <div class="input--label user__box">
                                                <div class="d-flex">
                                                    <div class="circle__user"> {{index + 1}} </div>
                                                    <div class="user__deal">
                                                        <span>{{ deal.name }}</span>
                                                        <span>{{deal.money_amount | formatMoney(2)}}  <span class="ml-5" v-if="deal.profit">( {{ deal.profit | formatMoney(2)}} )</span> </span>
                                                    </div>
                                                </div>
                                                <div class="user__name"> 
                                                    <span>{{ deal.username }}</span>
                                                </div>
                                            </div>
                                            <div class="user__footer">
                                                <span> {{ $t('message.from_filial') }}: {{ deal.from_filial }}</span>
                                                <span> {{ $t('message.to_filial') }}: {{ deal.to_filial }}</span>
                                            </div>
                                            <hr style="color: #fff">
                                        </div>
                                    </div>
                                    <div v-else class="eror">
                                        <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                                        <h1>
                                            {{ $t('message.no_data_yet') }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </el-col>

                        <el-col :sm="24" :md="24" :lg="16" :xl="16">
                            <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                <div class="task-home-two-center h__responssive">
                                    <div class="filterblock_s">
                                        <h4
                                            class="
                                                dashboard-tile__item-title
                                                short
                                            "
                                        >
                                            <span
                                                class="
                                                    dashboard-tile__item-title-txt
                                                    text-white
                                                "
                                            >
                                               {{ $t('message.categories') }}
                                            </span>
                                        </h4>
                                        <div class="filter_home">
                                            <div>
                                                <a @click="catGraph = true, update++" class="full__item">
                                                    <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z" fill="currentColor"/>
                                                            <path d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <el-radio-group
                                                v-model="categoryStatistic"
                                                size="medium"
                                                class=""
                                            >
                                                <el-radio-button label="weekly">
                                                    {{
                                                        $t("message.weekly")
                                                    }}</el-radio-button
                                                >
                                                <el-radio-button label="monthly"
                                                    >{{ $t("message.monthly") }}
                                                </el-radio-button>
                                                <el-radio-button label="yearly"
                                                    >{{ $t("message.yearly") }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div v-show="catGraph" class="fixed__pos">
                                        <div v-show="catGraph" :class="[catGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                                            <div class="graph__header">
                                                <div class="filter_home">
                                                    <el-radio-group
                                                        v-model="dealTypesChartPeriod"
                                                        size="medium"
                                                        class=""
                                                    >
                                                        <el-radio-button label="weekly">
                                                            {{
                                                                $t("message.weekly")
                                                            }}</el-radio-button
                                                        >
                                                        <el-radio-button label="monthly"
                                                            >{{ $t("message.monthly") }}
                                                        </el-radio-button>
                                                        <el-radio-button label="yearly"
                                                            >{{ $t("message.yearly") }}
                                                        </el-radio-button>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                                                    <a @click="catGraph = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                                        <span class="graph__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tech__flex">
                                                <el-row>
                                                    <el-col :span="12">
                                                        <div class="tech__center">
                                                            <div class="tech__item">
                                                                
                                                                <div class="tech__flex">
                                                                    <category-clothes :filterForm="filterForm" :period="categoryStatistic"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <div class="tech__box">
                                                            <div class="tech__item" v-for="categ in categoryInventory.filter(item => item.parent_id == null)" :key="categ.id">
                                                                <div class="tech__title" v-if="$refs['category_'+categ.id] && $refs['category_'+categ.id][0].category.length > 0">
                                                                    <span v-if="$refs['category_'+categ.id] && $refs['category_'+categ.id][0].child_category" @click="backToParentCategory(categ)" class="el-icon-arrow-left"></span>
                                                                    <h4 v-if="$refs['category_'+categ.id] && $refs['category_'+categ.id][0].child_category">{{ $refs['category_'+categ.id][0].child_category.name }}</h4>
                                                                    <h4 v-else>{{ categ.name }}</h4>
                                                                </div>
                                                                <div class="tech__flex">
                                                                    <category-child-clothes :key="update" :filterForm="filterForm" :period="categoryStatistic" :ref="'category_'+categ.id" :parent_id="categ.id" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tech__flex">
                                        <el-row>
                                            <el-col :span="12">
                                                <div class="tech__center">
                                                    <div class="tech__item">
                                                        
                                                        <div class="tech__flex">
                                                            <category-clothes :filterForm="filterForm" :period="categoryStatistic"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="tech__box">
                                                    <div class="tech__item" v-for="categ in categoryInventory.filter(item => item.parent_id == null)" :key="categ.id">
                                                        <div class="tech__title" v-if="$refs['category_'+categ.id] && $refs['category_'+categ.id][0].category.length > 0">
                                                            <span v-if="$refs['category_'+categ.id] && $refs['category_'+categ.id][0].child_category" @click="backToParentCategory(categ)" class="el-icon-arrow-left"></span>
                                                            <h4 v-if="$refs['category_'+categ.id] && $refs['category_'+categ.id][0].child_category">{{ $refs['category_'+categ.id][0].child_category.name }}</h4>
                                                            <h4 v-else>{{ categ.name }}</h4>
                                                        </div>
                                                        <div class="tech__flex">
                                                            <category-child-clothes :filterForm="filterForm" :period="categoryStatistic" :ref="'category_'+categ.id" :parent_id="categ.id" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="24" :lg="24" :xl="24">
                            <div class="task-home-two-center h__responssive">
                                <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full">
                                    <iframe src="https://ru.widgets.investing.com/live-currency-cross-rates?theme=darkTheme&pairs=1,152,950996,962660,2148,2186,962663,18,2211,2111" width="100%" height="460px" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <el-dialog
            :title="$t('message.deal')"
            :visible.sync="visibleShow"
            width="80%"
            @closed="empty()"
        >
            <crm-show :deal_id="selectedDeal" :open="visibleShow"></crm-show>
        </el-dialog>
        <div class="my-modal">
            <el-dialog
                :title="$t('message.total_amount') + ' : ' + paymentPointTotal"
                :visible.sync="dealPaymentDrawer"
            >
                <DealPaymentsInChart
                    :selectedPoint="paymentSelectedPoint"
                ></DealPaymentsInChart>
            </el-dialog>
        </div>
        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"
                :visible.sync="crmShowClient"
                size="80%"
                :append-to-body="true"
                class="my-bgd body_scroll_80"
                ref="drawerClientShow"
                @opened="drawerOpened('drawerClientShowChild')"
                @closed="drawerClosed('drawerClientShowChild')"
            >
                <crm-show-client
                    :selectedItem="selectedClient"
                    ref="drawerClientShowChild"
                    drawer="drawerClientShow">
                </crm-show-client>
            </el-drawer>
        </div>
        <div class="fixed__pos">
            <div v-show="sellGraph" :class="[sellGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                <div class="graph__header">
                    <div class="filter_home">
                        <el-radio-group
                            v-model="paymentPeriod"
                            size="medium"
                            class=""
                        >
                            <el-radio-button label="weekly">
                                {{
                                    $t("message.weekly")
                                }}</el-radio-button
                            >
                            <el-radio-button label="monthly"
                                >{{ $t("message.monthly") }}
                            </el-radio-button>
                            <el-radio-button label="yearly"
                                >{{ $t("message.yearly") }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <div>
                        <a @click="sellGraph = false" class="graph__close" :class="mode ? 'graph__closeday' : 'graph__closenight'">
                            <span class="graph__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
                                    <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
                <PaymentChart
                    :filterForm="filterForm"
                    :period="paymentPeriod"
                    :key="update"
                    @dataPointSelection="
                        paymentPointSelection
                    "
                    @dataPointAmount="paymentPointAmount"
                ></PaymentChart>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import chartMultiple from "../components/chart/chartMult";
import ShareGrossProfit from "../components/chart/shareGrossProfit";
import DeletTask from "../components/chart/deletTask";
import PaymentChart from "../components/chart/paymentChart";
import DealPaymentsInChart from "../components/chart/paymentModal/deal-payments";
import CrmShow from "@/views/archivedDeals/components/crm-show";
import selectClient from "@/components/inventory/select-client";
import selectUser from "@/components/inventory/select-users";
import selectFilial from "@/components/inventory/select-filial";
import MoneyType from "@/components/chart/moneyType";
import CounterColumn from "../components/chart/CounterColumn.vue";
import selectCountry from "@/components/inventory/select-country";
import DealPaymentTerms from "../components/chart/dealPaymentTerms";
import ProductSellStatisticsChart from "../components/chart/productSellStatisticsChart";
import categoryStatistic from "../components/chart/categoryStatisticBySellingProduct";
import shipmentTermsChart from "../components/chart/shipmentTermsChart";
import selectCategory from "@/components/inventory/select-category";
import dealTypesChart from "../components/chart/dealTypesChart";
import categoryClothes from '../components/chart/category/index.vue';
import categoryChildClothes from '../components/chart/category/index_childs.vue';
import crmShowClient from "@/views/clients/components/show";

import selectDay from '../components/selects/select-day.vue'

import _ from "lodash";
import { formatMoney } from "@/filters/index";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Home",
    components: {
        chartMultiple,
        ShareGrossProfit,
        DeletTask,
        CrmShow,
        selectClient,
        selectUser,
        selectFilial,
        PaymentChart,
        DealPaymentsInChart,
        MoneyType,
        selectCountry,
        DealPaymentTerms,
        ProductSellStatisticsChart,
        categoryStatistic,
        shipmentTermsChart,
        selectCategory,
        dealTypesChart,
        categoryClothes,
        CounterColumn,
        crmShowClient,
        selectDay,
        categoryChildClothes
   },
    data() {
        return {
            filterForm: {},
            selectedClient: {},
            crmShowClient: false,
            filterPayment: {},
            fullSize: false,
            activityMark: 0,
            update: 0,
            paymentPointTotal: "",
            selectedDeal: null,
            visibleShow: false,
            selectFilial: "",
            fetchAllData: false,
            dealPaymentDrawer: false,
            counterGraph: false,
            typesGraph: false,
            loadingData: false,
            loadingUsersDeals: false,
            sourcePeriod: "weekly",
            saleByPeriod: "weekly",
            activityPeriod: "weekly",
            catGraph: false,
            reasonForDelete: "weekly",
            userDeals: "weekly",
            deleteDeal: false,
            saleGraph: false,
            dealTerm: false,
            shipmentGraph: false,
            sellGraph: false,
            countryDealsPeriod: "weekly",
            dealPaymentTermsPeriod: "weekly",
            productSellStatisticsPeriod: "weekly",
            dealGoalsPeriod: "monthly",
            paymentPeriod: "weekly",
            categoryStatistic: "weekly",
            dealGoalsByAmounts: "by_count",
            allPeriodChange: "weekly",
            shipmentTermsChart: "weekly",
            topSuccessfulDealsPeriod: "weekly",
            dealTypesChartPeriod: "weekly",
            clientChartPeriod: "weekly",
            dealGoalsForm: {
                amount: 0,
                fact_amount: 0,
                percentage: 0,
                symbol: '',
            },
            percentage: 75,
            filial_id: null,
            colors: [
                { color: "#f56c6c", percentage: 20 },
                { color: "#e6a23c", percentage: 40 },
                { color: "#5cb87a", percentage: 60 },
                { color: "#1989fa", percentage: 80 },
                { color: "#6f7ad3", percentage: 100 },
            ],
            clientColors: [], 
            paymentSelectedPoint: {},
            category_parent_id: null,
        };
    },
    watch: {
        filterForm: {
            handler: async function (val) {
                this.filterPayment.user_id = val.user_id
                if (val && Object.keys(val).length > 0) {
                    this.fetchData(val);
                }
            },
            deep: true,
            immediate: true,
        },
        allPeriodChange: {
            handler: async function(newVal, oldVal) {
                this.sourcePeriod = newVal;
                this.saleByPeriod = newVal;
                this.activityPeriod = newVal;
                this.reasonForDelete = newVal;
                this.userDeals = newVal;
                this.countryDealsPeriod = newVal;
                this.dealPaymentTermsPeriod = newVal;
                this.productSellStatisticsPeriod = newVal;
                this.paymentPeriod = newVal;
                this.categoryStatistic = newVal;
                this.shipmentTermsChart = newVal;
                this.topSuccessfulDealsPeriod = newVal;
                this.dealTypesChartPeriod = newVal;
                this.clientChartPeriod = newVal;
                if(newVal !== 'weekly'){
                    this.dealGoalsPeriod = newVal;
                }
          },
          deep: true,
          immediate: true,
        },
        userDeals(val) {
            this.loadingUsersDeals = true;

            this.updateUsersDeals({ period: val, filter: this.filterForm })
                .then((res) => {
                    this.loadingUsersDeals = false;
                })
                .catch((err) => {
                    this.loadingUsersDeals = false;
                });
        },
        countryDealsPeriod(val) {
            this.updateCountryDeals({ period: val, filter: this.filterForm });
        },
        topSuccessfulDealsPeriod(val) {
            this.topSuccessfulDealsData({ period: val, filter: this.filterForm });
        },
        activityPeriod(val) {
            this.debouncedActivityDiagram(val, this.filterForm, this);
        },
        dealGoalsPeriod(val) {
            this.updateDealGoals({ period: val, filter: this.filterForm });
        },
        watchGoalParams() {
            
            setTimeout(() => {
                if (this.dealGoalsByAmounts === "by_count") {
                    this.dealGoalsForm.amount = this.dealGoals.count;
                    this.dealGoalsForm.fact_amount = this.dealGoals.fact_count;
                    this.dealGoalsForm.percentage =
                        this.dealGoals.count_percentage;
                    this.dealGoalsForm.symbol = "";
                } else if (this.dealGoalsByAmounts === "by_money_amount") {
                    this.dealGoalsForm.amount = this.dealGoals.money_amount;
                    this.dealGoalsForm.fact_amount =
                        this.dealGoals.fact_money_amount;
                    this.dealGoalsForm.percentage =
                        this.dealGoals.money_amount_percentage;
                    this.dealGoalsForm.symbol = process.env.VUE_APP_CURRENCY;
                } else {
                    this.dealGoalsForm.amount = this.dealGoals.profit;
                    this.dealGoalsForm.fact_amount = this.dealGoals.fact_profit;
                    this.dealGoalsForm.percentage =
                        this.dealGoals.profit_percentage;
                    this.dealGoalsForm.symbol = process.env.VUE_APP_CURRENCY;
                }
            }, 1000);
        },
    },
    created: function () {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
        if (this.filials && this.filials.length === 0)
            this.updateFilialInventory();
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            user: "auth/user",
            filials: "filials/userFilials",
            usersDeals: "users/usersDeals",
            latestFiles: "dealOffers/latest_files",
            activities: "home/activities",
            topSuccessfulDeals: "home/topSuccessfulDeals",
            countryDeals: "home/countryDeals",
            dealGoals: "home/dealGoals",
            taskCounts: "home/tasksByCompleted",
            clientStatistics: "home/clientStatistics",
            filter: "home/filter",
            categoryInventory: "categories/inventory",
            filterOptions: "deals/filterOptions",
        }),
        totalOfCounryDealsMoneyAmount(){
            let total = this.countryDeals.map(item => item.money_amount).reduce((prev, next) => parseInt(prev) + parseInt(next))
            return total ? total : 1
        },
        async watchGoalParams() {
            return (
                this.filial_id +
                this.dealGoalsByAmounts +
                this.dealGoalsPeriod +
                this.loadingData +
                this.dealGoals
            );
        },
    },
    methods: {
        ...mapActions({
            updateUsersDeals: "users/usersDeals",
            updateLatestFiles: "dealOffers/getLatestFiles",
            downloadFile: "dealOffers/downloadLatestFile",
            activityDiagram: "home/activityDiagram",
            updateFilialInventory: "filials/userFilials",
            updateCountryDeals: "home/countryDeals",
            updateDealGoals: "home/dealGoals",
            updateTaskCounts: "home/tasksByCompleted",
            productSellStatisticsData: "home/productSellStatistics",
            topSuccessfulDealsData: "home/topSuccessfulDeals",
            updateCategoryInventory: "categories/inventory",
            getFilterOptions: "deals/getFilterOptions",
            empty: "deals/empty",
        }),
        isActive (menuItem) {
            return this.radio === menuItem
            },
        getClientColors(value){
            this.clientColors = value;
        },

        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        backToParentCategory(cat){
            let ref = 'category_' + cat.id;
            
            if(this.$refs[ref]){
                this.$refs[ref][0].child_category = null;
                this.$refs[ref][0].updateChartData({
                    parent_id: cat.id,
                    period: this.categoryStatistic,
                    filter: this.filterForm,
                });
            }

        },
        showSelectedClient(client){
            this.selectedClient = client;
            this.crmShowClient = true;
        },
        paymentPointSelection(val) {
            this.paymentSelectedPoint = val;
            this.dealPaymentDrawer = true;
        },
        categoryChart (id) {
            this.products.findIndex(x => x.id === id)
        },
        paymentPointAmount(val) {
            this.paymentPointTotal = formatMoney(val, 2);
        },

        showDeal(deal_id) {
            if (deal_id) {
                this.visibleShow = true;
                this.selectedDeal = deal_id;
            }
        },
        async fetchData(filter) {
            this.loadingData = true;
            await this.getFilterOptions({for_dashboard: true});
            this.loadingData = false;
            // await this.updateCategoryInventory();
            // await this.topSuccessfulDealsData({ period: this.topSuccessfulDealsPeriod, filter: filter });
            // await this.debouncedUpdateUsersDeals(this.userDeals, filter, this);
            // await this.debouncedActivityDiagram(this.activityPeriod, filter, this);
            // await this.updateTaskCounts();
            // await this.updateDealGoals({ period: this.dealGoalsPeriod, filter: filter });
            // await this.debouncedUpdateLatestFiles(this, filter);
            // await this.productSellStatisticsData({ period: this.productSellStatisticsPeriod, filter: filter });
            // await this.debouncedUpdateCountryDeals(this.countryDealsPeriod, filter, this);
        },

        debouncedUpdateCountryDeals: _.debounce((period, filter, self) => {
            if (_.isFunction(self.updateCountryDeals)) {
                self.updateCountryDeals({ period: period, filter: filter });
            }
        }, 500),

        debouncedUpdateLatestFiles: _.debounce((self, filter) => {
            if (_.isFunction(self.updateLatestFiles)) {
                self.updateLatestFiles(filter);
            }
        }, 500),

        debouncedActivityDiagram: _.debounce((period, filter, self) => {
            if (_.isFunction(self.activityDiagram)) {
                self.activityDiagram({ period: period, filter: filter }).then(
                    (res) => {
                        let activity = Object.values(self.activities);
                        let marks = activity.reduce(function (acc, obj) {
                            return acc + obj.mark;
                        }, 0);
                        if (activity.length > 0) {
                            self.activityMark = Math.round(
                                marks / activity.length
                            );
                        }
                    }
                );
                self.loadingData = false;
            }
        }, 500),

        debouncedUpdateUsersDeals: _.debounce((period, filter, self) => {
            if (_.isFunction(self.activityDiagram)) {
                self.updateUsersDeals({ period: period, filter: filter });
            }
        }, 500),

        downloadLatestFile(file) {
            this.downloadFile(file.url)
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", file.name);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
        stageColor(id, index) {
            if (index+1 === this.filterOptions.length) {
                return "bg-sausage";
            }

            return "";
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
    },
};
</script>
<style lang="scss">
.bg-redcha {
    background-color: #ff6ba6bf !important;
}
.task-scroll-khan {
    overflow: hidden;
    overflow-y: scroll;
    height: 345px;
}
.task-scroll-khan::-webkit-scrollbar {
    width: 5px;
}

.task-scroll-khan::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: inherit;
}

.task-scroll-khan::-webkit-scrollbar-thumb {
    background-color: #0c253d6b;
    outline: 1px solid #ffffff12;
}
.el__cardnight {
    background: #080c24 !important;
    border: 1px dashed #323248 !important;
}
.el__cardday{
    background: #fff !important;
    border: 1px solid #fff;
}
.text__night {
    color: #000;
}
.mode__2 input {
    background: #323248!important;
    border: 1px solid #323248 !important;
    color: #fff;
  }

.task-scroll-khan-product {
    overflow: hidden;
    overflow-y: scroll;
    height: 400px;
}
.task-scroll-khan-product::-webkit-scrollbar {
    width: 5px;
}

.task-scroll-khan-product::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: rgba(12, 37, 61, 0.95);
}

.task-scroll-khan-product::-webkit-scrollbar-thumb {
    background-color: #0c253d6b;
    outline: 1px solid #ffffff12;
}
.filter-filyal {
    margin-bottom: 20px;
}
.filter-filyal .el-input__inner {
    background-color: #0e2b4500;
    border-color: #e5e5e561;
    color: #fff !important;
    border-radius: 8px;
}
.w50 {
    width: 55%;
    padding-right: 5px;
}
.w20 {
    width: 20%;
}
.w10 {
    width: 5%;
}
.style-view {
    color: #fff;
    position: absolute;
    right: 0px;
    z-index: 2;
    font-size: 20px;
}
.style-view:hover {
    color: #f8b01a;
}
.doc-style {
    position: relative;
}
.custom__justify {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1rem;
}
// .custom__button label span {
//     // background: #0e2b4500 !important;
//     border-color:#e5e5e561 !important;
// }
.custom__button label span {
    background: #0e2b4500 !important;
    border-color: #e5e5e561 !important;
    color: #fff !important;
}
.custom__button label input:checked+.el-radio-button__inner {
    background-color: #409EFF !important;
}
.circle__user {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #f8b01a;
    border: 2px solid #8f6408;
    color: #fff;
    border-radius: 50%;
}
.user__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.user__footer {
    display: flex;
    margin-top: 2px;
    flex-direction: column;
}
.user__footer span {
    font-size: 13px !important; 
}
.user__deal {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.user__deal span {
    font-size: 14px !important; 
}
.user__name span {
    font-size: 14px !important; 
}

// asasasass
.agents__table {
    width: 100%;
    color: #fff;
}
.agents__table thead tr th {
    text-align: center;
}
.agents__table tbody tr td {
    padding: 8px 5px;
}
.agents__box {
    display: flex;
    flex-direction: row;
} 
.agents__half {
    width: 50%;
}
.agents__circle {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.agents__green {
    background: #CCFF99;
}
.agents__red {
    background: #FF9999;
}
.agents__blue {
    background: #66B2FF;
}
.agents__yellow {
    background: #FFFF99;
}
.agents__center {
    text-align: center;
}
.agents__view span {
    font-size: 16px;
    cursor: pointer;
}
.agents__flex {
    display: flex;
    flex-direction: column;
}
@media screen and ( max-height: 750px ) {
    .agents__flex {
        display: flex;
        flex-direction: row;
    }
    .agents__full {
        width: 50%;
    }


}
.tech__item {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    // border-right: 1px solid rgb(53, 55, 68);
}
.tech__flex {
    display: flex;
    flex-direction: row;
}
.tech__color {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
}
.tech__name {
    color: #fff;
}
.tech__li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}
.tech__title {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.tech__title span {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}
.tech__title h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}
.tech__box {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
}
.tech__flex {
    display: flex;
    flex-direction: row;
}
.tech__center {
    display: flex;
    align-items: center;
}
.tech__box::-webkit-scrollbar {
  height: 8px;
}
.tech__box::-webkit-scrollbar-thumb {
  background-color: #459EF7
}
.tech__box::-webkit-scrollbar-track {
    background-color: rgba(12, 37, 61, 0.95);
}
.without__margin div {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.margin__header {
    margin: 0 12px
}
.select__formday .el-input__inner {
    border-color: #bfc4c9 !important;
    color: #606266 !important;
}
.select__formnight .el-input__inner {
    border-color: #bdc2c7 !important;
    color: #ffffff !important;
}
.home {
    position: relative;
}
.h__responssive {
    height: 100%;
}
</style>
