<template >
    <div :class="setBorder ? 'mm_bordered_section' : ''">
        <el-row v-if="deal_product" v-for="(deal_product, key) in dealHistoryProducts" :key="'col_'+key">
            <el-col :span="2">
                <span class="font-bold"> № {{ key+1 }}: </span>
            </el-col>
            <el-col :span="7">
                {{ deal_product.product_id }} 
                <span v-if="deal_product.comment" class="font-bold">({{ deal_product.comment }})</span>
                <span v-if="deal_product.status">
                    (<span class="font-bold">{{ deal_product.status == 'created' ? $t('message.new') : $t('message.old') }}</span>)
                </span>

            </el-col>
            <el-col :span="4">
                {{ deal_product.barcode }}
            </el-col>
            <el-col v-if="deal_product.package_barcode" :span="4">
                {{ $t('message.package') }}: {{ deal_product.package_barcode }}
            </el-col>
            
            <el-col v-if="deal_product.client_id" :span="3">
                {{ $t('message.client') }}: {{ deal_product.client_id }}
            </el-col>
            <el-col v-if="deal_product.filial_id" :span="3">
                {{ $t('message.filial') }}: {{ deal_product.filial_id }}
            </el-col>
            <el-col v-if="deal_product.container" :span="3">
                {{ $t('message.container') }}: {{ deal_product.container }}
            </el-col>
            <el-col v-if="deal_product.product_price >= 0" :span="2">
                {{ deal_product.product_price }} $
            </el-col>
            <el-col v-if="deal_product.incoming_quantity >= 0" :span="3">
                {{ deal_product.incoming_quantity}} шт
            </el-col>
            <el-col v-if="deal_product.quantity && !deal_product.incoming_quantity" :span="3">
                {{ deal_product.quantity}} шт
            </el-col>
            <el-col v-if="deal_product.price >= 0" :span="3">
                {{ deal_product.price }} $ / {{ $t('message.kg') }}
            </el-col>
            <el-col v-if="deal_product.weight >= 0" :span="3">
                {{ deal_product.weight }} {{ $t('message.kg') }}
            </el-col>
            <el-col v-if="deal_product.from_filial_id" :span="3">
                {{ $t('message.from_filial') }}: {{ deal_product.from_filial_id }}
            </el-col>
            <el-col v-if="deal_product.to_filial_id" :span="3">
                {{ $t('message.to_filial') }}: {{ deal_product.to_filial_id }}
            </el-col>
            <el-col v-if="deal_product.transfer" :span="3">
                {{ $t('message.party') }}: {{ deal_product.transfer }}
            </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    props: {
        dealHistoryProducts: {
            default: Array
        },
        setBorder: {
            default: false,
        }
    },
    data() {
      return {
      };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
        }),
    },
  };
  
</script>