import { index, inventory, show, changeIndex, archivateTask, restoreTask, archivedTasks, deleteTask, downloadFile, 
    deleteFile, deletedTasks, store, storeFiles, update, destroy, getTasksForCalendar } from "@/api/tasks/tasks";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.tasks);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    
    archivateTask({ commit }, id) {
        return new Promise((resolve, reject) => {
            archivateTask(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    restoreTask({ commit }, id) {
        return new Promise((resolve, reject) => {
            restoreTask(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteTask({ commit }, data) {
        const { task_id, task_reason_id } = data;
        return new Promise((resolve, reject) => {
            deleteTask(task_id, task_reason_id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    archivedTasks({commit}, params = {}){
        return new Promise((resolve, reject) => {
            archivedTasks(params).then(res => {
                commit('SET_ARCHIVED_TASKS_LIST', res.data.result.data.tasks)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    deletedTasks({commit}, params = {}){
        return new Promise((resolve, reject) => {
            deletedTasks(params).then(res => {
                commit('SET_DELETED_TASKS_LIST', res.data.result.data.tasks)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.tasks);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.task);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    downloadFile({ commit }, data){
        const { task_id, file } = data;
        return new Promise((resolve, reject) => {
            downloadFile(task_id, file).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteFile({ commit }, data){
        const { task_id, file } = data;
        return new Promise((resolve, reject) => {
            deleteFile(task_id, file).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    changeIndex({ commit }, data) {
        return new Promise((resolve, reject) => {
            changeIndex(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
    storeFiles({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeFiles(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getTasksForCalendar({ commit }, params) {
        return new Promise((resolve, reject) => {
            getTasksForCalendar(params)
                .then(res => {
                    commit('GET_TASKS_FOR_CALENDAR', res.data.result.data.tasks)
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
