<template>
    <div>
        <apexchart 
            type="bar" 
            height="419" 
            :options="chartOptions" 
            :series="series"
        ></apexchart>
    </div>
</template>
<script>
import ApexCharts from "apexcharts";
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";

export default {
    components: {
        ApexCharts  
    },
    props: {
        period: {
            default: "",
        },
        filterForm: {
            default: [],
        },
    },
    data: () => ({
      series: [{
        name: ['Money amount'],
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          foreColor: '#fff'
        },
        colors: [
          '#FF6633', '#FF33FF', '#FFFF99', 
          '#00B3E6', '#E6B333', '#3366E6', 
          '#99FF99', '#B34D4D', '#80B300', 
          '#E6B3B3', '#6680B3', '#66991A', 
          '#4D8066', '#809980', '#E6FF80', 
          '#E64D66', '#4DB380', '#FF4D4D',
          '#999966', '#1AFF33', '#99E6E6',
        ],
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
            distributed: true,
            color: '#fff',

          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        
        grid: {
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            // text: 'Money amount',
          },
          labels: {
              show: true,
              formatter: function (value) {
                  return formatMoney(value, 2);
              },
          },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return formatMoney(val);
                },
            },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
          },
        }
      },
    }),

    watch: {
        watchAll: {
            handler: async function (val) {
                // setTimeout(() => {
                    // this.updateChartData({
                    //     period: this.period,
                    //     filter: this.filterForm,
                    // });
                // }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            clientStatistics: "home/clientStatistics",
        }),
        watchAll(){
          return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            updateClientStatistics: "home/clientStatistics",
        }),

        updateChartData(data) {
            this.updateClientStatistics(data).then((res) => {
                this.$emit('getClientColors', this.chartOptions.colors);
                this.series[0].name[0] = this.$t('message.money_amount');
                this.series[0].data = this.clientStatistics.map(item => item.money_amount);
                this.chartOptions.xaxis.categories = this.clientStatistics.map(item => item.name);
                this.chartOptions = {
                    ...this.chartOptions,
                    ...{
                        chartOptions: {
                            xaxis: {
                                categories: this.clientStatistics.map(item => item.name),
                            },
                        },
                    },
                };
            });
        },
    },
    
    
}
</script>
