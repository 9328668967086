import request from '@/utils/request';

export function store(data) {
    return request({
        url: '/partnerTariffPrices',
        method: 'post',
        data
    })
}

export function updatePartnerPricesByTariff(data) {
    return request({
        url: '/partnerTariffPrices/updatePartnerPricesByTariff',
        method: 'put',
        data
    })
}

export function partnerDirectionTariffs(params) {
    return request({
        url: `/partnerTariffPrices/partnerDirectionTariffs`,
        method: 'get',
        params
    })
}

export function directionTariffPartners(params) {
    return request({
        url: `/partnerTariffPrices/directionTariffPartners`,
        method: 'get',
        params
    })
}
