<template>
<div>
    <div class="height__full">
  
        <div class="app-modal__in">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0">
                {{ $t("message.new_m", {  m: $t("message.actual_address"), }) }}
            </p>
            <div>
                <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
                ></crm-store-update-close>
            </div>
            </div>
        </div>
        <div class="text-right pt-5 pr-5">
          <el-button @click="addForm()" type="primary" size="medium">
            {{ $t('message.add_new_address') }}
          </el-button>
        </div>
        
        <el-form ref="form" :model="addressForm" label-position="top">
          <div v-for="(value, index) in addressForm.addressList" :key="index" v-loading="loadingData">
            <div v-if="addressForm.addressList && addressForm.addressList.length > 0" class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right w-100 rounded-sm p-4">
                    <!-- CLIENT ADDRESS  -->
                    <el-row :gutter="20">
                        <el-col :span="24" class="text-right">
                          <el-checkbox v-model="value.is_default" 
                            :disabled="value.is_default" 
                            @change="updateOthers(index)" 
                            :label="$t('message.active')" border
                            :style="value.is_default ? 'background-color: rgb(57, 180, 57);' : ''">
                          </el-checkbox>
                          <el-button v-show="value.id && !value.can_update" @click="canUpdateAddress(value)" class="ml-3" size="small" type="primary" icon="el-icon-edit"></el-button>
                          <el-button v-show="value.id && value.can_update" @click="returnToOldData(value, index)" class="ml-3" size="small" type="info" icon="el-icon-refresh-left"></el-button>
                        </el-col>
                    </el-row>

                    <el-row :gutter="20">  
                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.country_id')"
                              :prop="'addressList.' + index + '.country_id'"
                              :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.country')}), trigger: 'change' }"
                              >
                                <select-country
                                    :size="'medium'"
                                    :disabled="checkForUpdateDisable(value)"
                                    :placeholder="columns.country_id.title"
                                    :id="value.country_id"
                                    v-model="value.country_id"
                                    @input="updateCountry(value)"
                                >
                                </select-country>
                            </el-form-item>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.region')"
                              :prop="'addressList.' + index + '.region'"
                            >
                              <select-region
                                    :size="'medium'"
                                    :disabled="checkForUpdateDisable(value)"
                                    :placeholder="$t('message.region')"
                                    :id="value.region_id"
                                    :country_id="value.country_id"
                                    v-model="value.region_id"
                                >
                                </select-region>
                            </el-form-item>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.city_id')"
                              :prop="'addressList.' + index + '.city_id'"
                              :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.city')}), trigger: 'change' }"
                              >
                                <select-city
                                    :size="'medium'"
                                    :disabled="checkForUpdateDisable(value)"
                                    :placeholder="columns.city_id.title"
                                    :id="value.city_id"
                                    :country_id="value.country_id"
                                    :region_id="value.region_id"
                                    @cityRegion="updateRegionId($event, value)"
                                    v-model="value.city_id"
                                >
                                </select-city>
                            </el-form-item>
                        </el-col>
                        <!-- end col -->
                    </el-row>

                    <el-row :gutter="20">  
                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.street')"
                              :prop="'addressList.' + index + '.street'"
                              >
                              <crm-input
                                  :size="'medium'"
                                  :inputValue="value.street"
                                  :disabled="checkForUpdateDisable(value)"
                                  v-model="value.street"
                                  :placeholder="$t('message.street')"
                              ></crm-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.house')"
                              >
                              <crm-input
                                  :size="'medium'"
                                  :inputValue="value.house"
                                  :disabled="checkForUpdateDisable(value)"
                                  v-model="value.house"
                                  :placeholder="$t('message.house')"
                              ></crm-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.flat')"
                              >
                              <crm-input
                                  :size="'medium'"
                                  :inputValue="value.flat"
                                  :disabled="checkForUpdateDisable(value)"
                                  v-model="value.flat"
                                  :placeholder="$t('message.flat')"
                              ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end col -->
                    </el-row>

                    <el-row :gutter="20">  
                        
                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.landmark')"
                              >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="value.landmark"
                                    :disabled="checkForUpdateDisable(value)"
                                    v-model="value.landmark"
                                    :placeholder="$t('message.landmark')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end col -->

                        <el-col :span="8">
                            <el-form-item
                              :label="$t('message.zip_code')"
                              >
                              <crm-input
                                  :size="'medium'"
                                  :inputValue="value.zip_code"
                                  :disabled="checkForUpdateDisable(value)"
                                  v-model="value.zip_code"
                                  :placeholder="$t('message.zip_code')"
                              ></crm-input>
                            </el-form-item>
                        </el-col>

                        <!-- CLIENT ADDRESS END -->
                        <el-col :span="8" class="right-block">
                            <el-button v-if="(!value.id && type === 'deal') || (type !== 'deal')" @click="deleteForm(value, index)" type="danger" size="medium" plain>
                              {{ $t('message.delete_field') }}
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
          </div>
        </el-form>

    </div>
</div>
  <!-- end app-modal__body -->
</template>

<script>
// @ is an alias to /src
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";
import selectRegion from "@/components/inventory/select-region";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  name: "clientAddress",
  components: {
    selectCountry,
    selectCity,
    selectRegion
  },
  mixins: [form, drawer],
  props: {
      client_id: {
        default: null
      },
      deal_id: {
        default: null
      },
      type: {
        default: 'client'
      },
  },
  data() {
    return {
      
      loadingData: false,
      addressForm: {
        addressList: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      columns: "clients/columns",
      rules: "clients/client_address_rules",
      clientAddresses: "clients/clientAddresses",
    }),
  },

  methods: {
    ...mapActions({
      save: "clients/updateClientAddresses",
      getClientAddresses: "clients/getClientAddresses",
    }),
    updateRegionId(region_id, value){
      value.region_id = region_id;
    },
    updateCountry(value){
      value.city_id = null;
      value.region_id = null;
    },
    checkForUpdateDisable(value){
      if(value.id && this.type === 'deal' && !value.can_update){
        return true;
      }
      return false;
    },
    returnToOldData(value, index){
      this.$set(value, 'can_update', false)
      if(value.id && this.clientAddresses && this.clientAddresses.length > 0){
        let old_address = this.clientAddresses.find(el => el.id === value.id);
        if(old_address){
          this.$set(this.addressForm.addressList, index, JSON.parse(JSON.stringify(old_address)))
        }
      }
    },
    canUpdateAddress(value) {
      this.$set(value, 'can_update', true);
      this.$notify({
          title: this.$t('message.warning'),
          type: "warning",
          offset: 130,
          message: this.$t('message.address_could_be_used_on_antoher_deals')
      });
    },
    afterOpen(){
      this.loadingData = true;
      this.getClientAddresses({id: this.client_id, deal_id: this.deal_id})
        .then(res => {
          this.addressForm.addressList = JSON.parse(JSON.stringify(this.clientAddresses));
          if(this.addressForm.addressList && this.addressForm.addressList.length === 0){
            this.addForm();
          }
          this.loadingData = false;
        }).catch(err => {
          this.loadingData = false;
        });
    },

    addForm() {
      let new_address = {
        client_id: this.client_id,
        can_update: true,
        country_id: "",
        region_id: "",
        city_id: "",
        is_default: true,
        street: "",
        house: "",
        flat: "",
        zip_code: "",
        landmark: "",
      };
      this.addressForm.addressList.unshift(new_address);
      this.updateOthers(0);
    },

    updateOthers(address_index) {
      this.addressForm.addressList.forEach((item, index) => {
          if(index !== address_index){
            this.$set(item, 'is_default', false);
          }
      });
    },

    deleteForm(value, index) {
      if (this.addressForm.addressList.length === 1) {
        this.$message({
          type: "warning",
          message: this.$t("message.unable_to_delete_last_form"),
        });
      } else {
        if(value.id){

        }else{
          this.addressForm.addressList.splice(index, 1);
        }
      }
    },

    submit(close = true) {
        let checked = this.addressForm.addressList.filter(el => el.is_default); 
        if(this.addressForm.addressList.length > 0 && checked.length > 0){
          this.$refs["form"].validate((valid) => {
            if (valid) {
              this.loadingButton = true;
              this.save({id: this.client_id, deal_id: (this.deal_id ? this.deal_id : 'new'), addresses: this.addressForm.addressList})
                .then((res) => {
                  if(res.data.result.data && res.data.result.data.id){
                    this.$emit('input', res.data.result.data);
                  }
                  this.addressForm = { addressList: [] };
                  this.loadingButton = false;
                  this.$alert(res);
                  this.parent().listChanged();
                  if (close) this.close();
                })
                .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
                });
            }
          });
        }else{
            this.$notify({
                title: this.$t('message.warning'),
                type: "warning",
                offset: 130,
                message: this.$t('message.select_address')
            });
        }
    },


  },
};
</script>

