<template >
    <div class="mm_bordered_section">
        <el-row v-if="deal_service" v-for="(deal_service, key) in dealHistoryServices" :key="'col_'+key">
            <el-col :span="2">
                <span class="font-bold"> № {{ key+1 }}: </span>
            </el-col>
            <el-col v-if="deal_service.service_id" :span="7">
                {{ deal_service.service_id }}
                (<span class="font-bold">{{ deal_service.status == 'created' ? $t('message.new') : $t('message.old') }}</span>)
            </el-col>
            <el-col v-if="deal_service.sum >= 0 " :span="5">
                {{ parseFloat(deal_service.sum) * parseFloat(deal_service.rate) }} {{ calculationSymbol(deal_service) }}
            </el-col>
            <el-col v-if="deal_service.currency_id" :span="5">
                {{ $t('message.currency') }}: {{ deal_service.currency_id }}
            </el-col>
            <el-col v-if="deal_service.calculation_type" :span="5">
                {{ $t('message.calculation_type') }}: {{ calculateBy(deal_service) }}
            </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    props: {
        dealHistoryServices: {
            default: Array
        },
    },
    data() {
      return {
      };
    },
    computed: {
        ...mapGetters({
            calculationTypes: 'services/calculationTypes',
			serviceCalculateTypes: 'services/serviceCalculateTypes',
        }),
    },
    methods: {
        ...mapActions({
        }),
        calculateBy(row) {
			let c_symbol = this.serviceCalculateTypes.find(el => el.id == row.calculate_by);
			return c_symbol ? c_symbol.name : '';
		},
        calculationSymbol(row) {
			let c_symbol = this.calculationTypes.find(el => el.id == row.calculation_type);
			return c_symbol && c_symbol.symbol ? c_symbol.symbol : '$';
		},
    },
  };
  
</script>