import request from '@/utils/request';


export function store(data) {
    return request({
        url: '/productTariffPrices',
        method: 'post',
        data
    })
}

export function updateProductPricesByTariff(data) {
    return request({
        url: '/productTariffPrices/updateProductPricesByTariff',
        method: 'put',
        data
    })
}

export function productDirectionTariffs(params) {
    return request({
        url: `/productTariffPrices/productDirectionTariffs`,
        method: 'get',
        params
    })
}

export function directionTariffProducts(params) {
    return request({
        url: `/productTariffPrices/directionTariffProducts`,
        method: 'get',
        params
    })
}
