export const getters = {
    list: state => state.list,
    incoming_transfer_cargo: state => state.incoming_transfer_cargo,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    cargo_pagination: state => state.cargo_pagination,
    sort: state => state.sort 
  };
  