import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/productCirculations",
        method: "get",
        params
    });
}

export function waitingIndex(params) {
    return request({
        url: "/productCirculations/waitingIndex",
        method: "get",
        params
    });
}

export function store(data) {
    return request({
        url: "/productCirculations",
        method: "post",
        data
    });
}

export function printProductBarcode(params) {
    return request({
        url: `/productCirculations/printProductBarcode`,
        method: 'get',
        params
    })
}

export function getProductByBarcode(params) {
    return request({
        url: `/productCirculations/getProductByBarcode`,
        method: 'get',
        params
    })
}

export function inventory(params) {
    return request({
        url: "/productCirculations/inventory",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/productCirculations/${id}`,
        method: "get"
    });
}
export function update(data) {
    return request({
        url: `/productCirculations/${data.id}`,
        method: "put",
        data
    });
}
export function destroy(id) {
    return request({
        url: `/productCirculations/${id}`,
        method: "delete"
    });
}
