<template>
  <div class="modal-add-task-participants">
    <div class="add-task-participants-body">
      <div>
        <el-form ref="form" :model="form" :rules="rules">
          <div class="app-form__group mb-4">
                <el-form-item :label="$t('message.name')" prop="text" class="label_mini">
                    <el-input
                      type="textarea"
                      autosize
                      :placeholder="$t('message.name')"
                      v-model="form.text">
                    </el-input>
                </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <el-button type="primary" size="medium" @click="submit(true)">{{ $t('message.save') }}</el-button>
        <el-button type="danger" plain size="medium" @click="close()">{{ $t('message.cancel') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  props: {
      model:{
        default: null
      }
    },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
      ...mapGetters({
        rules: "taskCheckLists/rules",
        mode: "MODE"
      }),
  },
  methods: {
      ...mapActions({
      }),
      afterOpen(){
        this.form = JSON.parse(JSON.stringify(this.model));
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.$emit("updateMiniTask", this.form);
                  if (close) this.close();
              }
          });
      },
      afterLeave(){
        this.form = {}
      }
  },
};
</script>

<style>
</style>
