export const filter = {
    id : '',  
    name: '', 
    type_id: null, 
    phone: '', 
    phone_country_code: 'UZ',
    email: '', 
    location: '', 
    inn: '', 
    gender: null, 
    country_id: null, 
    city_id: null,
    clientType_id: null,

    custom_id: '',
    passport_number: '',
    passport_given_by: '',
    passport_given_date: '',
    region: '',
    street: '',
    house: '',
    flat: '',
    zip_code: '',
    passport_series: '',
    
    created_at: '',
    updated_at: '',
    search: ''  
  };
  