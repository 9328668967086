export const model = {
    id: null,
    name: '',
    url: '',
    register_rule_id: null,
    show_referral: true,
    countries: [],
    filials: [],
    tariffs: [],
    dark_plugin: '',
    light_plugin: '',
    tariffs: [],
    created_at: '',
    updated_at: '',
  };
  