import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
	deal_types : {
		show: true,
		title: i18n.t('message.dealTypes'),
		sortable: true,
		column: 'deal_types'
    },
	percent : {
		show: true,
		title: i18n.t('message.percent'),
		sortable: true,
		column: 'percent'
	},
    calculation_type : {
		show: true,
		title: i18n.t('message.calculation_type'),
		sortable: true,
		column: 'calculation_type'
	},
	calculate_by : {
		show: true,
		title: i18n.t('message.calculate_by'),
		sortable: true,
		column: 'calculate_by'
	},
	total_price : {
		show: true,
		title: i18n.t('message.total_price'),
		sortable: true,
		column: 'total_price'
	},
	currency : {
		show: true,
		title: i18n.t('message.currency'),
		sortable: true,
		column: 'currency'
	},
    service_category_id : {
		show: true,
		title: i18n.t('message.serviceCategory'),
		sortable: true,
		column: 'service_category_id'
	},
    show_cabinet : {
		show: true,
		title: i18n.t('message.show_cabinet'),
		sortable: true,
		column: 'show_cabinet'
	},
	auto_set : {
		show: true,
		title: i18n.t('message.auto_set'),
		sortable: true,
		column: 'auto_set'
	},
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
