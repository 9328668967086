export const model = {
  id : null,  
  cost_type_id: null,
  filial_id: null,
  payment_type_id: null, 
  bank_account_id: null, 
  money_amount: 0, 
  currency_rate: 0, 
  date: '', 
  comment: '', 
  created_at: '',
  updated_at: ''  
};
  