export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    filial_products_pagination: state => state.filial_products_pagination,
    sort: state => state.sort,
    readyToFlight: state => state.readyToFlight,
    incomingFilialContainers: state => state.incomingFilialContainers,
    allPackagesAndProducts: state => state.allPackagesAndProducts,
  };
  