import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, users) => {                
        state.list = users;        
    },
    SET_INVENTORY: (state, users) => (state.inventory = users),
    SET_USER_TELEGRAM_STATUSES: (state, userTelegramStatuses) => (state.userTelegramStatuses = userTelegramStatuses),
    SET_PERMISSION_TYPES: (state, userPermissionTypes) => (state.userPermissionTypes = userPermissionTypes),
    SET_USER_EMAIL_INVENTORY: (state, userEmailInventory) => (state.userEmailInventory = userEmailInventory),
    SET_USERS_DEALS: (state, users) => (state.usersDeals = users),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_SEARCH: (state, search) => (state.search = search),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, user) => {
        /***
         * set user main data
         */
        state.model=user;   
       
        /***
         * set user permissions
         */
        if (user.role) {
            if (user.role.permissions) {
                state.permissions = user.role.permissions
            }
        }
        
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
        state.permissions = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    }

};
