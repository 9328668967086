import { store, directionTariffProducts, productDirectionTariffs, updateProductPricesByTariff } from "@/api/settings/productTariffPrices";

export const actions = {

    directionTariffProducts({ commit }, params) {
        return new Promise((resolve, reject) => {
            directionTariffProducts(params)
                .then(res => {
                    commit('PRODUCT_TARIFF_PRICES', res.data.result.data.tariffProductPrices);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    productDirectionTariffs({ commit }, params) {
        return new Promise((resolve, reject) => {
            productDirectionTariffs(params)
                .then(res => {
                    commit('TARIFF_PRODUCT_PRICES', res.data.result.data.productTariffPrices);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateProductPricesByTariff({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateProductPricesByTariff(data)
                .then(res => {
                    commit('UPDATE_DEAL_PRODUCT_PRICES', res.data.result.data.newPriceList);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
