import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    user_id : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'user_id'
    },

    percentage : {
        show: true,
        title: i18n.t('message.percentage'),
        sortable: true,
        column: 'percentage'
    },
    min_deal_sum : {
        show: false,
        title: i18n.t('message.min_deal_sum'),
        sortable: false,
        column_sort: false,
        column: 'min_deal_sum'
    },
    max_deal_sum: {
        show: false,
        title: i18n.t('message.max_deal_sum'),
        sortable: false,
        column_sort: false,
        column: 'max_deal_sum'
    },
    currency_id: {
        show: false,
        title: i18n.t('message.currency'),
        sortable: false,
        column_sort: false,
        column: 'currency_id'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
