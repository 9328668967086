<template>
  <div class="modal-add-task-participants">
    <div class="add-task-participants-body">
      <div>
        <el-form ref="form" :model="form" :rules="rules">
          <div class="app-form__group mb-4">
            <div>
              <div class="form-group-wrap d-flex">
                <div class="app-form__group f-fill">
                  <div>
                  <el-form-item
                    :label="$t('message.payment_in_percents')"
                    class="label_mini mb-2"
                  >
                    <crm-money-input
                      v-model="form.percentage"
                      :old="form.percentage"
                      :size="'medium'"
                    >
                    </crm-money-input>
                  </el-form-item>
                  </div>

                </div>

                <div class="app-form__group pl-3 f-fill">
                  <el-form-item
                    :label="$t('message.payment_in_money')"
                    class="label_mini mb-2"
                  >
                    <el-input
                      placeholder="Please input"
                      style="font-weight: bold"
                      :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="app-form__group mb-0 d-flex">
                <div class="app-form__group f-fill mr-2">
                  <el-form-item
                    :label="$t('message.payment_start_date')"
                    class="label_mini mb-2"
                  >
                    <el-date-picker
                      class="w-100"
                      size="medium"
                      v-model="form.begin_date"
                      type="date"
                      :placeholder="$t('message.payment_start_date')"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </div>

                <div class="app-form__group f-fill ml-2">
                  <el-form-item
                    :label="$t('message.payment_end_date')"
                    class="label_mini mb-2"
                  >
                    <el-date-picker
                      class="w-100"
                      size="medium"
                      v-model="form.end_date"
                      type="date"
                      :placeholder="$t('message.payment_end_date')"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
            
              <el-form-item :label="$t('message.comment')" class="label_mini mb-0">
                <el-input
                  type="textarea"
                  autosize
                  :placeholder="$t('message.comment')"
                  v-model="form.comment">
                </el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!-- end itme-chek -->
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <el-button type="primary" size="medium" @click="submit(true)">{{$t('message.save')}}</el-button>
        <el-button type="danger" plain size="medium"  @click="close()">{{$t('message.cancel')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  data() {
    return {
      checked: false,
    };
  },
  computed: {
      ...mapGetters({
           rules: "taskCheckLists/rules",
      }),
  },
  methods: {
      ...mapActions({
      }),
      afterOpen(){
       
      },

      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.$emit("getNewCheckList", this.form.name);
                  if (close) this.close();
              }
          });
      },
      afterLeave(){
        this.form = {}
      }
  },
};
</script>

<style>
</style>
