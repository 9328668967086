

<template>
    <div>
        <el-radio-group v-model="selected" @change="dispatch" :size="size" class="w-100">
            <el-radio-button v-for="(calc_type, index) in calculation_types" :key="'calc_type-' + index" :label="calc_type.id"> {{ calc_type.name }} </el-radio-button>
        </el-radio-group>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id
          },
          immediate: true
        }
      },
      data() {
        return {
          selected: null,
        }
      },
      mounted() {
        if (this.calculation_types && this.calculation_types.length === 0) this.updateInventory();
      },
      computed: {
        ...mapGetters({
            calculation_types: 'products/calculation_types'
        })
      },
      methods: {
        ...mapActions({
            updateInventory: 'products/getCalculationTypes'
        }),
        dispatch(e) {
          this.$emit('input', e)
          this.selected = e
        }
      },
    }
  
</script>
  