import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}),
            trigger: "change"
        }
    ],
    deal_type_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.dealType')}),
            trigger: "change"
        }
    ],
    phone: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}),
            trigger: "change"
        }
    ],
   
    from_filial_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.from_filial')}),
            trigger: "change"
        }
    ],
    to_filial_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.to_filial')}),
            trigger: "change"
        }
    ],
    direction_tariff_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.direction_tariff_id')}),
            trigger: "change"
        }
    ],
    dealType_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.dealType')}),
            trigger: "change"
        }
    ],
    client_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.client')}),
            trigger: "change"
        }
    ],
    reason_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.deletion_reason')}),
            trigger: "change"
        }
    ],

    to_client_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.sender')}),
            trigger: "change"
        }
    ],
    to_client_phone: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}),
            trigger: "change"
        }
    ],

    partner_client_id: [
        {
            required: true,
            message: i18n.t('message.please_select_input', {input: i18n.t('message.partner')}),
            trigger: "change"
        }
    ],
    partner_phone: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}),
            trigger: "change"
        }
    ],

};
