<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.source')" filterable clearable
      :size="size" class="w-100" :disabled="disabled">
      <el-option v-for="(source,index) in sources" :key="'sources-' + index" :label="source.name" :value="source.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      disabled: {
        default: false
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
        sources: [
            {
                id: 1,
                name: 'Масса'
            },
            {
                id: 2,
                name: 'Объем'
            }
        ]
      }
    },
    // mounted() {
    //   if (this.sources && this.sources.length === 0) this.updateInventory()
    // },
    computed: {
    //   ...mapGetters({
    //     sources: 'sources/inventory'
    //   })
    },
    methods: {
      ...mapActions({
        updateInventory: 'sources/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
