import { index, getProcesses, updateServiceProcess, showDealServices } from "@/api/deals/dealService";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.dealService);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.dealService);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    getProcesses({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getProcesses(params)
                .then(res => {
                    commit("SET_PROCESSES", res.data.result.data.processes);
                    resolve(res.data.result.data.dealService);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    updateServiceProcess({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateServiceProcess(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    showDealServices({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            showDealServices(params)
                .then(res => {
                    commit("SET_DEAL_SERVICES_LIST", res.data.result.data.dealService);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
