import {i18n} from '@/utils/i18n';

export const rules = {
    driver: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.driver')}), trigger: 'change' },
    ],
    transport_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.transport')}), trigger: 'change' },
    ],
    transport_number: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.transport_number')}), trigger: 'change' },
    ],
    phone: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}), trigger: 'change' },
    ],
};
