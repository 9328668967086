<template>
  <div>
    <div class="app-modal__body">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <div style="display: flex; align-items: flex-start;">
            <h4 class=""> {{$t('message.products')}} - {{ dealPackages.length }} </h4> 
            <el-button @click="addNewPackage" class="ml-5 mt-3" type="success" plain size="medium"> {{ $t('message.add_package') }} </el-button>
          </div>
          <el-tabs v-model="currentName" type="card" closable @tab-remove="removePackageTab">
            <el-tab-pane v-for="(pack, index) in dealPackages" :key="pack.pack_id" :name="'pack_'+pack.pack_id">
              <span slot="label"> {{ $t('message.n') +' '+ (index+1) + ' / '  + pack.barcode}} </span>
              <div>
                <div style="display: flex; align-items: flex-start;">
                  <select-container-type
                    class="m-4 w-100"
                    :size="'large'"
                    :select_default="true"
                    :clearable="false"
                    :add_query="{user_id: authUser.id}"
                    :placeholder="$t('message.containerType')"
                    :id="pack.container_type_id"
                    v-model="pack.container_type_id"
                  >
                  </select-container-type>
						      
                  <el-input class="mt-4"  :placeholder="$t('message.tracking_code_new')" v-model="pack.tracking_code" size="large">
                  </el-input>

                  <el-button v-if="pack.barcode" 
                    @click="printBarCode(pack, 'waiting_barcode')" 
                    class="mt-4 ml-4" 
                    type="primary" 
                    icon="fa fa-print" circle> 
                      {{ $t('message.print') }}
                  </el-button>
                </div>
                <deal-products
                  :key="pack.pack_id" 
                  v-model="pack.deal_products" 
                  :packageProdList="deal_packages[index] ? deal_packages[index].deal_products : ''"
                  :direction_tariff_id="direction_tariff_id"
                  :deal_id="deal_id"
                  :type="pack.type"
                > 
                </deal-products>
               </div>
            </el-tab-pane>
          </el-tabs>
        </div>
    </div>
    <div class="app-modal__body pt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <h4 class=""> {{$t('message.marketplace')}} </h4>
          <deal-products  
            ref="productsTable" 
            v-model="dealProductsList"
            :packageProdList="deal_products"
            :direction_tariff_id="direction_tariff_id"
            :deal_id="deal_id"
				    :type="'product'"
            :partner_id="partner_id"
          >
          </deal-products>
        </div>
    </div>
   
  </div>
</template>

<script>
import dealProducts from "@/views/deals/components/dealProduct/deal-product-list";
import selectContainerType from "@/components/inventory/select-container-type";

import { formatMoney, formatNumber } from "@/filters/index";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import _ from 'lodash';

export default {
  components: {
    dealProducts,
    selectContainerType
  },
  mixins: [form, drawer],
  props: {
    partner_id: {
      default: null,
    },
    filial_id: {
      default: null,
    },
    deal_id: {
      default: null,
    },
    direction_tariff_id: {
      default: null,
    },
    deal_calculate_money: {
      default: 'deal',
    },
    deal_packages: {
      default: Array,
    },
    deal_products: {
      default: Array,
    },
    status: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      dealProductsList: [],
      total_weight: 0,
      product_id: null,
      updateProducts: false,
      selectedProductIndex: null,
      dealPackages: [],
      currentName: '',
    };
  },
  watch: {
    direction_tariff_id: {
      handler: function (val) {
        if (val && this.dealProductsList && this.dealProductsList.length > 0) {
          this.updateProductPricesByTariff({
              products: this.dealProductsList.map(el => el.product_id),
              direction_tariff_id: val
            }).then(res => {
              this.dealProductsList.forEach((el, index)=> {
                let new_el = this.newPriceList.find(item => item.id === el.product_id );
                if(new_el){
                  this.$set(el, 'price', new_el.price);
                }else{
                  this.$set(el, 'price', 0);
                }
              })
            }).catch(err => {
              console.log(err, 'err');
            });
        } 
      },
      immediate: true,
      deep: true,
    },
    deal_packages: {
      handler: async function (newVal) {
        this.dealPackages = JSON.parse(JSON.stringify(newVal), true);
      },
      immediate: true,
      deep: true,
    },
    deal_products: {
      handler: async function (newVal) {
        this.dealProductsList = JSON.parse(JSON.stringify(newVal), true);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      system_settings: "auth/system_settings",
      authUser: "auth/user",
      permissions: "auth/permissions",
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
      mode: "MODE",
      currencies: "currency/inventory",
      services: "productServices/inventory",
      newPriceList: "productTariffPrices/newPriceList",
    }),
    // last_deal_id_for_new_calculation_rule(){
    //   return (this.system_settings && this.system_settings.last_deal_id_for_new_calculation_rule) ? this.system_settings.last_deal_id_for_new_calculation_rule : ''
    // },
    profitAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (parseFloat(product.currency_rate) * parseFloat(product.price) * parseFloat(product.profit) * parseFloat(product.weight)) / 100;
      });
      return parseFloat(total); 
    },
    totalAmount: function () {
      let total = 0;
      this.total_weight = 0;
      this.dealProductsList.forEach((product) => {
        total +=parseFloat(product.currency_rate) * parseFloat(product.price) * parseFloat(product.weight);
        this.total_weight += parseFloat(product.weight);
      });
      return parseFloat(total);
    },
    
  },
  methods: {
    ...mapActions({
      save: "dealProducts/store",
      update: "dealProducts/update",
      updateColumn: "dealProducts/updateColumn",
      show: "dealProducts/show",
      showProduct: "products/getWithTariffPrice",
      empty: "dealProducts/empty",
      emptyProduct: "products/empty",
      destroy: "dealProducts/destroy",
      deleteDealPackage: "packageForProductCirculations/deleteDealPackage",
      updateCurrencyInventory: "currency/inventory",
      updateProductPricesByTariff: "productTariffPrices/updateProductPricesByTariff",
      printPackageBarcode: "packageForProductCirculations/printPackageBarcode",
    }),
    packageProductList(pack) {
      let curr_pack = this.deal_packages.find(el => el.pack_id === pack.pack_id);
      return JSON.parse(JSON.stringify(curr_pack ? curr_pack.deal_products : ''));
    },
    removePackageTab(targetName){
      let tabs = JSON.parse(JSON.stringify(this.dealPackages));
      let pack_id = parseInt(targetName.replace('pack_','')); 
      let current_pack = tabs.find(el => el.pack_id === pack_id);
      this.currentName = '';
      this.$confirm(this.$t('message.do_you_really_want_to_do_this'), this.$t('message.warning'),
        { confirmButtonText: this.$t('message.yes'), cancelButtonText: this.$t('message.no'), type: "warning", })
      .then(() => {
        if(current_pack && current_pack.id){
          this.deleteDealPackage({id: current_pack.id})
            .then(res => {
              this.dealPackages = tabs.filter(el => el.pack_id != pack_id);
              this.$alert(res);
            });
        }else{
          this.dealPackages = tabs.filter(el => el.pack_id != pack_id);
        }
        
      })
      .catch(() => {
          this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
          });
      });
    },
    afterOpen(){
      if(this.dealPackages.length > 0){
        this.currentName = 'pack_' + this.dealPackages[0].pack_id; 
      }
    },
    async afterLeave(){
      await this.dispatchAll();
      // await this.emptyProducts();
    },
    emptyProducts() {
      if(this.$refs.productsTable){
        this.$refs.productsTable.dealProductsList = []
      }
    },
    async dispatchAll(){
      this.$emit('input', this.dealProductsList);
      await this.updatePackWeight();
      this.$emit('updateDealPackages', this.dealPackages);
    },
    updatePackWeight(){
      for (let pack of this.dealPackages) {
        let total_weight = 0;
        let total_quantity = 0;
        let total_money = 0;
        let product_price = 0;
        if(pack.deal_products && pack.deal_products.length > 0){
          pack.deal_products.forEach(item => {
            total_weight  += (parseFloat(item.weight) || 0);
            total_quantity  += (parseFloat(item.quantity) || 0);
            product_price  += (parseFloat(item.product_price) || 0);
            total_money  += (parseFloat(item.weight * item.currency_rate * item.price) || 0);
          });
        }
        this.$set(pack, 'weight', total_weight);
        this.$set(pack, 'dp_weight', total_weight);

        this.$set(pack, 'quantity', total_quantity);
        this.$set(pack, 'dp_quantity', total_quantity);

        this.$set(pack, 'total_money', total_money);

        this.$set(pack, 'price', total_money);
        this.$set(pack, 'dp_price', total_money);
        
        this.$set(pack, 'product_price', product_price);
      }
    },
    addNewPackage(){
      let last_pack_id = 1;
      if(this.dealPackages.length > 0){
        let last_pack = this.dealPackages[this.dealPackages.length-1];
        last_pack_id = last_pack.pack_id + 1;
      }
      let pack = {
        id: '',
        type: 'package',
        pack_id: last_pack_id,
        barcode: '',
        container_type_id: '',
        weight: 0,
        quantity: 0,
        total_money: 0,
        deal_products: [],
		  new_product: true,
		  tracking_code: ''
      };
      this.dealPackages.push(JSON.parse(JSON.stringify(pack)));
    },
    deletePackage({index}){
      this.dealPackages.splice(index, 1);
    },
    noMoreThan(index) {
        if(this.dealProductsList[index].profit >= 100){
          this.dealProductsList[index].profit = 99;
        }
        if(this.dealProductsList[index].profit < 0){
          this.dealProductsList[index].profit = 0;
        }
    },
    checkCurrency(row){
      let currency = this.currencies.find(curr => curr.id === row.currency_id);  
      if(currency && currency.active){
        return true;
      }
      return false;
    },
    check: function (column, event) {
      this.updateColumn({key: column, value: event,})
    },

    changeCurrency(row){
      if(row.currency_id){
        let currency = this.currencies.find(el => el.id === row.currency_id);
        if(currency){
          this.$set(row, 'currency_rate', currency.rate);
        }
      }
    },

    updateList(){
      this.loadingData = true;
      if(this.currencies && this.currencies.length === 0){
        this.updateCurrencyInventory();
      }
      this.show({id: this.deal_id})
          .then((res) => {
            this.dealProductsList = JSON.parse(JSON.stringify(this.dealProducts));
            this.updateProducts = true;
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
    },
    updateWeight(row){
      if(row.measuring_type_id && row.measuring_type_id === 2){
        let size_weight = (parseFloat(row.width) * parseFloat(row.length) * parseFloat(row.height)) / 6000;
        this.$set(row, 'weight', _.round(parseFloat(size_weight), 6));
      }else{
        let current_row = this.dealProducts.find(el => el.id === row.id);
        let old_weight = JSON.parse(JSON.stringify(current_row.weight));
        this.$set(row, 'weight',  _.round(parseFloat(old_weight), 6));
      }
    },
    
    updatePoundAndKG(row, type){
        if(type === 'weight_in_kg'){
            this.$set(row, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 3));
        }else{
            this.$set(row, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 3));
        }
    },
  
    getCurrencyCodeById(row){
      let currency = this.currencies.find(el => el.id === row.currency_id);
      if(currency){
        return currency.symbol
      }
      return '';
    },
  
    get_selected_product(selected_product_id){
      if(selected_product_id){
        this.showSelectedProduct(selected_product_id);
      }
    },
    productRowTotal(product) {
      let rowTotal = product.currency_rate * product.price * product.weight;
      return rowTotal;
    },

    showSelectedProduct(product_id) {
      this.showProduct({product_id: product_id, direction_tariff_id: this.direction_tariff_id})
        .then(() => {
          this.addToDealProductsList(product_id);
        }).then(() => {
          this.emptyProduct();
        });
    },
    addToDealProductsList(product_id){
      let item = {};
      let currency_id = this.getProduct.currency.id;
      let rate = 1
      if(currency_id){
        let currency = this.currencies.find(el => el.id === currency_id)
        if(currency){
          rate = currency.rate;
        }
      }
      item.id = null;
      item.deal_id = this.deal_id;
      item.product_id = product_id;
      item.price = this.getProduct.price;
      item.currency_id = currency_id;
      item.currency_rate = rate;
      item.product = JSON.parse(JSON.stringify(this.getProduct));
      item.quantity = 1;
      item.weight = this.getProduct.weight;
      item.weight_in_pounds = _.round(parseFloat(this.getProduct.weight) * parseFloat(this.$kg_to_pound), 3);
      item.profit = 0;
      item.comment = '';
      item.width = 0;
      item.height = 0;
      item.length = 0;
      item.measuring_type_id = null;
		item.tracking_code = '';
      this.dealProductsList.push(item);
    },

    removeFromDealProductsList(id, index) {
      if (id) {
        this.$confirm(
            this.$t('message.Are you sure you want to uninstall the product?'),
            this.$t('message.warning'),
            {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning",
            }
        )
        .then(() => {
            this.destroy(id)
              .then((res) =>{
                this.dealProductsList.splice(index, 1);
                this.$alert(res);
              })
              .catch((err) => {
                  this.$alert(err);
              })
        })
        .catch(() => {
            this.$message({
                type: "warning",
                message: this.$t('message.deleting_product_canceled'),
            });
        });
      }else{
          this.dealProductsList.splice(index, 1);
      }
    },

    printBarCode(container, type) {
        let payload = {container_id: container.id, barcode: container.barcode}
        if(type){
            payload = {container_id: container.id, barcode: container.barcode, type: type}
        }
        this.printPackageBarcode(payload)
        .then(res => {
            const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            WinPrint.document.write(res.data);
            WinPrint.document.close();
            WinPrint.focus();
            setTimeout(() => {
                WinPrint.print();
                WinPrint.close();
            }, 1000);
        })
        .catch(err => {
            this.$alert(err);
        });
    },
    
    // table footer all total summs  
    getSummaries(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = this.$t('message.total');
            return;
          }
          if (column.label === this.columns.price.title) {
            sums[index] = formatMoney(this.totalAmount, 2);
            return;
          }
          if (column.label === this.columns.weight.title) {
            sums[index] = formatNumber(this.total_weight, 1) + ' kg';
            return;
          }
          if (column.label === this.columns.weight_in_pounds.title) {
            sums[index] = formatNumber((parseFloat(this.total_weight) * this.$kg_to_pound), 1) + ' lbs';
            return;
          }
          if (column.label === (this.columns.profit.title + ' %')) {
            sums[index] = formatMoney(this.profitAmount, 2);
            return;
          }
          if (column.label === (this.columns.weight.title + ' %')) {
            sums[index] = formatMoney(parseFloat(this.weight), 2);
            return;
          }
          if (column.label === this.columns.total.title) {
            sums[index] = formatMoney(this.totalAmount, 2);
            return;
          }
        });

        return sums;
    },
    
  },
};
</script>
<style>
  .header__class {
    font-size: 12px;
  }
  .row__class {
    padding: 0 !important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
</style>
