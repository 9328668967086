export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    products: state => state.products, 
    waitingPackageList: state => state.waitingPackageList, 
    dealProducts: state => state.dealProducts, 
  };
  