<template>
    <div style="height: 428px">
        <div v-if="this.shipmentTerms && this.shipmentTerms.length > 0" id="chart">
            <apexchart
                type="donut"
                :height="size"
                :options="shipmentChart.chartOptions"
                :series="shipmentChart.series"
            ></apexchart>
        </div>
        <div v-else class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t("message.no_data_yet") }}
            </h1>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    components: {},
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        },
        size: {
            default: '395'
        }
    },
    
    data() {
        return{
            shipmentChart: {
                series: [],
                
                
                chartOptions: {
                    labels: [],
                    chart: {
                        type: 'donut',
                    },
                    plotOptions: { 
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                        formatter: function(val) {
                                            return 'count_product' + ': ' + val 
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                        label: 'overall'
                                    }
                                }
                            }
                        },
                    },
                    legend: {
                        show: true,
                        fontSize: '13px',
                        width: 170,
                        height: 390,
                        labels: {
                            colors: '#fff',
                            useSeriesColors: false
                        },
                        markers: {
                            strokeColor: '#fff',
                        },
                    },
                    responsive: [{
                    options: {
                        chart: {
                            width: 200
                        },
                       
                    }
                    }]
                },
            }
        }
    },
    watch: {
        watchAll: {
            handler: async function(val) {
                // setTimeout(() => {
                //     this.updateChartData({period: this.period, filter: this.filterForm});
                // }, 500);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            shipmentTerms: "home/shipmentTerms"
        }),
        watchAll() {
            return  (JSON.stringify(this.filterForm) + this.period);
        }
    },
    methods: {
        ...mapActions({
            updateShipmentTerms: "home/shipmentTerms"
        }),

        updateChartData(data){
            this.updateShipmentTerms(data)
                .then(() => {
                    if(this.shipmentTerms.length > 0){
                        this.shipmentChart.series = JSON.parse(JSON.stringify(this.shipmentTerms.map(item => item.deal_count)));
                        let self = this;
                        this.shipmentChart = {...this.shipmentChart, ...{
                            
                            chartOptions: {
                                labels: JSON.parse(JSON.stringify(this.shipmentTerms.map(item => item.name))),
                                plotOptions: { 
                                    pie: {
                                        donut: {
                                            labels: {
                                                value: {
                                                    formatter: function(val) {
                                                        return self.$t('message.count_product') + ': ' + val 
                                                    },
                                                },
                                                total: {
                                                    label: self.$t('message.overall'),
                                                    show: self.shipmentTerms.length > 1 ? true : false,
                                                    formatter: function (w) {
                                                        return self.shipmentTerms.map(item => item.deal_count).reduce((a, b) => a + b, 0);
                                                    },
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }}
                    }
                });
        },
    },
};
</script>
