<template>
  <div>
    <div class="gc-card__header d-flex align-center px-3 py-4">
        <i class="el-icon-share mr-2"></i>
            <span class="font-bold"> {{ $t("message.parcel") }} </span>
        <div class="d-flex w-100">
            <span class="w-100 text-right font-bold">
                <div class="d-block">
                    <span :class="form.weight > maxWeight ? 'mm__danger__color' : ''">
                        {{$t('message.max_weight_limit_message', { weight: maxWeight, measure: $t('message.kg')})}}
                    </span>
                    <br>
                    <span class="mt-2 d-inline-block" :class="totalSMSizeSum > maxSize ? 'mm__danger__color' : ''">
                        {{ $t('message.max_size_limit_message', { size: maxSize, measure: $t('message.sm')}) }}
                    </span>
                </div>
            </span>
        </div>
    </div>
    <el-row :gutter="20" class="pt-5 pb-5">
        <el-col :span="4">
            <div>
                <span>{{$t('message.price')}}</span>
                <el-input
                    :placeholder="$t('message.price')"
                    :disabled="authUser.client_id ? true : false"
                    :min="0"
                    type="number"
                    size="mini"
                    v-model="form.price"
                ></el-input>

                <div class="mt-1 mm-total-content">
                    <span> {{$t('message.total') }} {{totalMoneyAmount | formatMoney(2)}}</span>
                </div>
            </div>
        </el-col>
        <el-col :span="4">
            <div>
                <span>{{$t('message.weight')}}</span>
                <crm-product-slot-input
                    v-model="form.weight"
                    :inputValue="form.weight"
                    :placeholder="$t('message.weight')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input'"
                    :measurement_type="'kg'"
                    @input="updatePoundAndKG(form, 'weight_in_kg')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                    v-model="form.weight_in_pounds"
                    :inputValue="form.weight_in_pounds"
                    :placeholder="$t('message.weight')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input mt-1'"
                    :measurement_type="'lbs'"
                    @input="updatePoundAndKG(form, 'weight_in_pounds')"
                ></crm-product-slot-input>
            </div>
        </el-col>
        <el-col :span="4">
            <div>
                <span>{{$t('message.size_summ')}}</span>
                <crm-product-slot-input
                    :inputValue="totalSMSizeSum"
                    :placeholder="$t('message.size_summ')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input'"
                    :measurement_type="'sm'"
                    :disabled="true"
                ></crm-product-slot-input>

                <crm-product-slot-input
                    :inputValue="totalINCHSizeSum"
                    :placeholder="$t('message.size_summ')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mt-1 mm_custom_input'"
                    :measurement_type="'inch'"
                    :disabled="true" 
                ></crm-product-slot-input>

            </div>
        </el-col>
        <el-col :span="4">
            <div>
                <span>{{$t('message.length')}}</span>
                <crm-product-slot-input
                    v-model="form.length"
                    :inputValue="form.length"
                    :placeholder="$t('message.length')"
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input'"
                    :measurement_type="'sm'"
                    @input="updateWeightAndSizes(form, 'length')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                    v-model="form.length_on_inches"
                    :inputValue="form.length_on_inches"
                    :placeholder="$t('message.length')"
                    :type="'number'"
                    :size="'mini'"
                    :class="'mt-1 mm_custom_input'"
                    :measurement_type="'inch'"
                    @input="updateWeightAndSizes(form, 'length_on_inches')"
                ></crm-product-slot-input>
            </div>
        </el-col>
        <el-col :span="4">
            <div>
                <span>{{$t('message.width')}}</span>
                <crm-product-slot-input
                    v-model="form.width"
                    :inputValue="form.width"
                    :placeholder="$t('message.width')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input'"
                    :measurement_type="'sm'"
                    @input="updateWeightAndSizes(form, 'width')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                    v-model="form.width_on_inches"
                    :inputValue="form.width_on_inches"
                    :placeholder="$t('message.width')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mt-1 mm_custom_input'"
                    :measurement_type="'inch'"
                    @input="updateWeightAndSizes(form, 'width_on_inches')"
                ></crm-product-slot-input>
            </div>
        </el-col>
        <el-col :span="4">
            <div>
                <span>{{$t('message.height')}}</span>

                <crm-product-slot-input
                    v-model="form.height"
                    :inputValue="form.height"
                    :placeholder="$t('message.height')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input'"
                    :measurement_type="'sm'"
                    @input="updateWeightAndSizes(form, 'height')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                    v-model="form.height_on_inches"
                    :inputValue="form.height_on_inches"
                    :placeholder="$t('message.height')" 
                    :type="'number'"
                    :size="'mini'"
                    :class="'mt-1 mm_custom_input'"
                    :measurement_type="'inch'"
                    @input="updateWeightAndSizes(form, 'height_on_inches')"
                ></crm-product-slot-input>
            </div>
        </el-col>
    </el-row>
    <template>
        <el-table
            :data="form.parcel_products"
            show-summary 
            :row-class-name="tableRowClassName"
            :summary-method="getSummaries"
            border
            style="width: 100%">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column :label="$t('message.name')">
                <template slot-scope="scope">
                    <el-input
                        size="mini"
                        type="textarea"
                        :autosize="true"
                        show-word-limit
                        v-model="scope.row.name"
                    ></el-input>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.tracking_code_new')">
                <template slot-scope="scope">
                    <el-input disabled :value="scope.row.tracking_code" size="mini" @change="setOwnLen(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="quantity" :label="$t('message.quantity_y')">
                <template slot-scope="scope">
                    <el-input
                        :min="0"
                        type="number"
                        size="mini"
                        v-model="scope.row.quantity"
                    ></el-input>
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.product_price')">
                <template slot-scope="scope">
                    <el-input
                        type="number"
                        size="mini"
                        v-model="scope.row.product_price"
                    ></el-input>
                </template>
            </el-table-column>

            <el-table-column width="60" align="center">
                <template slot="header">
                    <i class="el-icon-s-tools"></i>
                </template>
                <template slot-scope="scope">
                    <i @click="removeFromDealProductsList(scope.row.id, scope.$index)" class="el-icon-delete icon__delete"></i>
                </template>
            </el-table-column>
            
        </el-table>
    </template>
	<div class="tracking_code_new mt-3 pb-5 gc-card__header">
		<span style="width: 200px;">{{ $t('message.tracking_code_new') }}</span>
		<el-input class="w-35 flex-1" v-model="form.tracking_code" size="medium" @input="setTrackingCodeToAll"></el-input>
		<el-button @click="addParcelProduct()" type="primary" plain>{{ $t('message.add_product') }}</el-button>
	</div>
  </div>
</template>

<script>
import crmProductSlotInput from '@/components/inputs/crm-product-slot-input';

import { formatMoney, formatNumber } from "@/filters/index";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import _ from 'lodash';

export default {
  components: {
    crmProductSlotInput,
  },
  mixins: [form, drawer],
  props: {
    mainForm: {
      default: Object,
    },
    shipping_service: {
      default: false,
    },
    loadingData: {
      default: false,
    },
    direction_tariff_id: {
      default: null,
    },
    partner_id: {
      default: null,
    },
    deal_id: {
      default: null,
    },
  },
  data() {
    return {
        maxSize: 300,
        form: {
            price: 0,
            weight: 0,
            width: 0,
            height: 0,
            length: 0,
            tracking_code: '',
            currency_rate: 0,
            parcel_products: [],
        },
		tracking_code: '',
    };
  },
  watch: {
    shipping_service: {
      handler: function (val) {
        if (this.form && !this.loadingData) {
            this.updatePrice();
        } 
      },
      immediate: true,
      deep: true,
    },
    tariff_and_partner_change: {
      handler: function (val) {
        if (this.direction_tariff_id && this.form) {
          this.updatePartnerPricesByTariff({
              direction_tariff_id: this.direction_tariff_id,
              partner_id: this.partner_id
            }).then(res => {
                if(!this.loadingData){
                    this.updatePrice();
                }
            }).catch(err => {
                console.log(err, 'error');
            });
        } 
      },
      immediate: true,
      deep: true,
    },
    form: {
        handler: async function(newVal, oldVal) {
            this.$emit('input', newVal);
        },
        deep: true,
        immediate: true
    },
    mainForm: {
        handler: async function(newVal, oldVal) {
            if(this.mainForm && this.mainForm.weight >= 0){
                this.form = JSON.parse(JSON.stringify(this.mainForm));
            }
        },
        deep: true,
        immediate: true
    },
  },
  computed: {
    ...mapGetters({
      system_settings: "auth/system_settings",
      authUser: "auth/user",
      partnerTariffPrice: "partnerTariffPrices/partnerTariffPrice",
    }),
    totalMoneyAmount(){
        let weight = this.form.weight;
        if(weight > 0 && weight < this.form.minimum_weight){
            weight = this.form.minimum_weight;
        }
        return this.form.price * weight;
    },
    // last_deal_id_for_new_calculation_rule(){
    //   return (this.system_settings && this.system_settings.) ? this.system_settings.last_deal_id_for_new_calculation_rule : ''
    // },
    tariff_and_partner_change(){
        return this.direction_tariff_id + this.partner_id + 'aa';
    },
    totalProductPrice() {
        if(this.form.parcel_products && this.form.parcel_products.length > 0){
            return this.form.parcel_products.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
        }
        return 0;
    },
    totalQuantity() {
        if(this.form.parcel_products && this.form.parcel_products.length > 0){
            return this.form.parcel_products.reduce((total, product) => parseFloat(total) + (parseFloat(product.quantity) || 0), 0);
        }
        return 0;
    },
    totalSMSizeSum(){
        let total = parseFloat(this.form.width || 0) + parseFloat(this.form.height || 0) + parseFloat(this.form.length || 0)
        return _.round(total, 1);
    },
    totalINCHSizeSum(){
        let total = parseFloat(this.form.width_on_inches || 0) + parseFloat(this.form.height_on_inches || 0) + parseFloat(this.form.length_on_inches || 0)
        return _.round(total, 1);
    },
    maxWeight() {
      let max_weight = this.shipping_service ? 30 : 30;
      return max_weight;
    },
  },
  methods: {
    ...mapActions({
      save: "dealProducts/store",
      deleteParcelProduct: "dealProducts/deleteParcelProduct",
      updatePartnerPricesByTariff: "partnerTariffPrices/updatePartnerPricesByTariff",
    }),
    addParcelProduct() {
        let prod = {
            id: null,
            name: '',
			tracking_code: '',
            quantity: 1,
            product_price: 0,
			has_own_len: false
        }
        this.form.parcel_products.push(prod);
    },
    updatePrice() {
        if(this.partnerTariffPrice && this.partnerTariffPrice.id && this.direction_tariff_id != this.partnerTariffPrice.id){
            let price = 0;
            price = JSON.parse(JSON.stringify(this.partnerTariffPrice.price_without_delivery));
            this.$emit('getDeliveryPrice', this.partnerTariffPrice.price_with_delivery);
            if(this.shipping_service){
                price = parseFloat(JSON.parse(JSON.stringify(this.partnerTariffPrice.price_with_delivery)));
            }
            this.$set(this.form, 'price',  price);
        }else{
            this.$set(this.form, 'price',  0);
            this.$emit('getDeliveryPrice', 0);
        }
    },
    removeFromDealProductsList(id, index) {
        if(id) {
            this.$confirm(
                this.$t('message.Are you sure you want to uninstall the product?'),
                this.$t('message.warning'),
                {
                    confirmButtonText: this.$t('message.yes'),
                    cancelButtonText: this.$t('message.no'),
                    type: "warning",
                }
            )
            .then(() => {
                this.deleteParcelProduct(id)
                    .then((res) => {
                        this.form.parcel_products.splice(index, 1);
                        this.$alert(res);
                    })
                    .catch((err) => {
                        this.$alert(err);
                    })
            })
            .catch(() => {
                this.$message({
                    type: "warning",
                    message: this.$t('message.deleting_product_canceled'),
                });
            });
        }else{
            this.form.parcel_products.splice(index, 1);
        }
    },
    tableRowClassName({row, rowIndex}){
        if(row.name.length === 0 || !row.quantity || row.quantity == parseFloat(0)){
          return 'danger-row';
        }
        return '';
    },
    updatePoundAndKG(row, type){
        if(type === 'weight_in_kg'){
          this.$set(row, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 3));
        }else{
          this.$set(row, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 3));
        }
    },
    updateWeightAndSizes(row, type){
        if(type){
            if(type === 'width'){
                this.$set(row, 'width_on_inches',  _.round(parseFloat(row.width) * this.$sm_to_inch, 2));
            }
            if(type === 'width_on_inches'){
                this.$set(row, 'width',  _.round(parseFloat(row.width_on_inches) / this.$sm_to_inch, 2));
            }

            if(type === 'height'){
                this.$set(row, 'height_on_inches',  _.round(parseFloat(row.height) * this.$sm_to_inch, 2));
            }
            if(type === 'height_on_inches'){
                this.$set(row, 'height',  _.round(parseFloat(row.height_on_inches) / this.$sm_to_inch, 2));
            }

            if(type === 'length'){
                this.$set(row, 'length_on_inches',  _.round(parseFloat(row.length) * this.$sm_to_inch, 2));
            }
            if(type === 'length_on_inches'){
                this.$set(row, 'length',  _.round(parseFloat(row.length_on_inches) / this.$sm_to_inch, 2));
            }
        }
    },
    getSummaries(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = this.$t('message.total');
            return;
          }
          if (column.label === this.$t('message.quantity_y')) {
            sums[index] = formatNumber(this.totalQuantity, 1);
            return;
          }
          if (column.label === this.$t('message.product_price')) {
            sums[index] = formatMoney(this.totalProductPrice, 2);
            return;
          }
        });

        return sums;
    },

	setTrackingCodeToAll(tracking_code){
		this.form.parcel_products.forEach(item => {
			if (!item.has_own_len) {
				item.tracking_code = tracking_code;
			}
		});
	},

	 setOwnLen(row){
		this.$set(row, 'has_own_len', true);
	 }
    
  },
};
</script>
<style>
  .header__class {
    font-size: 12px;
  }
  .mm-total-content {
    border-radius: 4px;
    height: 28px;
    line-height: 28px;
    vertical-align: middle;
    padding-left: 15px;
    background-color: #67C23A;
    color: #fff;
    font-weight: 600;
  }
  .row__class {
    padding: 0 !important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
	.tracking_code_new{
		display: flex;
    	align-items: center;
	}
	.tracking_code_new .w-35 input{
		width: 35%;
	}
	.tracking_code_new .flex-1{
		flex: 1;
	}
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .el-table .danger-row {
    background: #e9a5a9;
  }
  .mm__danger__color {
    color: #f32936;
  }
  
</style>
