import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";

export const state = {
    filter: filter,
    reasonsDeals: [],
    activities: [],
    saleByPeriod: [],
    countryDeals: [],
    dealGoals: [],
    dealPayments: [],
    tasksByCompleted: [],
    dealPaymentByCountry: [],
    dealPaymentSelectedPoint: [],
    dealPaymentTerms: [],
    productSellStatistics: [],
    categoryStatisticBySellingProduct: [],
    shipmentTerms: [],
    topSuccessfulDeals: [],
    dealTypesChart: [],
    stageDeals: [],
    clientStatistics: [],
    timeLineTasks: [],
    timeLineTaskGroups: [],
    stageDealPagination: JSON.parse(JSON.stringify(pagination)),
};
