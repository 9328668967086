import { index } from "@/api/contacts/callHistories";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    getPhoneCalls({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_PHONE_CALL_HISTORY", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

};
