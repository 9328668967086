export const getters = {
    filter: state => state.filter,
    reasonsDeals: state => state.reasonsDeals,
    activities: state => state.activities,
    saleByPeriod: state => state.saleByPeriod,
    countryDeals: state => state.countryDeals,
    dealGoals: state => state.dealGoals,
    dealPayments: state => state.dealPayments,
    tasksByCompleted: state => state.tasksByCompleted,
    dealPaymentByCountry: state => state.dealPaymentByCountry,
    dealPaymentSelectedPoint: state => state.dealPaymentSelectedPoint,
    dealPaymentTerms: state => state.dealPaymentTerms,
    productSellStatistics: state => state.productSellStatistics,
    categoryStatisticBySellingProduct: state => state.categoryStatisticBySellingProduct,
    shipmentTerms: state => state.shipmentTerms,
    topSuccessfulDeals: state => state.topSuccessfulDeals,
    dealTypesChart: state => state.dealTypesChart,
    stageDeals: state => state.stageDeals,
    clientStatistics: state => state.clientStatistics,
    stageDealPagination: state => state.stageDealPagination,
    timeLineTasks: state => state.timeLineTasks,
    timeLineTaskGroups: state => state.timeLineTaskGroups,
  };
  