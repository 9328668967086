import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: false,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    deal_id : {
        show: true,
        title: i18n.t('message.deal'),
        sortable: true,
        column: 'deal_id'
    },
    client : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client'
    },
    sender : {
        show: true,
        title: i18n.t('message.sender'),
        sortable: true,
        column: 'sender'
    },
    reciever : {
        show: true,
        title: i18n.t('message.reciever'),
        sortable: true,
        column: 'reciever'
    },
    partner : {
        show: true,
        title: i18n.t('message.partner'),
        sortable: true,
        column: 'partner'
    },
    weight : {
        show: true,
        title: i18n.t('message.weight_in_kg'),
        sortable: true,
        column: 'weight'
    },
    current_location : {
        show: true,
        title: i18n.t('message.current_location'),
        sortable: true,
        column: 'current_location'
    },
    from_filial : {
        show: true,
        title: i18n.t('message.from_filial'),
        sortable: true,
        column: 'from_filial'
    },
    to_filial : {
        show: true,
        title: i18n.t('message.to_filial'),
        sortable: true,
        column: 'to_filial'
    },
    deal_product_id : {
        show: true,
        title: i18n.t('message.product'),
        sortable: true,
        column: 'deal_product_id'
    },
    incoming_quantity : {
        show: true,
        title: i18n.t('message.incoming_quantity'),
        sortable: true,
        column: 'incoming_quantity'
    },
    total_quantity : {
        show: true,
        title: i18n.t('message.total_quantity'),
        sortable: true,
        column: 'total_quantity'
    },
    remainder : {
        show: true,
        title: i18n.t('message.remainder'),
        sortable: true,
        column: 'remainder'
    },
    barcode : {
        show: true,
        title: i18n.t('message.barcode'),
        sortable: true,
        column: 'barcode'
    },
    date : {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'date'
    },
    shipping_cost : {
        show: false,
        title: i18n.t('message.shipping_cost'),
        sortable: false,
        column: 'shipping_cost'
    },

    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
