<template>
  <div>
	<div class="main-services">
		<div>
			<i class="el-icon-s-operation"></i>
			<span>{{$t('message.services')}}</span>
		</div>
		<div class="select-services" v-if="permissions.some(per => per.slug == 'services.show')">
			<el-select 
				v-model="service_id" 
				clearable
				:placeholder="$t('message.services')" 
				class="w-100" 
				size="medium"
				filterable
				@change="selectedServiceItem"
				>
				<el-option 
					v-for="(item, index) in s_items" :key="index" 
					:label="item.name" 
					:value="item.id"
				>
				</el-option>
			</el-select>
		</div>
	</div>
	<div class="main-services-2" v-if="tableData && tableData.length > 0">
		<el-row :gutter="20">
			<el-col :span="24">
				<el-table
					:data="tableData"
					border
					style="width: 100%"
					v-loading="loadingData"
					show-summary
					:summary-method="getSummariesForService"
				>
					<el-table-column
						type="index"
						width="50">
					</el-table-column>

					<el-table-column :label="$t('message.name')">
						<template slot-scope="item">
							<span v-if="item.row.deal_id">{{ item.row.service.name }}</span>
							<span v-else> {{ item.row.name }} </span>
							<span v-if="item.row.calculate_by">( {{ calculateBy(item.row) }} )</span>
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.total_price')"  width="150">
						<template slot-scope="item">
							<el-input 
								v-model="item.row.service_sum" 
								:disabled="item.row.calculate_by == 2"
								:placeholder="$t('message.price')" 
								size="medium" 
							>
								<template slot="append">{{ calculationSymbol(item.row) }}</template>
							</el-input>
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.rate')" width="150">
						<template slot-scope="item">
							{{ item.row.rate }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.currency')"  width="120">
						<template slot-scope="item">
							<select-currency 
								v-if="item.row.service_sum && item.row.service_sum > 0"
								v-model="item.row.currency_id" 
								:id="item.row.currency_id" 
								@c-change="updateCurrency(item.row)"
								:disabled="disableRow"
							>
							</select-currency>
						</template>
					</el-table-column>
						
					<el-table-column :label="$t('message.total_price') + ' $'" width="130">
						<template slot-scope="item">
							<span>
								{{ countTotalMoney(item.row) }}
							</span>
						</template>
					</el-table-column>
					<el-table-column width="100">
						<template slot="header" slot-scope="item">
							<i class="el-icon-s-tools"></i>
						</template>
						<template slot-scope="item">
							<el-button
								v-if="item.row.calculate_by == 2"
								type="primary" 
								icon="el-icon-s-operation" 
								circle 
								@click="updateRowDealProducts(item.row, item.$index)" 
								size="small"
							></el-button>
							<el-button 
								type="danger" 
								icon="el-icon-delete" 
								circle 
								@click="item.row.deal_id ? deleteItemRow(item.row) : removeItemRow(item.row)" 
								size="small"
							></el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
	</div>
	<el-drawer
        class="bg-se"
        :visible.sync="drawerDealProductServices"
        :with-header="false"
        ref="drawerDealProductServices"
        @opened="drawerOpened('drawerDealProductServicesChild')"
        @closed="drawerClosed('drawerDealProductServicesChild')"
        :append-to-body="true"
        size="80%"
    >
        <DealProductService
			:selectedItem="selectedService"
			ref="drawerDealProductServicesChild"
			drawer="drawerDealProductServices"
			@getDealProductServices="getDealProductServices"
        />
    </el-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CrmMoneyInput from '@/components/crm/crm-money-input';
import SelectCurrency from '@/components/selects/select-currency';
import DealProductService from './crm-deal-product-services';
import _ from 'lodash';
import { formatMoney, formatNumber } from "@/filters/index";
import list from "@/utils/mixins/child_list";
export default {
	mixins: [list],
  	components: { CrmMoneyInput, SelectCurrency, DealProductService },
	props:{
		opened:{
			type: Boolean,
			default: null
		},
		cargo_type: {
			type: String,
			default: 'commercial'
		},
		emptyData: {
			type: Boolean,
			default: false
		},
		parcel: {
			default: Object,
		},
		allItems: {
			type: Array
		},
		deal_id: {
			default: null
		},
		deal_services: {
			type: Array,
			default: null
		},
		services: {
			type: Array,
			default: null
		},
		partner_id: {
			default: null
		},
		direction_tariff_id: {
			default: null
		},
	},
	computed: {
		...mapGetters({
			currencies: "currency/inventory",
			getServices: 'services/services',
			authUser: "auth/user",
			permissions: "auth/permissions",
            calculationTypes: 'services/calculationTypes',
			serviceCalculateTypes: 'services/serviceCalculateTypes',
			specificServices: "services/specificServices",
			autoSetServices: "services/autoSetServices",
		}),
		totalProductPriceParcel() {
			if(this.parcel.parcel_products && this.parcel.parcel_products.length > 0){
				return this.parcel.parcel_products.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
			}
			return 0;
    	},
		totalProductPriceCargo() {
			if(this.allItems && this.allItems.length > 0){
				return this.allItems.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
			}
			return 0;
    	},
		disableRow(){
			if (this.permissions.some(per => per.slug == 'services.update')) {
				return false; 
			}
			else return true;
		}
	},
	watch: {
		emptyData: {
			handler: function (new_data){
				if(new_data){
					this.tableData = [];
				}
			},
			deep: true,
			immadiate: true
		},
		'deal_services':{
			handler: function(data){
				this.tableData = JSON.parse(JSON.stringify(data));
			}
		},
		'allItems': {
			handler: function (new_data){
				if(this.s_items && this.s_items.length == 0){
					this.s_items = JSON.parse(JSON.stringify(this.services));
				}
				this.searchAndCollectServiceProducts(new_data);
			},
			deep: true,
			immadiate: true
		},
		'tableData': {
			handler: function(data){
				this.updateServices(data);
			},
			deep: true,
		},
		direction_tariff_id: {
			handler: function (){
				this.queryFilter(this);
			},
			deep: true,
			immadiate: true
		},
		partner_id: {
			handler: function (){
				this.queryFilter(this);
			},
			deep: true,
			immadiate: true
		},
	},
	data(){
		return {
			service_id: null,
			tableData: [],
			s_items: [],
			loadingData: false,
			drawerDealProductServices: false,
			selectedService: null,
			selectedServiceIndex: null,
		}
	},
	async created() {
		await this.getCalculationTypes();
		if(this.currencies && this.currencies.length == 0) this.updateCurrencies();
		if(this.autoSetServices && this.autoSetServices.length == 0) this.getAutoSetServices();
		if(this.serviceCalculateTypes && this.serviceCalculateTypes.length == 0) this.getServiceCalculateTypes();
	},
	methods: {
		...mapActions({
			show: 'services/show',
			updateServices: 'services/updateServices',
			deleteDealServiceItem: 'services/deleteDealServiceItem',
			querySearchServices: 'services/inventory',
			getCalculationTypes: 'services/getCalculationTypes',
			getServiceCalculateTypes: 'services/getServiceCalculateTypes',
			updateCurrencies: "currency/inventory",
			getSpecificServices: "services/getSpecificServices",
			getAutoSetServices: "services/getAutoSetServices",
		}),
		getDealProductServices(product_services) {
			this.$set(this.tableData[this.selectedServiceIndex], 'deal_product_services', JSON.parse(JSON.stringify(product_services)));
			this.updateServices(this.tableData);
			this.drawerDealProductServices = false;
		},
		updateRowDealProducts(row, index) {
			this.selectedService = row;
			this.selectedServiceIndex = index;
			this.drawerDealProductServices = true;
		},
		calculationSymbol(row) {
			let c_symbol = this.calculationTypes.find(el => el.id == row.calculation_type);
			return c_symbol ? c_symbol.symbol : '';
		},
		calculateBy(row) {
			let c_symbol = this.serviceCalculateTypes.find(el => el.id == row.calculate_by);
			return c_symbol ? c_symbol.name : '';
		},
		queryFilter: _.debounce(function(self) {
			let query = { partner_id: self.partner_id, direction_tariff_id: self.direction_tariff_id };
			self.querySearchServices(query).then(() => {
				self.s_items = JSON.parse(JSON.stringify(self.services));
			});
		}, 1000),
		selectedServiceItem(service_id){
			if (!_.find(this.tableData, {'service_id': service_id})) {
				if (!this.loadingData) {
					this.loadingData = true;
					this.show(service_id)
						.then(res => { 
							this.loadingData = false; 
							this.setFilterServiceData(res.service);
							this.service_id = null;
						}).catch((err) => {
							this.loadingData = false; 
							this.$alert(err);
						});
				}
			}
			else  this.service_id = null;
		},
		setFilterServiceData(data){
			let rate = null;
			let total_money = 0;
			let new_service = JSON.parse(JSON.stringify(data));
			if (new_service.currency_id && new_service.currency) {
				rate = _.round(parseFloat(new_service.currency.rate), 7);
			}else{
				let new_currency = this.getActiveCurrencyOrFirst();
				new_service.currency_id = new_currency ? new_currency.id : new_service.currency_id;
				new_service.currency = new_currency ? new_currency : new_service.currency;
				rate = _.round(parseFloat(new_currency.rate), 7);
			}
			if (this.cargo_type == 'commercial' && new_service.percent) {
				if (!_.isEmpty(this.allItems) ) {
					total_money = this.allItems.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
				}
			} else if(this.cargo_type == 'parcel' && new_service.percent){
				if (!_.isEmpty(this.parcel.parcel_products)) {
					total_money = this.parcel.parcel_products.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
				}
			}
			total_money = _.round((total_money * (new_service.percent / 100)), 2); 

			this.tableData.push({
				'id': null,
				'service_id': new_service.id,
				'deal_id': null,
				'name': new_service.name,
				'service_sum': new_service.service_sum,
				'currency_id': new_service.currency_id,
				'rate': rate,
				'currency': new_service.currency,
				'total_money': total_money,
				'calculation_type': new_service.calculation_type,
				'calculate_by': new_service.calculate_by,
			});
			this.searchAndCollectServiceProducts(this.allItems);
		},
		getActiveCurrencyOrFirst () {
			let currency = null;
			if(this.currencies && this.currencies.length > 0){
				currency = this.currencies.find(el => el.active == true);
				currency = currency ? currency : this.currencies[0];
			}
			return currency;
		},
		countTotalMoney(row) {
			if(row.calculation_type == 3){
				return this.rowTotals(row) + '$ / '+ this.rowTotals(row, 'pc_total') + '$';
			} else {
				return this.rowTotals(row) + '$';
			}
		},
		rowTotals(row, type = '') {
			let total = 0;
			let pc_weight_total = 0;
			if(row.calculation_type == 1){ // calculate by value itself
				total =  parseFloat(row.service_sum || 0) *  parseFloat(row.rate || 0);
			}
			else if((row.calculation_type == 2 || row.calculation_type == 3) && row.deal_product_services && type != 'pc_total') { // calculate by per quantity of product
				row.deal_product_services.forEach(el => {
					total += parseFloat(el.row_total || 0);
				});
			}
			else if(row.calculation_type == 3 && type == 'pc_total' && row.deal_product_services) { // calculate by per kg of product
				row.deal_product_services.forEach(el => {
					pc_weight_total += parseFloat(el.pc_row_total || 0);
				});
			} 
			else if(row.calculation_type == 4 && type != 'pc_total'){ // calculate by % from produt price total
				total = parseFloat(row.service_sum || 0) * parseFloat(this.totalProductPriceCargo || 0) / 100;
			}

			if(type == 'pc_total'){
				return _.round(pc_weight_total, 2);
			}
			return _.round(total, 2);
		},
		removeItemRow(item){
			if (this.tableData && this.tableData.length > 0) 
				this.tableData.splice(this.tableData.indexOf(item), 1);
		},
		deleteItemRow(item){
			if (item.id) {
				this.$confirm(
					this.$t('message.do_you_really_want_to_do_this'),
					this.$t("message.warning"), {
						confirmButtonText: this.$t("message.yes"),
						cancelButtonText: this.$t("message.no"),
						type: "warning"
					}
					)
					.then(() => {
						this.deleteDealServiceItem(item.id).then((res) => {
							this.tableData.splice(this.tableData.indexOf(item), 1);
							this.$alert(res)
						}).catch((err) => {
							this.$alert(err)
						})
					})
					.catch(() => {
					this.$message({
						type: "warning",
						message: this.$t("message.operation_canceled")
					});
					});
			}
		},
		updateCurrency(item){
			if (item.currency_id) {
				let currency = _.find(this.currencies, function (c) {return c.id == item.currency_id});
				let rate = parseFloat(currency.rate.toFixed(7));

				this.$set(item, 'currency', currency);
				this.$set(item, 'rate', rate);
			}
			else {
				this.$set(item, 'rate', 0);
			}
		},
		changePercent(item){
			let total_money = 0;
			if (item.percent){
				if (this.cargo_type == 'commercial') {
					total_money = _.round(this.totalProductPriceCargo * (item.percent / 100), 2);  
				}
				else {
					total_money = _.round(this.totalProductPriceParcel * (item.percent / 100), 2);
				}
			}
			this.$set(item, 'total_money', total_money);
		},
		searchAndCollectServiceProducts(data) {
			if(this.direction_tariff_id && this.tableData.length > 0){
				let service_ids = this.tableData.map(el => el.service_id);
				let product_ids = [];
				data.forEach(el => {
					if(el.type == 'package'){
						let pack_prod_ids = el.deal_products.map(p_prod => p_prod.product_id);
						product_ids = product_ids.concat(pack_prod_ids);
					}else{
						product_ids.push(el.product_id)
					}
				});
				let querySpecServ = {service_ids: service_ids, product_ids: product_ids, direction_tariff_id: this.direction_tariff_id, partner_id: this.partner_id};
				this.collectServiceProducts(data, querySpecServ);
				
			}

		},
		collectServiceProducts: _.debounce( function(data, querySpecServ) {
			this.getSpecificServices(querySpecServ)
				.then(res => {
					if (this.tableData) {
						this.tableData.forEach(service => {
							if(service.calculate_by == 2){ // calculation of service by products
								if(!service.deal_product_services){
									service.deal_product_services = [];
								}
								let product_service = {
									id: '',
									value: '',
									product_name: '',
									deal_product_id: '',
									barcode: '',
									packed: false,
									deal_service_id: service.id,
									currency_rate: service.rate,
									currency_id: service.currency_id,
									currency: service.currency,
									calculation_type: service.calculation_type,
									comment: '',
									product_price: 0,
									weight: 0,
									quantity: 0,
									pc_weight: 0,
									row_total: 0,
									pc_row_total: 0,
								} 
								let service_total = 0;
								data.forEach((prod, index)=> {
									let existing_service_index = null;
									let new_service = null;
									if(prod.type == 'package'){
										prod.deal_products.forEach(pack_prod => {
											existing_service_index = service.deal_product_services.findIndex(ser => ser.deal_product_id == pack_prod.id);
											if(pack_prod.id) {
												product_service.product_name = pack_prod.product ? pack_prod.product.name : '';
												product_service.deal_product_id = pack_prod.id;
												product_service.barcode = prod.barcode;
												product_service.packed = true;
												product_service.comment = pack_prod.comment;
												product_service.product_price = pack_prod.product_price;
												product_service.weight = pack_prod.weight;
												product_service.pc_weight = pack_prod.pc_weight;
												product_service.quantity = pack_prod.quantity;
												if(existing_service_index < 0){
													let foun_service = this.findService(service, prod);
													product_service.value = foun_service ? foun_service.value : 0;
													product_service.calculation_type = foun_service ? foun_service.calculation_type : 1;
													new_service = JSON.parse(JSON.stringify(product_service));
													new_service.row_total = this.calculateRowTotal(new_service);
													if(new_service.calculation_type == 3){
														new_service.pc_row_total = this.calculateRowTotal(new_service, 'pc_weight');
													}
													service.deal_product_services.push(new_service);
												}else if (existing_service_index >=0){
													product_service.id 	  = service.deal_product_services[existing_service_index].id;
													product_service.value = service.deal_product_services[existing_service_index].value;
													product_service.calculation_type = service.deal_product_services[existing_service_index].calculation_type;
													new_service = JSON.parse(JSON.stringify(product_service));
													new_service.row_total = this.calculateRowTotal(new_service);
													if(new_service.calculation_type == 3){
														new_service.pc_row_total = this.calculateRowTotal(new_service, 'pc_weight');
													}
													service.deal_product_services[existing_service_index] = new_service;
												}
											}
											service_total += new_service ? parseFloat(new_service.row_total || 0) : 0; 
										});
									}else {
										existing_service_index = service.deal_product_services.findIndex(el => el.deal_product_id == prod.id);
										if(prod.id){
											product_service.product_name = prod.product ? prod.product.name : '';
											product_service.deal_product_id = prod.id;
											product_service.barcode = prod.barcode;
											product_service.comment = prod.comment;
											product_service.product_price = prod.product_price;
											product_service.weight = prod.weight;
											product_service.pc_weight = prod.sum_coming_weight;
											product_service.quantity = prod.quantity;
											if(!existing_service_index < 0){
												let foun_service = this.findService(service, prod);
												product_service.value = foun_service ? foun_service.value : 0;
												product_service.calculation_type = foun_service ? foun_service.calculation_type : 1;
												new_service = JSON.parse(JSON.stringify(product_service));
												new_service.row_total = this.calculateRowTotal(new_service);
												if(new_service.calculation_type == 3){
													new_service.pc_row_total = this.calculateRowTotal(new_service, 'pc_weight');
												}
												service.deal_product_services.push(new_service);
											}else if(existing_service_index >= 0) {
												product_service.id 	  = service.deal_product_services[existing_service_index].id;
												product_service.value = service.deal_product_services[existing_service_index].value;
												product_service.calculation_type = service.deal_product_services[existing_service_index].calculation_type;
												new_service = JSON.parse(JSON.stringify(product_service));
												new_service.row_total = this.calculateRowTotal(new_service);
												if(new_service.calculation_type == 3){
													new_service.pc_row_total = this.calculateRowTotal(new_service, 'pc_weight');
												}
												service.deal_product_services[existing_service_index] = new_service;
											}
										}
										service_total += new_service ? parseFloat(new_service.row_total || 0) : 0; 
									}
								});
								service.service_sum = _.round(service_total, 2);
							}else if(service.calculate_by == 3) {
								let unqiueTrackNumbers = [];
								data.forEach(prod => {
									if(prod.tracking_code && !unqiueTrackNumbers.includes(prod.tracking_code)){
										unqiueTrackNumbers.push(prod.tracking_code);
									}
								});

								let track_count = unqiueTrackNumbers.length > 0 ? unqiueTrackNumbers.length : 1;
								let autoService = this.autoSetServices.find(el => el.id == service.service_id);
								if(autoService){
									service.service_sum = track_count * autoService.service_sum;
								}
							}
						});
					}
				})
				.catch(err => {
					console.log(err, 'err servoce');
				})
			
		}, 1000),
		findService(service, product) {
			let p_service = this.specificServices.find(el => el.product_id == product.product_id && el.service_id == service.service_id);
			if(!p_service) {
				p_service = this.specificServices.find(el => el.service_id == service.service_id);
			}
			if(!p_service){
				p_service = {
					value: service.service_sum,
					calculation_type: service.calculation_type
				};
			}
			return p_service
		},
		calculateRowTotal(row, key = '') {
			let total = 0;
			if(row.calculation_type == 1){ // calculate by value itself
				total =  parseFloat(row.value || 0) *  parseFloat(row.currency_rate || 0);
			}
			else if(row.calculation_type == 2) { // calculate by per quantity of product
				total = parseFloat(row.value || 0) * parseFloat(row.quantity || 0) * parseFloat(row.currency_rate || 0);
			}
			else if(row.calculation_type == 3 && key && key == 'pc_weight') { // calculate by per kg of product
				total = parseFloat(row.value || 0) * parseFloat(row.pc_weight || 0) * parseFloat(row.currency_rate || 0);
			}
			else if(row.calculation_type == 3) { // calculate by per kg of product
				total = parseFloat(row.value || 0) * parseFloat(row.weight || 0) * parseFloat(row.currency_rate || 0);
			}
			else if(row.calculation_type == 4) { // calculate by percent from product total
				total = parseFloat(row.value || 0) * parseFloat(row.product_price || 0) * parseFloat(row.currency_rate || 0) / 100;
			}
			return _.round(total, 2);
		},
		getSummariesForService(param) {
			const { columns, data } = param;
			const sums = [];
         	columns.forEach((column, index) => {
				if (index === 1) {
					sums[index] = this.$t('message.total_amount');
					return;
				}

				if (index == 5) {
					sums[index] = this.getTotals() + ' / ' +this.getTotals('pc_total');
					return;
				}
			});

			return sums;
      	},
		getTotals(type = '') {
			let total = 0;
			let data = (JSON.parse(JSON.stringify(this.tableData)));
			data.forEach(element => {
				total += this.rowTotals(element, type);
			});

			return formatMoney(total, 2);
		}
	} 
}
</script>

<style lang="scss">
.main-services {
	width: 50%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.select-services{
	width: 80%;
}
.main-services-2{
	margin-top: 20px;
}
  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .value {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .link {
        font-size: 12px;
        color: #b4b4b4;
      }
    }
  }
</style>