export const model = {
  id : '',
  new_client: true,  
  name: '', 
  type: null, 
  phone: '', 
  phone_country_code: 'UZ',
  email: '', 
  inn: '', 
  gender_id: null, 
  country_id: null, 
  city_id: null, 
  clientTypes: [], 
  clientType_ids: [], 
  custom_id: '',
  has_iin: false,
  passport_iin: '',
  passport_number: '',
  passport_given_by: '',
  passport_given_date: '',
  passport_series: '',
  created_at: '',
  updated_at: ''  
  };
  