export const filter = {
  id : '', 
  deal_id: null,
  client_id: null,
  to_client_id: null,
  partner_client_id: null,
  from_filial_id: '',
  to_filial_id: '',
  barcode: '', 
  created_at: '',
  updated_at: '',
  search: '' 
};
