<template>
    <div>
        <div class="pr__navbar" :class="mode ? 'top__day' : 'top__night'">
            <div class="topnav__box">
                <div class="pr__flex">
                    <!-- <div v-if="true" class="menu__toggle" @click="menuToggle">
                        <span class="icon__menu" :class="mode ? 'icon__menuday' : 'icon__menunight'">
                            <span class="lnr lnr-menu"></span>
                        </span>
                    </div> -->
                    <router-link tag="div" to="/" class="logo menu__logot cursor-pointer">
                        <img v-if="mode" src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/gocrm.svg?alt=media&token=aee82924-7449-4870-9b47-76b3ded29d0e" alt="">
                        <img v-else src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/gocrm.svg?alt=media&token=aee82924-7449-4870-9b47-76b3ded29d0e" alt="">
                    </router-link>

                    <div class="topnav__breadcrumb">
                        <nav class="pr__flex" aria-label="Breadcrumb">
                          <ol class="breadcrumb__ul">
                              <li v-for="(link, index) in links" :key="index" :class="$route.fullPath == link.link ? 'breadcrumb__active' : ''" class="breadcrumb__li">
                              <router-link tag="a" :to="{ name: link.link }" :class="mode ? 'breadcrumb__itemday' : 'breadcrumb__itemnight'" class="breadcrumb__item">
                                  # {{ $t(`${link.name}`) }}
                              </router-link>
                              </li>
                          </ol>
                        </nav>
                    </div>
                </div>
                <div class="topnav__right">
                    <div class="topnav__fast">
                        <ul>
                            <li >
                                <el-badge :value="newNotificationsCount" :max="100" :hidden="newNotificationsCount === 0 ? true : false" class="custom__badge">
                                    <a @click="messageOpen"  :class="[mode ? 'fast__iconday' : 'fast__icon']">
                                        <i class="fa-solid fa-bell"></i>
                                    </a>
                                </el-badge>
                              <Message @outside="messageOutside" :message="message" />
                            </li>
                            <li v-if="lockPassword.length > 1">
                                <a @click="lockUser()" :class="[mode ? 'fast__iconday' : 'fast__icon']">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"/>
                                        <path d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z" fill="currentColor"/>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a :class="[mode ? 'fast__iconday' : 'fast__icon']" @click="fullMode">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="black"></path>
                                        <path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="black"></path>
                                        <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="black"></path>
                                    </svg>
                                </a>
                            </li>
                            <!-- <li>
                                <router-link tag="a" :to="{name : 'game.index'}" :class="[mode ? 'fast__iconday' : 'fast__icon']">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"/>
                                        <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"/>
                                    </svg>
                                </router-link>
                            </li> -->
                            <li>
                                <a @click="popup =!popup" :class="[mode ? 'fast__iconday' : 'fast__icon']">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z" fill="currentColor"/>
                                </svg>
                                </a>
                                <div v-show="popup" @click="popup = false" class="pop__all">
                                <div class="pop__box">
                                    <div class="pop__header" :class="mode ? 'pop__headerday' : 'pop__headernight'">
                                        <h5 :class="[mode ? 'text__color7' : 'text__color1']">Quick Links</h5>
                                        <span :class="[mode ? 'text__color7' : 'text__color1']">25 pending</span>
                                    </div>
                                    <div class="pop__center" :class="mode ? 'pop__centerday' : 'pop__centernight'">
                                        <el-row>
                                            <el-col :span="12">
                                            <a href="" class="popup__item popup1">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/back%2Flogo_light.png?alt=media&token=2b7f998b-349f-473a-97db-3733364acbbf" alt="">
                                                <span :class="[mode ? 'text__color7' : 'text__color1']">GoHr</span>
                                                <p>eCommerce</p>
                                            </a>
                                            </el-col>
                                            <el-col :span="12">
                                            <a href="" class="popup__item popup1">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/back%2Flogo_light.png?alt=media&token=2b7f998b-349f-473a-97db-3733364acbbf" alt="">
                                                <span :class="[mode ? 'text__color7' : 'text__color1']">GoMax</span>
                                                <p>eCommerce</p>
                                            </a>
                                            </el-col>
                                            <el-col :span="12">
                                            <a href="" class="popup__item popup1">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/back%2Flogo_light.png?alt=media&token=2b7f998b-349f-473a-97db-3733364acbbf" alt="">
                                                <span :class="[mode ? 'text__color7' : 'text__color1']">GoHr</span>
                                                <p>eCommerce</p>
                                            </a>
                                            </el-col>
                                            <el-col :span="12">
                                            <a href="" class="popup__item popup1">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/back%2Flogo_light.png?alt=media&token=2b7f998b-349f-473a-97db-3733364acbbf" alt="">
                                                <span :class="[mode ? 'text__color7' : 'text__color1']">GoMax</span>
                                                <p>eCommerce</p>
                                            </a>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div class="pop__footer" :class="mode ? 'pop__footerday' : 'pop__footernight'">
                                        <a>{{ $t("message.wiev_all") }}</a>
                                    </div>
                                </div>
                                </div>
                            </li>
                            <li class="settings__outside">
                                <a @click="clickInside" v-click-outside="onClickOutside" class="unique__settings" :class="[mode ? 'fast__iconday' : 'fast__icon']">
                                {{ role ? role.slice(0,1) : 'Z' }}
                                </a>
                                <div v-show="settings">
                                    <div class="settings__inside">
                                        <ul class="settings__ul">
                                            <router-link tag="li" :to="{ name: 'settingprofile' }" class="settings__item">
                                                <span>{{ $t("message.settings profile") }}</span>
                                            </router-link>
                                            <!-- <router-link tag="li" :to="{ name: 'settingsCompany' }" class="settings__item">
                                                <span>{{ $t("message.settingCompany") }}</span>
                                            </router-link> -->
                                            <li @click="logout" class="settings__item">
                                                <span>{{ $t("message.logout") }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LockScreenMixin from '../../utils/mixins/LockScreenMixin'
import Message from '../../components/prcomponents/Message'

const bcrypt = require('bcryptjs')
const salt = bcrypt.genSaltSync(10)
import { mapGetters } from 'vuex'



export default {
    name: 'topNav',
    components: {
      Message
    },
    mixins: [LockScreenMixin],
    data: () => ({
        popup: false,
        drawerMiniSettings: false,
        settings: false,
        showSetLockPasswordModal: false,
        password: '1234',
        repeat_password: '',
        message: false
    }),
    watch:{
        $route (to, from){
            this.getTest()
        }
    },
    mounted () {
        this.getTest()
        if(!localStorage.getItem('sys_language')){
          localStorage.setItem('sys_language', this.$i18n.locale);
        }
    },
    computed: {
        ...mapGetters({
            role: 'auth/name',
            mode: "MODE",
            leftVersion: "LEFTVERSION",
            links: "LINKS",
            systemLanguages: "systemLanguages",
            notificationList: "systemNotifications/user_notifications",
        }),
        test () {
            return this.$store.getters[`${this.name}`]
        },
        newNotificationsCount(){
          return this.notificationList.filter(el => el.unread === false).length;
        }
    },
    methods: {
        messageOpen () {
              setTimeout(() => this.message = true, 0);
        },
        messageOutside () {
            if(this.message) {
                this.message = false
            }
        },
        lockUser(){
            // set lock password if it's not set
            if(this.lockPassword === ''){
                this.showSetLockPasswordModal = true;
            } else {
                this.TRIGGER_LOCK({ status: true });
            }
        },
        setLanguage(val) {
          if(this.$i18n.locale !== val){
            localStorage.setItem('sys_language', val);
            this.$i18n.locale = val;
            window.location.reload();
          }
        },
        fullMode(e) {
            const full = document.documentElement
            this.fullSize = true
            if (full.requestFullscreen) {
                full.requestFullscreen()
            } else if (full.msRequestFullscreen) {
                full.msRequestFullscreen()
            } else if (full.mozRequestFullscreen) {
                full.mozRequestFullscreen()
            } else if (full.webkitRequestFullscreen) {
                full.webkitRequestFullscreen()
            }
        },
        menuToggle () {
            this.$store.commit('toggleMenu')
        },
        getTest () {
            this.name = this.$route.name.substring(0, this.$route.name.length - 6) + '/total_price'
        },
        onClickOutside () {
            this.settings = false
        },
        clickInside () {
            this.settings =!this.settings
        },
        async logout() {
            await this.$store.dispatch("auth/logout");
            this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        },
    }
}
</script>
<style scoped>
.pr__navbar {
    z-index: 1002;
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
}
.settings__outside {
    position: relative;
}
.settings__inside {
    display: flex;
    position: absolute;
    width: 180px;
    left: 0;
    background: #fff;
    border-radius: 3px;
    margin-left: -140px;
    margin-top: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.settings__item a {
    background: #fff ;
}
.settings__ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 0 !important;
}
.settings__item {
    display: flex;
    widows: 100%;
    margin-left: 0 !important;
    cursor: pointer;
    padding: 3px 0px 3px 20px !important;

}
.settings__item:hover {
    background-color: rgba(34, 34, 34, 0.091);
}
.settings__item {
    font-size: 13px;
    font-weight: 400;
}
.settings__item a:hover {
    background: #fff !important;
}
.pr__flex {
    display: flex;
}
.logo {
    margin-left: 0px;
    margin-right: 55px;
    margin-bottom: 0 !important;
}
.logo img {
    width: auto !important;
    height: 2rem;
}
.menu__toggle {
  display: flex;
  align-items: center;
}
.menu__toggle {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    margin-left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: opacity .1s ease-out;
}
.menu__toggle {
    margin-right: 30px;
}
.menu__toggle:active {
    background-color: rgba(0, -1, -2, 0.05);
    border: 1px solid #e8e5dd;
    border-radius: 50%;
}
.topnav__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.position__relative {
  position: relative;
}

.topnav__icon svg rect {
  fill: #92929f;
}
.topnav__input {
  padding: 0.50rem 0.5rem;
  outline: none;
  background: inherit;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
}
.topnav__inputday {
  padding: 0.50rem 0.5rem;
  outline: none;
  background: inherit;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #323248;
}
.topnav__icon svg {
  margin-top: 3px;
}
.topnav__right {
  display: flex;
  align-items: center;
}
.menu__logot {
  display: flex;
  align-items: center;
}
.topnav__nameinfo {
  margin-left: 20px;
}
.topnav__breadcrumb {
  display: flex;
  align-items: center;
}
.topnav__nameinfo h5 {
  font-size: 1.5rem!important;
  font-weight: 600!important;
  margin: 0;
}
.topnav__breadcrumb div span {
  font-size: .95rem;
  color: #565674;
}
.topnav__breadcrumb div:last-child {
  color: #fff !important;
}
.topnav__fast ul {
  padding: 0;
  display: flex;
  margin: 0;
}
.topnav__fast ul li {
  display: flex;
  margin-left: 13px;
}
.unique__settings {
  background: #0d67c9;
  color: #fff !important;
  font-weight: 700 !important;
}
.unique__settings:hover {
    color: #0d67c9 !important;
}
.topnav__fast ul {
  padding: 0;
  display: flex;
}
.topnav__fast ul li {
  display: flex;
  margin-left: 13px;
}
.fast__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem - 8px);
  width: calc(1.5em + 1.5rem - 8px);
  cursor: pointer;
  border: 1px solid #323248;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
}
.fast__iconday {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem - 8px);
  width: calc(1.5em + 1.5rem - 8px);
  cursor: pointer;
  border: 1px solid #e8e5dd;
  font-weight: 500;
  line-height: 1.5;
  color: #323248;
  border-radius: 10px;
  text-decoration: none;
}
.fast__icon:hover {
  color: #cdcdde;
  border-color: #323248;
  background-color: #323248!important;
}
.fast__icon svg path, .fast__icon svg rect, .fast__icon i {
  fill: #92929f;
  color: #92929f;
}
.fast__icon:hover svg rect {
  fill: #0d67c9;
}
.fast__icon:hover svg path {
  fill: #0d67c9;
}
.fast__icon:hover i {
  color: #0d67c9;
}

.fast__iconday:hover {
  color: #cdcdde;
  border-color: #f8f6f2;
  background-color: #f8f6f2!important;
}
.fast__iconday svg path, .fast__iconday svg rect, .fast__iconday i {
  fill: #92929f;
  color: #92929f;
}
.fast__iconday:hover svg rect {
  fill: #0d67c9;
}
.fast__iconday:hover svg path {
  fill: #0d67c9;
}
.fast__iconday:hover {
  color: #0d67c9;
}
.icon__menu {
  display: flex;
  align-items: center;
}
.icon__menu span {
  font-size: 24px;
}
.icon__menuday span {
  color: #030303;
}
.icon__menunight span {
  color: #fff;
}
.breadcrumb__ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
  margin-top: 0;
}
.breadcrumb__itemday {
  color: #92929f;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  display: flex;
  border: 1px solid #efe9e1;
  border-radius: 10px;
  height: 37.59px;
  padding: 0 10px;
  cursor: pointer;
}

.breadcrumb__itemnight {
  color: #fff;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #323248;
  border-radius: 10px;
  height: 37.59px;
  cursor: pointer;
}

.breadcrumb__active a {
  color: #0d67c9;
  border-color: #0d67c9;
}
.breadcrumb__itemday:hover {
  color: #0d67c9;
  margin-right: 10px;
  border-color: #f8f6f2;
  background-color: #f8f6f2!important;

}
.breadcrumb__itemnight:hover {
  color: #0d67c9;
  margin-right: 10px;
  border-color: #323248;
  background-color: #323248!important;

}
.breadcrumb__li {
    display: flex;
}
.breadcrumb__li a::after {
  content: ' | ';
}
.breadcrumb__li a:last-child::after {
  content: '';
}
.breadcrumb__li::marker {
  display: none;
}
.top__day {
  background: #fff;
  transition: opacity .1s ease-out;
}
.top__night {
  background: #080c24;
  box-shadow: rgb(204 219 232 / 10%) 3px 3px 6px 0px inset, rgb(255 255 255 / 3%) -3px -3px 6px;
  transition: opacity .1s ease-out;
}
.pop__box {
  width: 325px;
  margin-left: auto;
  background: white;
  border-radius: 10px;
  margin-right: 65px;
  margin-top: 60px;
}
.pop__header {
  text-align: center;
  padding: 20px 0;
  border-radius: 7px 7px 0 0;
}
.pop__headerday {
    background-image: linear-gradient(to right top, #0c0e1d, #0c0e22, #0c0e27, #0b0e2b, #0b0e30);
}
.pop__headernight {
    background-image: linear-gradient(to right top, #0c0e1d, #0c0e22, #0c0e27, #0b0e2b, #0b0e30);
}
.pop__header h5 {
  color: #fff;
  font-size: 17.55px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pop__header span {
  background: #0d67c9;
  border-radius: 0.35rem;
  padding: 2px 8px;
}
.pop__center {
  width: 100%;
}
.pop__centerday {
    background: #080c24;
}
.pop__centernight {
    background: #080c24;
}
.pop__footer {
  text-align: center;
  padding: 10px 0;
  border-radius: 0 0 7px 7px;
}
.pop__footerday {
    background: #080c24;
}
.pop__footernight {
    background: #080c24;
}
.pop__footer a {
  cursor: pointer;
  color: #6d6d80;
}
.pop__footer a:hover {
  color: #0d67c9;
}
.popup__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19.25px;
  text-decoration: none;
  cursor: pointer;

}
.popup__item:hover {
  background: rgba(133, 133, 133, 0.2);
}
.popup__item img {
  height: 2.25rem !important;
  width: auto;
  object-fit: contain;
}
.popup__item span {
  color: #cdcdde;
  font-size: 14.95px;
  margin-top: 10px;
}
.popup__item p {
  color: #474761;
}
.popup1 {
  border-bottom: 1px solid #2b2b40;
  border-right: 1px solid #2b2b40;
}
.popup2 {
  border-bottom: 1px solid #2b2b40;
}
.popup3 {
  border-right: 1px solid #2b2b40;
  border-bottom: 1px solid #2b2b40;
}
.popup4 {
  border-bottom: 1px solid #2b2b40;
}
.pop__all {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  background: rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
}
.mm__icon-button__badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.custom__badge .el-badge__content {
    height: 20px !important;
}
</style>
