<template>
    <div class="testi">
        <TheHeader v-if="oldVersion" />
        <PrHeader v-if="leftVersion" />
        <div v-if="leftVersion" class="app__row pr__outside" >
            <div v-if="true">
                <menu-left :isMenuOpen="true" :mode="mode" />
            </div>
            <div :style="{ '--bgImage': `url('${background.url}')` }" :class="[mode ? 'right__sideday' : 'right__sidenight']" class="test__back">
                <!-- <double-line v-if="doubleLine" /> -->
                <section class="app-main">
                    <router-view class="margin__top"> </router-view>
                </section>  
            </div>
        </div>
        <section v-if="oldVersion" class="app-main">
            <router-view  class="margin__top"> </router-view>
        </section>  
        <Call />

        <div>
            <el-dialog
                :title="$t('message.calendarNotification')"
                :visible.sync="centerDialogVisible"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                width="30%"
                center>

                <div v-for="note in showingData" :key="note.id">
                    <p><span style="margin-right: 10px">{{ $t("message.name") }}:</span> {{ note.title }} </p>
                    <p><span style="margin-right: 10px">{{ $t("message.comment") }}:</span> {{ note.comment ? note.comment : '-' }} </p>
                    <p>Окончание: {{ note.start ? note.start : 'не указан' }}</p>
                    <hr>
                </div>
                
            </el-dialog>
        </div>
        <div class="builder">
            <a @click="drawerBuilderBack = true" class="builder__item">
                <i class="el-icon-s-operation"></i>
                <span>BUILDER</span>
            </a>
        </div>
        <el-drawer :with-header="false" :visible.sync="drawerBuilderBack" size="28%" ref="drawerBuilderBack" @closed="drawerClosed('drawerBuilderBackChild')" @opened="drawerOpened('drawerBuilderBackChild')">
            <Builder drawer="drawerBuilderBack" ref="drawerBuilderBackChild" />
        </el-drawer>
    </div>
</template>

<script>
const TheHeader = () =>
    import(/*webpackChunkName: "TheHeader"*/ "./components/TheHeader");
import Call from "./components/call";
import PrHeader from './components/PrHeader.vue'
import MenuLeft from './components/MenuLeft.vue'
import Builder from '../components/prcomponents/Builder.vue'
import { mapGetters, mapActions} from "vuex";

export default {
    components: {
        TheHeader,
        Call,
        PrHeader,
        MenuLeft,
        Builder
    },
    data() {
        return {
            centerDialogVisible: false,
            drawerBuilderBack: false,
            showingData: []
        }
    },
    created() {
        // this.checkNotifications();
    },
    computed: {
        ...mapGetters({
            dailyNotifications: "calendarNotifications/dailyNotifications",
            mode: "MODE",
            oldVersion: "OLDVERSION",
            leftVersion: "LEFTVERSION",
            openVersion: "OPENVERSION",
            background: "BACKGROUND"
        })
    },
    methods: {
        ...mapActions({
            updateDailyNotifications: "calendarNotifications/dailyNotifications",
        }),
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        checkNotifications() {
            setInterval(() => {
                this.updateDailyNotifications()
                .then(() => {
                    if(this.dailyNotifications.length > 0){
                        let today = new Date();
                        let min = today.getMinutes() >= 10 ? today.getMinutes() : ('0' + today.getMinutes())
                        let time = today.getHours() + ":" + min;

                        if(time === '10:00'){
                            this.centerDialogVisible = true;
                            this.showingData = JSON.parse(JSON.stringify(this.dailyNotifications)); 
                        }else{
                            this.showingData = JSON.parse(JSON.stringify(this.dailyNotifications)).filter(item => item.alert_time === time); 
                            if(this.showingData.length > 0){
                                this.centerDialogVisible = true;
                            }else{
                                this.centerDialogVisible = false;
                            }
                        }
                    }
                });
            }, 60000); // every 60 seconds
        },
    }
};
</script>
<style>
.app-main {
  min-height: calc(100vh - 200px);
  position: relative;   
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 1rem 1.2rem;
}
.el-checkbox.is-bordered {
    height: 32px !important;
    padding: 6px 20px 9px 10px !important;
}
.el-input--medium .el-input__inner {
    height: 32px !important;
}
.app__row {
    margin: 0 !important;
    width: inherit;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    max-height: 100%;
}

.pr__outside {
    padding-top: 57px;
}
.right__sidenight .el-loading-mask {
    background-color: rgb(28 28 28 / 90%) !important;
}

.builder {
    position: fixed;
    right: 0;
    margin-right: -40px;
    bottom: 50%;
    top: 50%;
    z-index: 99;
}
.builder__item {
    display: flex;
    flex-direction: row;
    transform: rotate(90deg);
    color: #fff;
    background-color: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 0 0 0.45rem 0.45rem;
}
.builder__item:hover {
    color: #fff;
}
.builder__item i {
    transform: rotate(-90deg);
    font-size: 14px;
}
.builder__item span {
    font-size: 14px;
}
.right__sideday {
  background: #f2f2f2;
  width: 100%;
  height: 100%;
}
.right__sidenight {
  background: #151521;
  width: 100%;
  height: 100%;
  /* padding-left: 78px; */
}
/* .test__back {
    background-size: cover !important;
    background-position: center top !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    overflow-x: auto;
    width: 100%;
    height: inherit !important;
} */
.test__back {
    position: relative;
}
.test__back::before {
    background: var(--bgImage) no-repeat center center;
    background-size: cover;
    content: ' ';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

</style>
