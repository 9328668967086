import _ from 'lodash';
export default {
    
  data() {
    return {
      
    }
  },  
  methods:{    
    handleExceed(files, fileList) {
        this.$message.warning(this.$t('message.file_limit_warning', {number: 10}));
      },
      handleRemove(file, fileList){
        this.updateFile.forEach((element, index) => {
          if(element.name === file.name ){
            this.updateFile.splice(index, 1);
          }
        });
      },
      createImageList(file, fileList) {
        if(this.form.files.length > 0 && this.form.files.some(item => item.name == file.name)){
          fileList.pop();
          return this.$message({
            type: "warning",
            message: this.$t('message.A file with the same name already exists, choose another one or change the name'),
          });
        }else{
          if (file.size > 5000000) {
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_limit_warning', {number: 5}),
            });
          }
          if(this.updateFile.some(item => item.name === file.name && item.size === file.size)) {
            this.updateFile.forEach((element, index) => {
              if(element.name === file.name && element.size === file.size){
                fileList.pop();
                return this.$message({
                  type: "warning",
                  message: this.$t('message.file_already_exists'),
                });
              } 
            });
          }else{
            this.updateFile.push(file.raw);
          }  
        }
      },
      submitFiles(){
        let formData = new FormData();
  
        if (_.size(this.updateFile) > 0) {
          for (const key in this.updateFile) {
            if (this.updateFile.hasOwnProperty(key)) {
              const element = this.updateFile[key];
              formData.append(`files[${key}]`, element);
            }
          }
        }
        this.saveFiles(formData)
  
      },
  
      addCommentToForm(){
        if(this.comment.length > 0){

          this.form.comments.push({number: (this.form.comments + 1), comment: this.comment});
          this.comment = '';
        }else{
          this.$message({
                  type: "warning",
                  message: this.$t('message.write_text'),
                });
        }
      },

      destroyComment(comment){
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.form.comments = this.form.comments.filter(item => item.number != comment.number);
          this.form.comments.map((item, index) => item.number = index + 1);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
        
      } ,

      updateParticipants(users){
        users.forEach(user => {
          this.form.participants.push(user);
        });
      },
      deleteParticipant(participant_id){
        this.form.participants = this.form.participants.filter(user => user.id !== participant_id);
      },
  
      updateCheckList(list_name){
          let number = this.form.checkLists.length + 1;
          this.form.checkLists.push({number: number, name: list_name, percentage: 0, miniTasks: []});
      },
      deleteCheckList(checkList) {
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
          )
          .then(() => {
            this.form.checkLists.splice((checkList.number-1), 1);
  
            this.form.checkLists.map((item, index) => item.number = index + 1)
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
            });
          });
      },
      updateDates(dates){
        if(dates){
          this.form.begin_date = dates[0];
          this.form.end_date = dates[1];
        }else{
          this.form.begin_date = '';
          this.form.end_date = '';
        }
          
      },
      addMiniTask(checkListNumber){
          this.checkListForMiniTask = checkListNumber;
          this.addingMiniTask = true;
      },    
      updateMiniTaskList(miniTask){
        if(miniTask.number){
          this.form.checkLists[miniTask.checkListNumber-1].miniTasks[miniTask.number - 1].text = miniTask.text;
        }else{
          miniTask.is_done = false
          miniTask.number = this.form.checkLists[miniTask.checkListNumber-1].miniTasks.length + 1;
          this.form.checkLists[miniTask.checkListNumber-1].miniTasks.push(miniTask);
          this.checkListPercentage(miniTask.checkListNumber);
        }
      },
      checkListPercentage(checkListNumber){
        if(this.form.checkLists && this.form.checkLists.length > 0){
          let allCount = this.form.checkLists[checkListNumber-1].miniTasks.length;
          let selectedCount = this.form.checkLists[checkListNumber-1].miniTasks.filter(task => task.is_done === true);
          selectedCount = selectedCount.length;
          let percentage = 0;
          if(allCount > 0){
            percentage = selectedCount*100/allCount;
          }
          this.form.checkLists[checkListNumber-1].percentage =  Math.round(percentage);

        }
      },
      updateMiniTask(miniTask){
        this.selectedMiniTaskForUpdate = miniTask;
        this.updateMiniTaskDrawer = true;
      },
      deleteMiniTask(miniTask){
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
          .then(() => {
            this.form.checkLists[miniTask.checkListNumber-1].miniTasks.splice((miniTask.number - 1), 1);
            
            this.form.checkLists[miniTask.checkListNumber-1].miniTasks.map((item, index) => item.number = index + 1)
            
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
            });
          });
      },
      updateTaskLabels(taskLabels){
        this.form.labels = taskLabels;
      },
      removeLabelFromTask(label){
        this.form.labels = this.form.labels.filter(item => item.id != label.id)
      },
  
      updateComment(comment){

        this.comment = comment.comment;
        this.form.comments = this.form.comments.filter(item => item.number !== comment.number);
        this.form.comments.map((item, index) => item.number = index + 1);
      },
      listChanged() {
      },
      closeAddModel() {
          this.drawerCreateDeal = false;
          if(this.reloadList === true){
              this.updateDealsList().then(res => this.reloadList === false);
          }
      },
      drawerClosed(ref) {
        if(ref === 'drawerDatesChild'){
            this.taskDates = [];
        }
            
          if (this.$refs[ref]) {
              this.$refs[ref].closed()
          }
          if (this.reloadList === true) {
            
          }
      },
      drawerOpened(ref) {        
          if (this.$refs[ref]) {
              if (_.isFunction(this.$refs[ref].opened)) {
                  this.$refs[ref].opened()
              }
          }
      },
      closeDrawer(drawer) { 
          if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
          } 
          if (this.$refs[drawer]) {
              this.participants = false;
              this.addingTask = false;
              this.term = false;
              this.labels = false;
              this.addingMiniTask = false;
              this.updateMiniTaskDrawer = false;
              this.drawerDeleteTask = false;
          }
      },
  
      submit(close = true) {
        if(!this.form.task_board_id){
          this.form.task_board_id = this.board_id;
        }
        if(!this.form.user_id){
          this.form.user_id = this.authUser.id;
        }
        if(this.form.user){
          this.form.user_id = this.form.user.id;
        }

        this.$refs["form"].validate((valid) => {
            if (valid) {
                this.loadingButton = true;
                this.save(this.form)
                    .then((res) => {
                        this.submitFiles();
                        this.loadingButton = false;
                        this.$alert(res);
                        this.parent().listChanged();
                        if(this.form.deal_id > 0){
                          this.updateDealTasks(this.form.deal_id);
                        }
                        if (close) this.close();
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }
        });
      },
      afterLeave(){
        this.finishDateCheck = false;
        this.finish_date = '';
        this.taskDates = [],
        this.fileList = [],
        this.updateFile = [],
        this.empty()
      },
  }
}
