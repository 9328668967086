import { validation } from "@/api/hr/users";
import { parseValidationErrorToStr } from "@/utils";
import {i18n} from '@/utils/i18n';
import store from "@/store/modules/hr/user/users";

const validatePhone = (rule, phone, callback) => {
    if (phone === '') {
        callback(new Error(i18n.t('message.please_enter_phone_number')));
    } else {
        validation({ 'phone': phone, update_id: store.state.model.id ? store.state.model.id : false })
            .then(res => {
                callback()
            })
            .catch(err => {
                if (err.status === 422) {
                    callback(new Error(i18n.t('message.phone_already_exits')))
                }
            });
    }
};

// const validatePinCode = (rule, pin_code, callback) => {
//     if (pin_code === '') {
//         callback()
//     } else if (!Number.isInteger(pin_code)) {
//         callback(new Error('Пин код должна быть числом'));
//     } else {
//         validation({ 'pin_code': pin_code })
//             .then(res => {
//                 callback()
//             })
//             .catch(err => {
//                 if (err.response.status === 422) {
//                     callback(new Error(parseValidationErrorToStr(err.response.data.validation_errors.pin_code)))
//                 }
//             });
//     }
// };

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'blur' },
        { min: 3, max: 255, message: i18n.t('message.text_length_must_be', {min: 3, max: 255}), trigger: 'blur' }
    ],
    phone: [
        { required: true, validator: validatePhone, trigger: 'blur' }
    ],
    password: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.password')}), trigger: 'blur' },
        
        { min: 6, max: 255, message: i18n.t('message.text_length_must_be', {min: 6, max: 255}), trigger: 'blur' },
    ],
    status: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.status')}), trigger: 'change' },
    ],
    filials: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filials')}), trigger: 'change' },
    ],
    role_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.role')}), trigger: 'change' },
    ],
    // pin_code: [
    //     { required: true, validator: validatePinCode, trigger: 'blur' }
    // ],
};
