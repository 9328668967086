<template>
    <modal :name="name" width="80%" height="auto" @before-open="beforeOpen" :draggable="true">
        <div class="el-dialog__header cursor-move">
            <span class="el-dialog__title">{{ $t('message.new') }} {{ $t('message.client') | lowerFirst }}</span>
            <button type="button" class="el-dialog__headerbtn" @click="closeModal(name)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
        </div>
        <div class="el-dialog__body" style="background-color: white">
             <el-form ref="form" status-icon :model="form" :rules="rules" label-position="left"  size="small">
                 <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="columns.name.title" prop="name">
                        
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>

                        
                        <el-form-item :label="columns.date_of_birth.title">
                            <el-date-picker
                                class="w-100"
                                size="medium"
                                v-model="form.date_of_birth"
                                type="date"
                                :placeholder="$t('message.date_of_birth')"
                            ></el-date-picker>
                        </el-form-item>

                        <el-form-item :label="columns.phone.title" prop="phone">
                            <el-input v-model="form.phone" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.email.title">
                            <el-input v-model="form.email" autocomplete="off"></el-input>
                        </el-form-item>
                        
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="columns.location.title">
                            <el-input v-model="form.location" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.gender_id.title">
                            <select-client-gender
                                :size="'medium'"
                                :placeholder="columns.gender_id.title"
                                :id="form.gender"
                                v-model="form.gender"
                            >
                            </select-client-gender>
                        </el-form-item>

                        <el-form-item :label="columns.country_id.title">
                            <select-country
                                :size="'medium'"
                                :placeholder="columns.country_id.title"
                                :id="form.country_id"
                                v-model="form.country_id"
                            >
                            </select-country>
                        </el-form-item>

                        <el-form-item :label="columns.city_id.title">
                            <select-city
                                :size="'medium'"
                                :placeholder="columns.city_id.title"
                                :id="form.city_id"
                                :country_id="form.country_id"
                                v-model="form.city_id"
                            >
                            </select-city>
                        </el-form-item>
                        
                    </el-col>
                 </el-row>
            </el-form>
        </div>
        <div class="el-dialog__footer">
            <span class="dialog-footer">
                <el-button @click="closeModal(name)" round size="mini" :loading="waiting">{{ $t('message.cancel') }}</el-button>
                <el-button type="primary" @click="submit" round size="mini" :loading="waiting">{{ $t('message.save') }}</el-button>
            </span>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectClientType from "@/components/inventory/select-client-type";
import selectClientGender from "@/components/inventory/select-client-gender";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

export default {
  mixins: [form, drawer],
   components: {
    selectClientType,
    selectClientGender,
    selectCountry,
    selectCity,
  },
   data(){
        return {
            name: 'create_client',
            form: {},
            waiting: false,
        }
    },
    computed: {
        ...mapGetters({
            rules: 'clients/rules',
            model: 'clients/model',
            columns: 'clients/columns',
            types: 'clients/types'
        })
    },
    methods:{
        ...mapActions({
            save: 'clients/store',
            updateListInventory: 'clients/inventory',
            updateFreeClients: 'clients/freeClients'
        }),
        beforeOpen(){
            this.form = JSON.parse( JSON.stringify( this.model ));
        },
        closeModal(modalName) {
            this.$modal.hide(modalName);
        },
        changeWaiting(val = false) {
            if (val === false) {
                setTimeout(() => {
                    this.waiting = false;
                }, 500);
            } else {
                this.waiting = true;
            }
        },
        submit(){
            if (!this.waiting) {
                this.form.client_contact_people = [];
                this.form.client_checking_accounts = [];

                let formData = new FormData();
                for (const key in this.form) {
                    if (key != "avatar" && key !== 'client_contact_people' && key !== 'client_checking_accounts' && key !== 'clientType_ids' && key !== 'gender' && key !== 'clientTypes') {
                    if (this.form[key]) {
                        formData.append(key, this.form[key]);
                    } else {
                        formData.append(key, "");
                    }
                    }else if(key === 'client_contact_people' || key === 'client_checking_accounts' || key !== 'clientType_ids' || key !== 'gender' || key !== 'clientTypes'){
                    formData.append(key, JSON.stringify(this.form[key]));
                    }
                }
                if (_.size(this.updateImage) > 0) {
                    for (const key in this.updateImage) {
                    if (this.updateImage.hasOwnProperty(key)) {
                        const element = this.updateImage[key];
                        formData.append("avatar", element);
                    }
                    }
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.changeWaiting(true);
                        this.save(formData)
                            .then(async (res) => {
                                await this.updateListInventory();
                                this.$emit('crm-close', {created: true, client: res.data.result.data});
                                this.$alert(res);
                                this.changeWaiting();
                                this.closeModal(this.name);
                            })
                            .catch(err => {
                                this.changeWaiting();
                                this.$alert(err);
                            })
                    }
                });
            }
        }
    }
};
</script>
