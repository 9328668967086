import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/parties',
        method: 'get',
        params
    })
}

export function excelFileDownload(params) {
    return request({
        url: '/parties/excelFileDownload',
        method: 'get',
        responseType: 'blob',
        params
    })
}