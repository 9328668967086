import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/clientBonusSystem',
        method: 'get',
        params
    })
}

export function getFilterOptions(params) {
    return request({
        url: '/clientBonusSystem/getFilterOptions',
        method: 'get',
        params
    })
}

export function store(data) {
    return request({
        url: '/clientBonusSystem',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/clientBonusSystem/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/clientBonusSystem/${id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/clientBonusSystem/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/clientBonusSystem/${id}`,
        method: 'delete'
    })
}

export function paymentFromClientBalance(data) {
    return request({
        url: '/clientBonusSystem/paymentFromClientBalance',
        method: 'post',
        data
    })
}