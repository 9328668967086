import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, reasons) => { state.list = reasons; },
    SET_FILIAL_PRODUCT_LIST: (state, products) => { state.filial_product_list = products; },
    SET_INVENTORY: (state, reasons) => (state.inventory = reasons),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    SET_RECIEVERS: (state, recievers) => { state.recievers = recievers; },
    UPDATE_FILIAL_PRODUCT_PAGINATION: (state, pagination) => {
        state.filial_product_pagination[pagination.key] = pagination.value
    },
    UPDATE_DEAL_ROWS_ADDRESS: (state, data) => {
        state.filial_product_list.forEach(row => {
            if(data.deals.includes(row.deal_id)){
                row.address = data.address;
            }
        });
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, reason) => {
        state.model = reason
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    }
};
