import { dateTime } from "@/filters/index";

export const model = {
    deal_id: null, 
    store_in_realtion: 'Filial', 
    from_filial_id: null,
    all_client_id: null,
    client_id: null,
    column_name: '',
    date: '',
    date: dateTime(new Date()),
    products: [],
  };
  