import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/packageForProductCirculations",
        method: "get",
        params
    });
}

export function waitingIndex(params) {
    return request({
        url: "/packageForProductCirculations/waitingIndex",
        method: "get",
        params
    });
}

export function getProdOrPackByBarcode(params) {
    return request({
        url: `/packageForProductCirculations/getProdOrPackByBarcode/${params.barcode}`,
        method: "get",
        params
    });
}

export function showPackageDealProducts(id) {
    return request({
        url: `/packageForProductCirculations/showPackageDealProducts/${id}`,
        method: "get",
    });
}

export function store(data) {
    return request({
        url: "/packageForProductCirculations",
        method: "post",
        data
    });
}

export function printPackageBarcode(params) {
    return request({
        url: `/packageForProductCirculations/printPackageBarcode`,
        method: 'get',
        params
    })
}

export function getProductByBarcode(params) {
    return request({
        url: `/packageForProductCirculations/getPackageByBarcode`,
        method: 'get',
        params
    })
}

export function inventory(params) {
    return request({
        url: "/packageForProductCirculations/inventory",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/packageForProductCirculations/${id}`,
        method: "get"
    });
}

export function showProducts(id) {
    return request({
        url: `/packageForProductCirculations/showProducts/${id}`,
        method: "get"
    });
}

export function update(data) {
    return request({
        url: `/packageForProductCirculations/${data.id}`,
        method: "put",
        data
    });
}

export function destroy(params) {
    return request({
        url: `/packageForProductCirculations/${params.id}`,
        method: "delete",
        params
    });
}

export function deletePackageProduct(params) {
    return request({
        url: `/packageForProductCirculations/deletePackageProduct/${params.id}`,
        method: "delete",
        params
    });
}

export function deleteDealPackage(params) {
    return request({
        url: `/packageForProductCirculations/deleteDealPackage/${params.id}`,
        method: "delete",
        params
    });
}
