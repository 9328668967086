<template>
  <div class="modal-add-task-participants">
    <div class="add-task-participants-body">
      <div>
        <el-form ref="form" :model="form">
          <div class="app-form__group mb-4">
         
            <div class="block">
              <el-form-item :label="$t('message.name')" prop="date" class="label_mini">
                  <el-date-picker
                  v-model="date"
                  type="datetimerange"
                  range-separator="To"
                  :start-placeholder="$t('message.from')"
                  :end-placeholder="$t('message.to')"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  default-time="10:00:00">
                  </el-date-picker>

              </el-form-item>
              
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <el-button type="primary" size="medium" @click="submit(true)">{{ $t('message.save') }}</el-button>
        <el-button type="danger" plain size="medium" @click="close()">{{ $t('message.cancel') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";


export default {
  mixins: [form, drawer],
  props: {
    taskDates:{
      default: Array
    }
  },
  data() {
    return {
      checked: false,
      value1: new Date(2016, 9, 10, 18, 40),
      form: {},
      date: []
    };
  },
  computed: {
      ...mapGetters({
          rules: "tasks/rules",
          mode: "MODE"
      }),
  },
  methods: {
      ...mapActions({
      }),
      afterOpen(){
        if(this.taskDates.length > 0 && this.taskDates[0].length > 0){
          this.date = this.taskDates;
        }
      },
      submit(close){
        if(this.date.length > 0){
          this.$emit("getDates", this.date);
          if (close) this.close();
        }else{
          this.$message({
                type: "warning",
                message: "Пожалуйста, введите дату"
              });
        }
        
      },
  },


};
</script>

<style>
</style>
