<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                    <p class="large--title m-0">
                        {{ $t("message.price_list") }}
                    </p>
                    <div>
                        <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <div v-loading="loading">
                <div class="app-modal__body p-5 pb-0 mb-5">
                    <div class="timeline-items__right rounded-sm w-100 p-4">
                        <el-form ref="form" :model="current_price" label-position="top">
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item :label="$t('message.directionTariff')">
                                        <select-tariff
                                            ref="Tariff"
                                            :size="'large'"
                                            :show_all="true"
                                            :multiple="true"
                                            :placeholder="$t('message.directionTariff')"
                                            :id="current_price.direction_tariff_id"  
                                            v-model="current_price.direction_tariff_id"
                                        >
                                        </select-tariff>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('message.partner')">
                                        <select-partner
                                            :size="'large'"
                                            class="w-100"
                                            :multiple="true"
                                            :placeholder="$t('message.partner')"
                                            :id="current_price.partner_id"
                                            v-model="current_price.partner_id"
                                            :query="{clientType_id: 1, selected_id: current_price.partner_id}"
                                        >
                                        </select-partner>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item :label="$t('message.from')">
                                        <el-input :placeholder="$t('message.from')" type="number" v-model="current_price.from_amount">
                                            <template slot="append">{{ $t('message.kg') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item :label="$t('message.to')">
                                        <el-input :placeholder="$t('message.to')" type="number" v-model="current_price.to_amount">
                                            <template slot="append">{{ $t('message.kg') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item :label="$t('message.price')">
                                        <el-input :placeholder="$t('message.price')" type="number" v-model="current_price.price">
                                            <template slot="append">$</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="text-right">
                                <el-button @click="addNewPrice()" icon="el-icon-plus" type="primary">{{ $t('message.add') }}</el-button>
                            </div>
                        </el-form>
                    </div>
                </div>          
                <div class="app-modal__body p-5 pb-0 mb-5">
                    <div class="timeline-items__right rounded-sm w-100 p-4">
                        <el-row :gutter="20" class="mb-2">
                            <el-col :span="7">
                                <span class="font-bold">{{ $t('message.directionTariff') }}</span>
                            </el-col>
                            <el-col :span="7">
                                <span class="font-bold">{{ $t('message.partner') }}</span>
                            </el-col>
                            <el-col :span="2">
                                <span class="font-bold">{{ $t('message.minimum') }}</span>
                            </el-col>
                            <el-col :span="2">
                                <span class="font-bold">{{ $t('message.from') }}</span>
                            </el-col>
                            <el-col :span="2">
                                <span class="font-bold">{{ $t('message.to') }}</span>
                            </el-col>
                            <el-col :span="2">
                                <span class="font-bold">{{ $t('message.price') }}</span>
                            </el-col>
                        </el-row>
                        <el-form ref="form" :model="form">
                            <el-row v-for="(price, index) in form.priceList" :key="'price_' + index" class="mb-1" :gutter="20">
                                <el-col :span="7">
                                    {{ price.tariff ? price.tariff.name : '-' }}
                                </el-col>
                                <el-col :span="7">
                                    {{ price.partner ? price.partner.name : '-' }}
                                </el-col>
                                <el-col :span="2">
                                    <div class="grid-content bg-purple p-3 text-center">
                                        {{ price.minimum_amount }}
                                    </div>
                                </el-col>
                                <el-col :span="2">
                                    <el-input
                                        :placeholder="$t('message.from')"
                                        v-model="form.priceList[index].from_amount"
                                        type="number"
                                        size="large"
                                    ></el-input>
                                </el-col>
                                <el-col :span="2">
                                    <el-input
                                        :placeholder="$t('message.to')"
                                        v-model="form.priceList[index].to_amount"
                                        type="number"
                                        size="large"
                                    ></el-input>
                                </el-col>
                                <el-col :span="2">
                                    <el-input
                                        :placeholder="$t('message.price')"
                                        v-model="form.priceList[index].price"
                                        type="number"
                                        size="large"
                                    ></el-input>
                                </el-col>
                                <el-col :span="2" class="text-right">
                                    <el-button @click="removePrice(index, price)" icon="el-icon-delete" type="danger"></el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </div>
                <div class="app-modal__body p-5 pb-0 mb-5">
                    <div class="timeline-items__right rounded-sm w-100 p-4">
                        <h4>{{ $t('message.directions_list_with_no_minimum_weight') }}</h4>
                        <div v-html="no_minimum_text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
       
</template>
  
<script>
    import { mapGetters, mapActions } from "vuex";
    import SelectTariff from '@/components/selects/select-direction-tariff';
    import SelectPartner from "@/components/inventory/select-client";
    import drawer from "@/utils/mixins/drawer";
    import form from "@/utils/mixins/form";


export default {
    mixins: [form, drawer],
    components: {
        SelectTariff,
        SelectPartner
    },
    props: {
        product: {
            default: Object
        },
        calculation_type: {
            default: true
        },
    },
    data() {
      return {
        form: {
            priceList: [],
        },
        newPriceLimit: {
            direction_tariff_id: null,
            partner_id: null,
            from_amount: 0,
            to_amount: 0,
            price: 0,
            minimum_amount: 0
        },
        current_price: {},
        no_minimum_text: '',
      };
    },
    created() {
        this.current_price = JSON.parse(JSON.stringify(this.newPriceLimit));
    },
    computed: {
        ...mapGetters({
            priceList: "products/priceList",
            minimum_weight_list: "products/minimumWeightList",
            directionTariffs: 'directionTariffs/inventory',
            partners: 'clients/searchInventory',
        }),
    },
    watch:{
        product: {
            handler: async function(newVal, oldVal) {
                if(newVal && newVal.id){
                    this.form.product_id = JSON.parse(JSON.stringify(newVal.id));
                }else{
                    this.form.product_id = null;
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            update: "products/updatePriceList",
            getList: "products/getPriceList",
            getMinimumWeightList: "products/getMinimumWeightList",
        }),
        afterOpen() {
            this.loading = true;
            this.getList({product_id: this.product.id})
                .then(res => {
                    this.form.priceList = JSON.parse(JSON.stringify(this.priceList));
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });

            this.getMinimumWeightList({ product_id: this.product.id});
        },
        isDublicatePrice(direction_tariff_id, partner_id) {
            return this.form.priceList.find(el => (
                el.direction_tariff_id == direction_tariff_id && el.partner_id == partner_id
            ));
        },
        addNewPrice(){
            this.batchAddPrices();
            this.clearSelect('Tariff');
            this.current_price = JSON.parse(JSON.stringify(this.newPriceLimit));
        },
        batchAddPrices() {
            let tariffs = this.current_price.direction_tariff_id ? this.directionTariffs.filter(el => this.current_price.direction_tariff_id.includes(el.id)) : [];
            let partners = this.current_price.partner_id ? this.partners.filter(el => this.current_price.partner_id.includes(el.id)) : [];
        
            if(tariffs.length >= 0){
                tariffs.push({id: null, name: null});
            }
            if(partners.length >= 0){
                partners.push({id: null, name: null});
            }
            this.no_minimum_text = '';
            tariffs.forEach(tariff => {
                partners.forEach(partner => {
                    let minimum = this.findMinimumAmount(tariff.id, partner.id);
                    let minimum_amount = minimum ? minimum.minimum_amount : 0;

                    if(minimum && (tariff.id || partner.id)){
                        this.form.priceList.push({
                            id: null,
                            direction_tariff_id: tariff.id,
                            partner_id: partner.id,
                            tariff: {
                                id: tariff.id,
                                name: tariff.name ? tariff.name : '-',
                            },
                            partner: {
                                id: partner.id,
                                name: partner.name ? partner.name : '-',
                            },
                            minimum_amount: minimum_amount,
                            from_amount: minimum_amount > this.current_price.from_amount ? minimum_amount : this.current_price.from_amount,
                            to_amount: this.current_price.to_amount,
                            price: this.current_price.price,
                        });
                    } else {
                        this.no_minimum_text += '<div class="mm-no-minimum">';
                        this.no_minimum_text += '<span class="mr-5">' + this.$t('message.directionTariff') + ': ' + (tariff.name ? tariff.name : '-') + '</span>';
                        this.no_minimum_text += '<span>' + this.$t('message.partner') + ': ' + (partner.name ? partner.name : '-') + '</span>';
                        this.no_minimum_text += '</div>';
                    }
                });
            });
        },
        findMinimumAmount(tariff_id, partner_id) {
            return this.minimum_weight_list.find(el => el.direction_tariff_id == tariff_id && el.partner_id == partner_id);
        },
        warningMessage(text_key) {
            this.$notify({
                title: this.$t('message.warning'),
                type: "warning",
                offset: 130,
                message: this.$t('message.'+text_key)
            });
        },
        clearSelect(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].emptyInput)) {
                this.$refs[drawer].emptyInput()
            }
        },
        submit() {
            this.update(this.form)
                .then(res => {
                    this.$alert(res);
                    this.close();
                })
                .catch(err => {
                    this.$alert(err);
                })
        },
        removeByIndex(index){
            this.form.priceList.splice(index, 1);
        },
        removePrice(index, row){
            if(row.id){
               this.warningRemove(index);
            }else {
                this.removeByIndex(index);
            }
        },
        warningRemove(index) {
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.removeByIndex(index);
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
        },
    },
};
</script>
<style>
    .radio{
        width: 100%;
        margin-left: 12px;
    }
    .total_price{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 30px 0 0 12px;
    }
    .price{
        width: 100%;
    }
    .percent{
        width: 50%;
    }
    .percent-slot{
        font-weight: bold;
        font-size: 17px;
        color: black;
    }
    .mm-no-minimum{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        padding: 5px;
    }
</style>