export const model = {
  id: null,
  name: '',
  first_name: '',
  surname: '',
  patronymic: '',
  role_id: null,
  telegram_chat_id: null,
  phone: '',
  email: '',
  password: '',
  current_password: '',
  new_password: '',
  pin_code: null,
  verified: false,
  locked: false,
  status: 'active',
  avatar: '',
  client_id: '',
  is_employee: '',
  filials: [],
  is_super_admin: false,
  user_emails: [],
  control_user: false,
  sip_account_id: null,
  user_permission_types: [],
  user_telegram_status: 3,
};
