<template>
    <div style="height: 428px">
        <div v-if="this.dealTypesChart && this.dealTypesChart.length > 0" id="chart">
            <apexchart
                type="donut"
                :height="size"
                :options="dealTypesData.chartOptions"
                :series="dealTypesData.series"
            ></apexchart>
        </div>
        <div v-else class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t("message.no_data_yet") }}
            </h1>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";

export default {
    components: {},
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        },
        size: {
            default: '395'
        }
    },
    
    data() {
        return{
            dealTypesData: {
                series: [],
                
                chartOptions: {
                    labels: [],
                    chart: {
                        type: 'donut',
                        foreColor: '#fff'
                    },
                    plotOptions: { 
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff'
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                        label: 'overall'
                                    }
                                }
                            }
                        },
                    },
                    legend: {
                        show: true,
                        fontSize: '13px',
                        width: 170,
                        height: 390,
                        labels: {
                            colors: '#fff',
                            useSeriesColors: false
                        },
                        markers: {
                            strokeColor: '#fff',
                        },
                    },
                    responsive: [{
                        options: {
                            chart: {
                                width: 200
                            },
                        
                        }
                    }]
                },
            }
        }
    },
    watch: {
        watchAll: {
            handler: async function(val) {
                // setTimeout(() => {
                //     this.updateChartData({period: this.period, filter: this.filterForm});
                // }, 500);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            dealTypesChart: "home/dealTypesChart"
        }),
        watchAll(){
          return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            updateDealTypesChart: "home/dealTypesChart"
        }),

        updateChartData(data){
            this.updateDealTypesChart(data)
                .then(() => {
                    if(this.dealTypesChart.length > 0){
                        this.dealTypesData.series = JSON.parse(JSON.stringify(this.dealTypesChart.map(item => parseInt(item.money_amount))));
                        let self = this;
                        let counts = JSON.parse(JSON.stringify(this.dealTypesChart.map(item => item.deal_count).reduce((a, b) =>parseInt(a) + parseInt(b), 0)));
                        this.dealTypesData = {...this.dealTypesData, ...{
                           
                            chartOptions: {
                                labels: JSON.parse(JSON.stringify(this.dealTypesChart.map(item => item.name))),
                                legend: {
                                    show: true,
                                    fontSize: '13px',
                                    labels: {
                                        colors: '#fff',
                                        useSeriesColors: false
                                    },
                                    markers: {
                                        strokeColor: '#fff',
                                    },
                                },
                                plotOptions: { 
                                    pie: {
                                        donut: {
                                            labels: {
                                                name:{
                                                    formatter: function (name) {
                                                        if(self.dealTypesChart.some(item => item.name === name)){
                                                            let text = name + ': ' + self.dealTypesChart.find(item => item.name === name).deal_count;
                                                            return text;
                                                        }else{

                                                            return name
                                                        }
                                                    },
                                                },
                                                value: {
                                                    formatter: function (val) {
                                                        return  self.$t('message.money_amount') + ': ' + formatMoney(val, 2);
                                                    },
                                                },
                                                total: {
                                                    show: self.dealTypesChart.length > 1 ? true : false,
                                                    label: self.$t('message.overall') +': ' + counts,
                                                    formatter: function (w) {
                                                        let count = self.dealTypesChart.map(item => item.deal_count).reduce((a, b) => a + b, 0);
                                                        let money_amount = self.dealTypesChart.map(item => item.money_amount).reduce((a, b) =>parseInt(a) + parseInt(b), 0);
                                                        return  self.$t('message.money_amount') + ': ' + formatMoney(money_amount, 2);
                                                    },
                                                }
                                            }
                                        }
                                    }
                                }   
                            }
                        }}
                    }
                });
        },
    },
};
</script>
