<template>
  <div>
    <el-row class="mm_custom_input_padding">    
        <el-col :span="24" class="mt-2">
          <el-table 
            header-row-class-name="header__class"
            row-class-name="row__class"
            :data="dealProductsList" 
            show-summary 
            :summary-method="getSummaries" 
            border stripe 
            style="width: 100%">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>

            <el-table-column :label="$t('message.name')" v-if="columns.name.show">
              <template slot-scope="scope">
                <span v-if="scope.row.product && scope.row.product.id"> 
                  {{ scope.row.product ? scope.row.product.name : '' }} 
                  <span class="d-block font-bold">
                    {{ scope.row.product ? scope.row.product.minimum_text : '' }} 
                  </span>
                </span>
					      <el-input v-if="type != 'package'" class="mt-4" :placeholder="$t('message.tracking_code_new')" v-model="scope.row.tracking_code" size="medium"></el-input>
                <el-input
                  v-if="scope.row.product && !scope.row.product.id" 
                  size="mini"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 3}"
                  show-word-limit
                  maxlength="250"
                  v-model="scope.row.product.name"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.product_price')"  width="120">
              <template slot-scope="scope">
                <div v-if="deal_id && !scope.row.new_product">
                  <div v-if="scope.row.type == 'package'">
                    <div v-if="scope.row.incoming_quantity"><span>{{scope.row.product_price | formatMoney(1)}}</span></div>
                    <div v-else>
                      <el-input
                        type="number"
                        size="mini"
                        v-model="scope.row.product_price"
                      ></el-input>
                    </div>
                  </div>
                  <div v-else>
                    <el-input
                      type="number"
                      size="mini"
                      v-model="scope.row.product_price"
                      :disabled="scope.row.sum_coming_qty > 0"
                    ></el-input>
                  </div>
                </div>
                <div v-else>  
                  <el-input
                    type="number"
                    size="mini"
                    v-model="scope.row.product_price"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.quantity_y')" v-if="columns.quantity.show" width="110">
              <template slot-scope="scope">
                <div v-if="deal_id && !scope.row.new_product">
                  <div v-if="scope.row.type == 'package'">
                    <div v-if="scope.row.incoming_quantity">
                      <span>{{ scope.row.quantity + ' / ' + scope.row.remainder }}</span>
                    </div>
                    <div v-else>
                      <el-input
                        :min="0"
                        type="number"
                        size="mini"
                        v-model="scope.row.quantity"
                        @input="updateRowPriceByAmount(scope.row, scope.$index)"
                      >
                      </el-input>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="scope.row.sum_coming_qty && (scope.row.sum_coming_qty == scope.row.old_quantity)">
                        <span>{{ scope.row.old_quantity + ' / ' + scope.row.sum_coming_qty }}</span>
                    </div>
                    <div v-else>
                      <div v-if="!scope.row.incoming_quantity">
                        <el-input
                          :min="0"
                          type="number"
                          size="mini"
                          v-model="scope.row.quantity"
                          @input="updateRowPriceByAmount(scope.row, scope.$index)"
                        >
                        </el-input>
                      </div>
                      <div v-else class="mm_custom_input_padding_with_append">
                        <el-input
                          :min="0"
                          type="number"
                          size="mini"
                          v-model="scope.row.quantity"
                          @change="filteredQuantity(scope.row, scope.$index)"
                        >
                          <template slot="append">{{ scope.row.sum_coming_qty }}</template>
                        </el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-input
                    :min="0"
                    type="number"
                    size="mini"
                    v-model="scope.row.quantity"
                    @input="updateRowPriceByAmount(scope.row, scope.$index)"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column :label="columns.price.title" v-if="columns.price.show" width="100">
              <template slot-scope="scope">
                <div v-if="deal_id && !scope.row.new_product">
                  <div v-if="scope.row.type == 'package'">
                    <div v-if="scope.row.incoming_quantity">
                      <span>{{ scope.row.price | formatMoney(1) }}</span>
                    </div>
                    <div v-else>
                      <crm-product-slot-input
                        v-model="scope.row.price"
                        :inputValue="scope.row.price"
                        :type="'number'"
                        :size="'mini'"
                        :class="'mm_custom_input mt-1'"
                        :disabled="authUser.client_id ? true : false"
                        :measurement_type="getCalculationKey(scope.row)"
                      ></crm-product-slot-input>
                    </div>
                  </div>
                  <div v-else>
                    <crm-product-slot-input
                        v-model="scope.row.price"
                        :inputValue="scope.row.price"
                        :type="'number'"
                        :size="'mini'"
                        :class="'mm_custom_input mt-1'"
                        :disabled="(scope.row.sum_coming_qty > 0) ? true : (authUser.client_id ? true : false)"
                        :measurement_type="getCalculationKey(scope.row)"
                    ></crm-product-slot-input>
                  </div>
                </div>
                <div v-else>
                  <crm-product-slot-input
                      v-model="scope.row.price"
                      :inputValue="scope.row.price"
                      :type="'number'"
                      :size="'mini'"
                      :class="'mm_custom_input mt-1'"
                      :disabled="authUser.client_id ? true : false"
                      :measurement_type="getCalculationKey(scope.row)"
                  ></crm-product-slot-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="columns.measuring_type.title" v-if="columns.measuring_type.show" width="140">
              <template slot-scope="scope">
                <div v-if="scope.row.type == 'package'">
                  <select-measuring-type
                    :size="'mini'"
                    :placeholder="columns.measuring_type.title"
                    :id="scope.row.measuring_type_id"
                    v-model="scope.row.measuring_type_id"
                    @input="updateWeightAndSizes(scope.row)"
                    :disabled="scope.row.incoming_quantity ? true : false"
                  ></select-measuring-type>
                </div>
                <div v-else>
                  <select-measuring-type
                    :size="'mini'"
                    :placeholder="columns.measuring_type.title"
                    :id="scope.row.measuring_type_id"
                    v-model="scope.row.measuring_type_id"
                    @input="updateWeightAndSizes(scope.row)"
                    :disabled="scope.row.sum_coming_qty > 0"
                  ></select-measuring-type>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column :label="columns.weight.title" v-if="columns.weight.show" width="140">
              <template slot-scope="scope">
                <div v-if="deal_id && !scope.row.new_product">
                  <div v-if="scope.row.type == 'package'">
                    <div v-if="scope.row.incoming_quantity">
                      {{ roundUpVal(scope.row.weight) +' / ' + roundUpVal(scope.row.pc_weight) + ' ' + $t('message.kg')}} <br>
                      {{ roundUpVal(scope.row.weight * $kg_to_pound) +' / ' + roundUpVal(scope.row.pc_weight * $kg_to_pound) + ' ' + $t('message.lbs')}}
                    </div>
                    <div v-else>
                      <crm-product-slot-input
                        v-model="scope.row.weight"
                        :inputValue="scope.row.weight"
                        :type="'number'"
                        :size="'mini'"
                        :class="'mm_custom_input'"
                        :disabled="scope.row.measuring_type_id == 2"
                        :measurement_type="'kg'"
                        @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                      ></crm-product-slot-input>

                      <crm-product-slot-input
                        v-model="scope.row.weight_in_pounds"
                        :inputValue="scope.row.weight_in_pounds"
                        :type="'number'"
                        :size="'mini'"
                        :class="'mm_custom_input mt-1'"
                        :disabled="scope.row.measuring_type_id == 2"
                        :measurement_type="'lbs'"
                        @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                      ></crm-product-slot-input>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="scope.row.incoming_quantity">
                      <div v-if="scope.row.sum_coming_qty == 0">
                        <crm-product-slot-input
                          v-model="scope.row.weight"
                          :inputValue="scope.row.weight"
                          :type="'number'"
                          :size="'mini'"
                          :class="'mm_custom_input'"
                          :disabled="scope.row.measuring_type_id == 2"
                          :measurement_type="'kg'"
                          @c-change-value="updatePoundAndKG(scope.row, 'weight_in_kg')"
                        ></crm-product-slot-input>

                        <crm-product-slot-input
                          v-model="scope.row.weight_in_pounds"
                          :inputValue="scope.row.weight_in_pounds"
                          :type="'number'"
                          :size="'mini'"
                          :class="'mm_custom_input mt-1'"
                          :disabled="scope.row.measuring_type_id == 2"
                          :measurement_type="'lbs'"
                          @c-change-value="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                        ></crm-product-slot-input>
                      </div>
                      <div v-else-if="scope.row.sum_coming_qty > 0">
                        <span>{{  roundUpVal(scope.row.weight) + ' / ' +   roundUpVal(scope.row.sum_coming_weight) + ' ' + $t('message.kg')}}</span>
                        <br>
                        <span>{{ roundUpVal(parseFloat(scope.row.old_weight) * $kg_to_pound)
                          + ' / ' + roundUpVal(parseFloat(scope.row.sum_coming_weight) * $kg_to_pound)
                          + ' ' + $t('message.lbs')
                          }}
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <crm-product-slot-input
                          v-model="scope.row.weight"
                          :inputValue="scope.row.weight"
                          :type="'number'"
                          :size="'mini'"
                          :class="'mm_custom_input'"
                          :disabled="scope.row.measuring_type_id == 2"
                          :measurement_type="'kg'"
                          @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                        ></crm-product-slot-input>

                        <crm-product-slot-input
                          v-model="scope.row.weight_in_pounds"
                          :inputValue="scope.row.weight_in_pounds"
                          :type="'number'"
                          :size="'mini'"
                          :class="'mm_custom_input mt-1'"
                          :disabled="scope.row.measuring_type_id == 2"
                          :measurement_type="'lbs'"
                          @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                        ></crm-product-slot-input>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <crm-product-slot-input
                    v-model="scope.row.weight"
                    :inputValue="scope.row.weight"
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input'"
                    :disabled="scope.row.measuring_type_id == 2"
                    :measurement_type="'kg'"
                    @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                  ></crm-product-slot-input>

                  <crm-product-slot-input
                    v-model="scope.row.weight_in_pounds"
                    :inputValue="scope.row.weight_in_pounds"
                    :type="'number'"
                    :size="'mini'"
                    :class="'mm_custom_input mt-1'"
                    :disabled="scope.row.measuring_type_id == 2"
                    :measurement_type="'lbs'"
                    @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                  ></crm-product-slot-input>
                </div>
              </template>
            </el-table-column>

            <!-- SIZES ON SANTIMETRS AND INCHES -->
            <el-table-column :label="columns.width.title" v-if="columns.width.show" width="110">
              <template slot-scope="scope">
                <crm-product-slot-input
                  v-model="scope.row.width"
                  :inputValue="scope.row.width"
                  :type="'number'"
                  :size="'mini'"
                  :class="'mm_custom_input'"
                  :disabled="!scope.row.measuring_type_id || scope.row.measuring_type_id === 1"
                  :measurement_type="'sm'"
                  @input="updateWeightAndSizes(scope.row, 'width')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                  v-model="scope.row.width_on_inches"
                  :inputValue="scope.row.width_on_inches"
                  :type="'number'"
                  :size="'mini'"
                  :class="'mt-1 mm_custom_input'"
                  :disabled="!scope.row.measuring_type_id || scope.row.measuring_type_id === 1"
                  :measurement_type="'inch'"
                  @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                ></crm-product-slot-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.height.title" v-if="columns.height.show" width="110">
              <template slot-scope="scope">
                <crm-product-slot-input
                  v-model="scope.row.height"
                  :inputValue="scope.row.height"
                  :type="'number'"
                  :size="'mini'"
                  :class="'mm_custom_input'"
                  :disabled="!scope.row.measuring_type_id || scope.row.measuring_type_id === 1"
                  :measurement_type="'sm'"
                  @input="updateWeightAndSizes(scope.row, 'height')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                  v-model="scope.row.height_on_inches"
                  :inputValue="scope.row.height_on_inches"
                  :type="'number'"
                  :size="'mini'"
                  :class="'mt-1 mm_custom_input'"
                  :disabled="!scope.row.measuring_type_id || scope.row.measuring_type_id === 1"
                  :measurement_type="'inch'"
                  @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                ></crm-product-slot-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.length.title" v-if="columns.length.show" width="110">
              <template slot-scope="scope">
                <crm-product-slot-input
                  v-model="scope.row.length"
                  :inputValue="scope.row.length"
                  :type="'number'"
                  :size="'mini'"
                  :class="'mm_custom_input'"
                  :disabled="!scope.row.measuring_type_id || scope.row.measuring_type_id === 1"
                  :measurement_type="'sm'"
                  @input="updateWeightAndSizes(scope.row, 'length')"
                ></crm-product-slot-input>

                <crm-product-slot-input
                  v-model="scope.row.length_on_inches"
                  :inputValue="scope.row.length_on_inches"
                  :type="'number'"
                  :size="'mini'"
                  :class="'mt-1 mm_custom_input'"
                  :disabled="!scope.row.measuring_type_id || scope.row.measuring_type_id === 1"
                  :measurement_type="'inch'"
                  @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                ></crm-product-slot-input>
              </template>
            </el-table-column>

            <el-table-column :label="columns.comment.title" v-if="columns.comment.show">
               <template slot-scope="scope">
                  <div>
                    <el-input
                      size="mini"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 3}"
                      v-model="scope.row.comment"
                    ></el-input>
                  </div>
               </template>
            </el-table-column>
            
            <el-table-column :label="columns.total.title" v-if="columns.total.show" width="100">
              <template slot-scope="scope">
                {{ productRowTotal(scope.row) | formatMoney(2) }} / {{ productRowIncomingTotal(scope.row) | formatMoney(1) }}
              </template>
            </el-table-column>
            
            <el-table-column width="50" v-if="columns.delete.show">
              <template slot-scope="scope">
                <div v-if="deal_id && !scope.row.new_product">
                  <div v-if="scope.row.type == 'package'">
                    <i
						  	v-if="scope.row.incoming_quantity > 0"
                      @click="errorDeleteMessage"
                      class="el-icon-delete icon__delete"
                    ></i>
						  <i
						  	v-else
                      @click="removeFromDealProductsList(scope.row.id, scope.$index)"
                      class="el-icon-delete icon__delete"
                      ></i>
                  </div>
                  <div v-else>
							<i
								v-if="scope.row.incoming_quantity > 0"
								@click="errorDeleteMessage"
								class="el-icon-delete icon__delete"
							></i>
							<i
								v-else
								@click="removeFromDealProductsList(scope.row.id, scope.$index)"
								class="el-icon-delete icon__delete"
							></i>
                  </div>
                </div>
                <div v-else>
                  <i
                    @click="removeFromDealProductsList(scope.row.id, scope.$index)"
                    class="el-icon-delete icon__delete"
                  ></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12" class="mt-2">
         <select-product
            ref="selectProductComponent"
            :size="'large'"
            :placeholder="$t('message.product_id')"
            :id="product_id"
            :add_query="{partner_id: (partner_id ? partner_id : 0), direction_tariff_id: (direction_tariff_id ? direction_tariff_id : 0)}"
            :create="true"
            v-model="product_id"
            @get_selected_product="get_selected_product"
            @createNewProduct="createNewProduct"
         >
         </select-product>
        </el-col>
        <el-col v-if="remove_package" :span="12" class="text-right mt-2">
           <el-button @click="$emit('deletePackage')" type="danger" plain> {{ $t('message.delete_package') }} </el-button>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import selectProduct from "@/components/inventory/select-product";
import selectCurrency from "@/components/inventory/select-currency";
import selectMeasuringType from "@/components/inventory/select-measuring-type";
import selectKpTemplate from "@/components/inventory/select-kp-template";
import crmProductSlotInput from '@/components/inputs/crm-product-slot-input';
import { formatMoney, formatNumber } from "@/filters/index";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import _ from 'lodash';
import {i18n} from '@/utils/i18n';

export default {
  components: {
    selectProduct,
    selectCurrency,
    selectKpTemplate,
    selectMeasuringType,
    crmProductSlotInput
  },
  mixins: [show, form, drawer],
  props: {
    filial_id: {
      default: null,
    },
    remove_package: {
      default: false,
    },
    deal_id: {
      default: null,
    },
    direction_tariff_id: {
      default: null,
    },
    deal_calculate_money: {
      default: 'deal',
    },
    packageProdList: {
      default: Array
    },
    type: {
      default: 'package'
    },
    partner_id: {
      default: null,
    },
  },
  data() {
    return {
      dealProductsList: [],
      total_weight: 0,
      incoming_total_weight: 0,
      incomingTotalAmount: 0,
      product_id: null,
      updateProducts: false,
      selectedProductIndex: null
    };
  },
  watch: {
    direction_tariff_id: {
      handler: function (val) {
        if (val && this.dealProductsList && this.dealProductsList.length > 0) {
          this.updateProductPricesByTariff({
              products: this.dealProductsList.map(el => el.product_id),
              direction_tariff_id: val
            }).then(res => {
              this.dealProductsList.forEach((el, index)=> {
                let new_el = this.newPriceList.find(item => item.id === el.product_id );
                if(new_el){
                  this.$set(el, 'price', new_el.price);
                }else{
                  this.$set(el, 'price', 0);
                }
              })
            }).catch(err => {
              console.log(err, 'err');
            });
        } 
      },
      immediate: true,
      deep: true,
    },
    packageProdList:{
      handler: function (newVal) {
        if(newVal){
          this.dealProductsList = (JSON.parse(JSON.stringify(newVal)));
          this.dealProductsList.forEach(row => {
            this.updatePoundAndKG(row, 'weight_in_kg');
            this.updateWeightAndSizes(row, 'width');
            this.updateWeightAndSizes(row, 'height');
            this.updateWeightAndSizes(row, 'length');
          });
        }else{
          this.dealProductsList = [];
        }
      },
      immediate: true,
      deep: true,
    },
    dealProductsList: {
      handler: function () {
        this.$emit('input', this.dealProductsList);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      system_settings: "auth/system_settings",
      permissions: "auth/permissions",
      authUser: "auth/user",
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
      currencies: "currency/inventory",
      services: "productServices/inventory",
      mode: "MODE",
      newPriceList: "productTariffPrices/newPriceList",
      calculationTypes: 'products/calculation_types',
      minimum_weight_list: "products/minimumWeightList",
    }),
    profitAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (parseFloat(product.currency_rate) * parseFloat(product.price) * parseFloat(product.profit) * this.weightByCalculation(product)) / 100;
      });
      return parseFloat(total); 
    },
    totalAmount: function () {
      let total = 0;
      this.total_weight = 0;
      this.incoming_total_weight = 0;
      this.incomingTotalAmount = 0;
      this.dealProductsList.forEach((product) => {
        total +=parseFloat(product.currency_rate) * parseFloat(product.price) * this.weightByCalculation(product);
        this.incoming_total_weight += product.type == 'product' ? parseFloat(product.sum_coming_weight || 0) : parseFloat(product.pc_weight || 0);

        this.total_weight += parseFloat(product.weight);
        this.incomingTotalAmount += parseFloat(product.currency_rate * product.price * (product.type == 'product' ? (product.sum_coming_weight || 0) : (product.pc_weight || 0)));
      });
      return parseFloat(total);
    },
    
  },
  methods: {
    ...mapActions({
      save: "dealProducts/store",
      update: "dealProducts/update",
      updateColumn: "dealProducts/updateColumn",
      show: "dealProducts/show",
      showProduct: "products/getWithTariffPrice",
      empty: "dealProducts/empty",
      emptyProduct: "products/empty",
      destroy: "dealProducts/destroy",
      updateCurrencyInventory: "currency/inventory",
      updateProductPricesByTariff: "productTariffPrices/updateProductPricesByTariff",
      getProductPriceByQuantity: "products/getProductPriceByQuantity",
    }),
    getCalculationKey(prod) {
      let calc_key = 'kg';
      if(prod.product && prod.product.calculation_type){
        let c_type = this.calculationTypes.find(el => el.id == prod.product.calculation_type);
        calc_key = c_type && c_type.key ? c_type.key : 'kg';
      }
      return calc_key;
    },
    updateRowPriceByAmount(product, index){
      if(product.quantity && product.weight){
        this.getProductPriceByQuantity({
            product_id: product.product_id,
            partner_id: this.partner_id || 0,
            direction_tariff_id: this.direction_tariff_id || 0,
            calculation_type: product.calculation_type,
            weight: product.weight,
            quantity: product.quantity,
            price: product.price,
          }) 
          .then(res => {
            let response = res.data.result
            if(response.data && response.data.price >= 0 && this.dealProductsList[index]){
              let new_price = parseFloat((response.data.price ? response.data.price : response.data.default_price) || 0);
              let new_minimum_amount = parseFloat(response.data.minimum_amount ? response.data.minimum_amount : product.minimum_amount);
              if(this.dealProductsList[index].price != new_price){
                this.$alert(res)
              }
              this.dealProductsList[index].price = new_price
              this.dealProductsList[index].minimum_amount = new_minimum_amount
            }
          })
          .catch(err => {
            console.log(err, 'price');
          });
      }
    },
    roundUpVal(num, dec = 2){
      return _.round(parseFloat(num || 0), dec)
    },
    noMoreThan(index) {
        if(this.dealProductsList[index].profit >= 100){
          this.dealProductsList[index].profit = 99;
        }
        if(this.dealProductsList[index].profit < 0){
          this.dealProductsList[index].profit = 0;
        }
    },
    checkCurrency(row){
      let currency = this.currencies.find(curr => curr.id === row.currency_id);  
      if(currency && currency.active){
        return true;
      }
      return false;
    },
    check: function (column, event) {
      this.updateColumn({key: column, value: event,})
    },

    changeCurrency(row){
      if(row.currency_id){
        let currency = this.currencies.find(el => el.id === row.currency_id);
        if(currency){
          this.$set(row, 'currency_rate', currency.rate);
        }
      }
    },

    updateWeightAndSizes(row, type){
      if(type){
        if(type === 'width'){
            this.$set(row, 'width_on_inches',  _.round(parseFloat(row.width) * this.$sm_to_inch, 2));
        }
        if(type === 'width_on_inches'){
            this.$set(row, 'width',  _.round(parseFloat(row.width_on_inches) / this.$sm_to_inch, 2));
        }

        if(type === 'height'){
            this.$set(row, 'height_on_inches',  _.round(parseFloat(row.height) * this.$sm_to_inch, 2));
        }
        if(type === 'height_on_inches'){
            this.$set(row, 'height',  _.round(parseFloat(row.height_on_inches) / this.$sm_to_inch, 2));
        }

        if(type === 'length'){
            this.$set(row, 'length_on_inches',  _.round(parseFloat(row.length) * this.$sm_to_inch, 2));
        }
        if(type === 'length_on_inches'){
            this.$set(row, 'length',  _.round(parseFloat(row.length_on_inches) / this.$sm_to_inch, 2));
        }
      }
      if(row.measuring_type_id && row.measuring_type_id === 2){
        let size_weight = (parseFloat(row.width) * parseFloat(row.length) * parseFloat(row.height)) / 6000;
        this.$set(row, 'weight', _.round(parseFloat(size_weight), 3));
      }else{
        let old_weight = row.old_weight ? JSON.parse(JSON.stringify(row.old_weight)) : row.weight;
        this.$set(row, 'weight',  _.round(parseFloat(old_weight), 3));
      }
    },
    
    updatePoundAndKG(row, type){
        if(type === 'weight_in_kg'){
            this.$set(row, 'weight_in_pounds',  _.round(parseFloat(row.weight) * this.$kg_to_pound, 3));
        }else{
            this.$set(row, 'weight',  _.round(parseFloat(row.weight_in_pounds) / this.$kg_to_pound, 3));
        }
    },
  
    getCurrencyCodeById(row){
      let currency = this.currencies.find(el => el.id === row.currency_id);
      if(currency){
        return currency.symbol
      }
      return '';
    },
  
    get_selected_product(selected_product_id){
      if(selected_product_id){
        this.showSelectedProduct(selected_product_id);
      }
    },
    dealOffersCreate() {
      if(this.dealProductsList && this.dealProductsList.length > 0){
        this.$emit('dealOffersCreate', {drawer: true, productList: this.dealProductsList})
      }else{
        this.$message({
            type: "warning",
            message: this.$t('message.product_list_is_empty'),
        });
      }
    },
    weightByCalculation(product){
      let qty_type = product.calculation_type ? product.calculation_type : '';
      let qty = (qty_type && qty_type == 2 ? product.quantity : product.weight);
      qty = product.minimum_amount > qty ? product.minimum_amount : qty;
      return parseFloat(qty || 0);
    },
    productRowTotal(product) {
      let rowTotal = product.currency_rate * product.price * this.weightByCalculation(product);
      return rowTotal;
    },

    productRowIncomingTotal(product) {
      let rowTotal = product.currency_rate * product.price * (product.type == 'product' ? (product.sum_coming_weight || 0) : (product.pc_weight || 0));
      return rowTotal;
    },

    showSelectedProduct(product_id) {
      this.showProduct({product_id: product_id, direction_tariff_id: this.direction_tariff_id, partner_id: this.partner_id})
        .then(() => {
          this.addToDealProductsList(product_id);
        }).then(() => {
          this.emptyProduct();
        });
    },
    createNewProduct(name){
      this.addToDealProductsList(null, name);
    },
    addToDealProductsList(product_id = null, name = ''){
      let item = {};
      let rate = 1
      let product = { id: null, name: name };

      let currency_id = null;
      let currency = this.currencies.find(el => el.active === true);
      if(currency){
        currency_id = currency.id;
        rate = currency.rate;
      }

      if(product_id){
        currency_id = this.getProduct.currency.id;
        if(currency_id){
          currency = this.currencies.find(el => el.id === currency_id)
          if(currency){
            rate = currency.rate;
          }
        }
        product = {
          id: this.getProduct.id,
          name: this.getProduct.name,
          calculation_type: this.getProduct.calculation_type,
          minimum_text: this.getProduct.minimum_text,
        }
      }
      item.id                 = null;
      item.type               = 'product';
      item.deal_id            = this.deal_id;
      item.product_id         = product_id;
      item.price              = this.getProduct.price;
      item.product_price      = 0;
      item.currency_id        = currency_id;
      item.currency_rate      = rate;
      item.product            = product;
      item.quantity           = 1;
      item.weight             = this.getProduct.weight;
      item.old_weight         = this.getProduct.weight;
      item.weight_in_pounds   = _.round(parseFloat(this.getProduct.weight) * parseFloat(this.$kg_to_pound), 3);
      item.profit             = 0;
      item.comment            = '';
      item.width              = 0;
      item.width_on_inches    = 0;
      item.height             = 0;
      item.height_on_inches   = 0;
      item.length             = 0;
      item.length_on_inches   = 0;
      item.measuring_type_id  = null;
      item.minimum_amount     = this.getProduct.minimum;
      item.calculation_type   = this.getProduct.calculation_type;
      item.tracking_code      = '';

      this.dealProductsList.push(item);
      if(this.$refs['selectProductComponent'] && this.$refs['selectProductComponent'].filterInput){
        this.$refs['selectProductComponent'].filterInput = '';
      }
    },

    removeFromDealProductsList(id, index) {
      if(id) {
        this.$confirm(
            this.$t('message.Are you sure you want to uninstall the product?'),
            this.$t('message.warning'),
            {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning",
            }
        )
        .then(() => {
            this.destroy(id)
              .then((res) =>{
                if(res.data.result.success){
                  this.dealProductsList.splice(index, 1);
                  this.$alert(res);
                }else{
                  this.$warning_message(res);
                }
                
              })
              .catch((err) => {
                  this.$alert(err);
              })
        })
        .catch(() => {
            this.$message({
                type: "warning",
                message: this.$t('message.deleting_product_canceled'),
            });
        });
      }else{
          this.dealProductsList.splice(index, 1);
      }
    },
    
    // table footer all total summs  

    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = this.$t('message.total');
          return;
        }
        if (column.label === this.columns.weight.title) {
          sums[index] = formatNumber(this.total_weight, 1) + ' / ' + formatNumber(this.incoming_total_weight, 1) + ' kg'; 
          return;
        }
        if (column.label === this.columns.weight_in_pounds.title) {
          sums[index] = formatNumber((parseFloat(this.total_weight) * this.$kg_to_pound), 1) + ' lbs';
          return;
        }
        if (column.label === (this.columns.profit.title + ' %')) {
          sums[index] = formatMoney(this.profitAmount, 2);
          return;
        }
        if (column.label === this.columns.total.title) {
          sums[index] = formatMoney(this.totalAmount, 2) + ' / ' + formatMoney(this.incomingTotalAmount, 2);
          return;
        }
      });

      return sums;
    },
    errorDeleteMessage(){
      this.$notify({
        title: 'Warning',
        message: i18n.t("message.Does not let delete"),
        type: 'warning'
      });
	 },
	 filteredQuantity(row, index){
      let min_may_be_quantity = row.sum_coming_qty;
      let quantity = parseInt(row.quantity);
      
      if (min_may_be_quantity >= quantity) { 
        this.$set(row, 'quantity', min_may_be_quantity);
      }
      else this.$set(row, 'quantity', row.quantity);

      this.updateRowPriceByAmount(row, index);
	 }

  },
};
</script>
<style>
  .header__class {
    font-size: 12px;
  }
  .row__class {
    padding: 0 !important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .mm_custom_input {
    border-radius: 0px !important;
  }

  .mm_custom_input_padding .el-input-group__append {
    padding: 0px !important;
    width: 35px !important;
    text-align: center;
  }
  
</style>
