import { login, getTokenForCabinetUser, logout, refresh, getAuth, checkStatus } from "@/api/auth";
import { setToken, removeToken } from "@/utils/auth";

export const actions = {

    login({ commit }, credentials) {
        const { phone, password } = credentials;
        return new Promise((resolve, reject) => {
            login({ phone: phone.trim(), password: password }).then(response => {          
                if (response.data.result.data && response.data.result.data.token) {          
                    commit('SET_TOKEN', response.data.result.data.token)                                                                
                    setToken(response.data.result.data.token)
                    resolve()
                }
            }).catch(error => {                
                reject(error)
            })
        })
    },

    getTokenForCabinetUser({ commit }, credentials) {
        const { user_id, phone, password } = credentials;
        return new Promise((resolve, reject) => {
            getTokenForCabinetUser({ user_id: user_id, phone: phone.trim(), password: password })
            .then(response => {      
                if (response.data.result.data && response.data.result.data.token) {
                    resolve(response)
                }
            }).catch(error => {                
                reject(error)
            })
        })
    },

    checkStatus({ commit }) {
        return new Promise((resolve, reject) => {
            checkStatus().then(res => {
                resolve(res.data.result.data.status);
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAuth({ commit, state }, payload) {
        return new Promise((resolve, reject) => {   
            getAuth(state.token).then(res => {    
                const { data } = res.data.result                
                if (!data) {
                    reject('Проверка не удалась, пожалуйста, войдите снова.')
                }
                const { role, name, phone, goodone_admin_slug } = data
                if (!role) {
                    reject('Роль не может быть пустой')
                }
                commit('SET_SLUG', goodone_admin_slug)
                commit('SET_ROLE', role)
                commit('SET_NAME', name)
                commit('SET_PHONE', phone)                
                commit('SET_USER',res.data.result.data)
                commit('SET_SYSTEM_SETTINGS', res.data.result.data.system_settings)
                console.log('success in permissions js')
                resolve(data)
            }).catch(error => {             
                console.log('error in permissions js')
                commit('SET_TOKEN', '');
                removeToken();
                reject(error);
            })
        })
    },
    refresh({ commit }) {
        refresh().then(res => {
            const { data } = res.data.result;
            if (data.token) {
                commit('SET_TOKEN', data.token)
                setToken(data.token)
            }
        }).catch(err => {
            removeToken()
        })

    },
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                removeToken()
                commit('SET_TOKEN', '')
                commit('SET_ROLE', '')
                window.location.reload();
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetToken({ commit }) {
        return new Promise((resolve) => {
            removeToken()
            commit('SET_TOKEN', '')
            resolve()
        })
    },
}
