export const model = {
    id: null,
    name: '', 
    average_days: null, 
    from_filial_id: null, 
    to_filial_id: null, 
    for_systeam: true,
    for_site: true,
    price_delivery_to_door: 0,
    shipping_type_id: null,
    comment: '',
  };
  