import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    user_id : {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user_id'
    },
    name_title_id : {
        show: true,
        title: i18n.t('message.name_title'),
        sortable: true,
        column: 'name_title_id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    surname : {
        show: true,
        title: i18n.t('message.surname'),
        sortable: true,
        column: 'surname'
    },
    patronymic : {
        show: false,
        title: i18n.t('message.patronymic'),
        sortable: true,
        column: 'patronymic'
    },
    date_of_birth : {
        show: false,
        title: i18n.t('message.date_of_birth'),
        sortable: true,
        column: 'date_of_birth'
    },
    client_id : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client_id'
    },
    position : {
        show: true,
        title: i18n.t('message.position'),
        sortable: true,
        column: 'position'
    },
    phone_number : {
        show: true,
        title: i18n.t('message.phone_number'),
        sortable: true,
        column: 'phone_number'
    },
    email : {
        show: true,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    website : {
        show: true,
        title: i18n.t('message.website'),
        sortable: true,
        column: 'website'
    },
    contact_type_id : {
        show: true,
        title: i18n.t('message.contact_type'),
        sortable: true,
        column: 'contact_type_id'
    },
    source_id : {
        show: true,
        title: i18n.t('message.source'),
        sortable: true,
        column: 'source_id'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
