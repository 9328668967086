<template>
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{$t('message.client')}}</p>
          <div>
            <el-button type="warning" size="medium" @click="close()" plain>{{$t('message.close')}}</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" >
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-tabs v-model="activeName" @tab-click="handleClick" >
              <el-tab-pane :label="$t('message.dashboard')" name="dashboard">
              <div class="card-table-header table-crm-smart" v-loading="loadTab">
                  <div class="dashboard__box">
                      <div class="dashboard__flex">
                        <div class="dashboard__photo">
                            <img :src="model.avatar && model.avatar[0] ? (model.image_url + '/' + model.avatar[0].path) : '/img/default-user-icon.png'" alt="">
                        </div>
                        <div class="dashboard__info">
                            <!-- <h5>"REAL EVRO TRADE" МЧЖ</h5> -->
                            <h5>{{ model.name }}</h5>
                            <div>
                                <p><span class="dashboard__margin el-icon-phone"></span><span>{{ model.phone }}</span></p>
                                <p><span class="dashboard__margin el-icon-message"></span><span>{{ model.email ? model.email : $t('message.no_email') }}</span></p>
                                <p><span class="dashboard__margin el-icon-location"></span><span>{{ model.city ? model.city.name + ',' : '' }} {{ model.country ? model.country.name : ''}}</span></p>
                            </div>
                        </div>
                      </div>
                      <div class="filter_home">
                            <el-radio-group
                                v-model="clientDashboardPeriod"
                                size="medium"
                                class="zindex-100"
                            >
                                <el-radio-button label="weekly">
                                    {{
                                        $t("message.weekly")
                                    }}</el-radio-button
                                >
                                <el-radio-button label="monthly"
                                    >{{ $t("message.monthly") }}
                                </el-radio-button>
                                <el-radio-button label="yearly"
                                    >{{ $t("message.yearly") }}
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                  </div>
                  <div>
                      <div class="board__flex"> 
                        <div class="board__left">
                            <div class="dashboard__item">
                                <h5 class="board__title"> {{ $t('message.all_deals') }} </h5>
                                <div class="dashboard__space">
                                    <div class="dashboard__left">
                                        <Chart :percent="clientDashboard.deal ? (clientDashboard.deal.fact > 0 ? clientDashboard.deal.successful_deals/clientDashboard.deal.fact : 0) : 0"/>
                                    </div>
                                    <div class="dashboard__right">
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.succesfull') }}:</span>
                                            <span> {{ clientDashboard.deal ? clientDashboard.deal.successful_deals : 0 }} </span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.fact') }}:</span>
                                            <span>{{ clientDashboard.deal ? clientDashboard.deal.fact : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.difference') }}:</span>
                                            <span>{{ clientDashboard.deal ? (clientDashboard.deal.fact -  clientDashboard.deal.successful_deals) : 0}}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.active_d') }}:</span>
                                            <span>{{ clientDashboard.deal ? clientDashboard.deal.active : 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="board__right">
                            <div class="dashboard__item">
                                <div>
                                    <h5 class="board__title1">{{ $t('message.indicators') }}</h5>
                                </div>
                                <div>
                                  <bar-chart :products="clientDashboard.products ? clientDashboard.products : []"/>
                                </div>

                            </div>
                        </div>
                  </div>
                  <div class="board__flex1"> 
                        <div class="board__left">
                            <div class="dashboard__item">
                                <h5 class="board__title">{{ $t('message.all_tasks') }}</h5>
                                <div class="dashboard__space">
                                    <div class="dashboard__left">
                                        <Chart :percent="clientDashboard.task ? (clientDashboard.task.fact > 0 ? clientDashboard.task.completed_tasks/clientDashboard.task.fact : 0) : 0"/>
                                    </div>
                                    <div class="dashboard__right">
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.completed_tasks') }}:</span>
                                            <span>{{ clientDashboard.task ? clientDashboard.task.completed_tasks : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.fact') }}:</span>
                                            <span>{{ clientDashboard.task ? clientDashboard.task.fact : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.difference') }}:</span>
                                            <span>{{ clientDashboard.task ? (clientDashboard.task.fact - clientDashboard.task.completed_tasks) : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.active_d') }}:</span>
                                            <span>{{ clientDashboard.task ? clientDashboard.task.active : 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                  </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('message.client')" name="client">
              <el-row :gutter="30" v-loading="loadingData">
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.name') }}:</div>
                    <div><b>{{ model.name }}</b></div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.phone') }}:</div>
                    <div><b>{{ model.phone }}</b></div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.type') }}:</div>
                    <div><b>{{ model.type ?  model.type.name : ''}}</b></div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.inn') }}:</div>
                    <div><b>{{ model.inn }}</b></div>
                  </div>
                </el-col>
                <el-col :span="8" v-if="model.type && model.type.id === 1">
                  <div class="user-info">
                    <div>{{ $t('message.gender') }}:</div>
                    <div><b>{{ model.gender ? model.gender.name : '' }}</b></div>
                  </div>
                </el-col>
                
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.location') }}:</div>
                    <div><b>{{ model.location }}</b></div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.country') }}:</div>
                    <div><b>{{ model.country ? model.country.name : '' }}</b></div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.city') }}:</div>
                    <div><b>{{ model.city ? model.city.name : '' }}</b></div>
                  </div>
                </el-col>
                
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.email') }}:</div>
                    <div><b>{{ model.email }}</b></div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="user-info">
                    <div>{{ $t('message.client_type') }}:</div>
                    <div><b class="ml-2" v-for="type in model.clientTypes" :key="'type' + type.id">{{ type.name }} ,</b></div>
                  </div>
                </el-col>
                <div v-if="model.type && model.type.id === 2">
                  <el-col :span="8">
                    <div class="user-info">
                      <div>{{ $t('message.mfo') }}:</div>
                      <div><b>{{ model.mfo}}</b></div>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="user-info">
                      <div>{{ $t('message.okonx') }}:</div>
                      <div><b>{{ model.okonx}}</b></div>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="user-info">
                      <div>{{ $t('message.oked') }}:</div>
                      <div><b>{{ model.oked}}</b></div>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="user-info">
                      <div>{{ $t('message.rkp_nds') }}:</div>
                      <div><b>{{ model.rkp_nds}}</b></div>
                    </div>
                  </el-col>
                </div>
              </el-row>
            </el-tab-pane>

            <el-tab-pane :label="$t('message.deals')" name="deals">
              <div class="card-table-header table-crm-smart" v-loading="loadTab">
                <table class="table-my-code ali t-fixed">
                  <thead>
                    <tr>
                      <th>{{ $t('message.n') }}</th>
                      <th>{{ $t('message.user') }}</th>
                      <th>{{ $t('message.from_filial') }}</th>
                      <th>{{ $t('message.to_filial') }}</th>
                      <th>{{ $t('message.Total amount') }}</th>
                      <th>{{ $t('message.begin_date') }}</th>
                      <th>{{ $t('message.end_date') }}</th>
                      <th>{{ $t('message.link') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="deal in deals" :key="deal.id">
                      <th> {{ deal.id }} </th>
                      <th> {{ deal.user ? deal.user.name : '' }} </th>
                      <th> {{ deal.from_filial ? deal.from_filial.name : '' }} </th>
                      <th> {{ deal.to_filial ? deal.to_filial.name : '' }} </th>
                      <th> {{ deal.money_amount }} </th>
                      <th> {{ deal.begin_date }} </th>
                      <th> {{ deal.end_date }} </th>
                      <th>
                        <el-button
                          @click="showSelectedDeal(deal)"
                          type="success"
                          class="w-100"
                          size="mini"
                          round
                          >{{ $t('message.view') }}</el-button
                        >
                      </th>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </el-tab-pane>

            <el-tab-pane :label="$t('message.tasks')" name="tasks">
              <div v-loading="loadTab">
                <div>
                    <select-deal
                        :size="'medium'"
                        :placeholder="$t('message.deal')"
                        :id="deal_id"
                        v-model="deal_id"
                        :client_id="model.id"
                    >
                    </select-deal>
                </div>
                <el-button
                  @click="showSelectedTask(task)"
                  type="success"
                  class="my-links"
                  v-for="task in dealTasks" :key="task.id"
                >
                  {{$t('message.task')}} № {{ task.id }}
                  <i class="el-icon-arrow-left"></i>
                </el-button>
                <h2 v-if="dealTasks.length === 0">{{$t('message.no_task')}}</h2>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <div class="app-modal app-modal__full modal-color-bg">

      <el-drawer
          :with-header="false"
          :visible.sync="newModal"
          :append-to-body="true"
          size="80%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
      >
          <crm-show-task 
              :selectedItem="selectedTask"
              ref="drawerUpdateChild"
              drawer="drawerUpdate"
          ></crm-show-task>
      </el-drawer>
      <el-dialog :append-to-body="true" :title="$t('message.deal')" :visible.sync="dealDrawer" width="80%" @closed="empty()">
        <crm-show-deal :deal_id="selectedDeal" :open="dealDrawer"></crm-show-deal>
      </el-dialog>


    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectDeal from "@/components/inventory/select-deal";
import CrmShowTask from "./showTask/show";
import CrmShowDeal from "@/views/archivedDeals/components/crm-show";
import Chart from '../components/chart/circle.vue'
import BarChart from '../components/chart/bar.vue'



export default {
  mixins: [form, drawer, show],
  components: {
    selectDeal,
    CrmShowTask,
    CrmShowDeal,
    Chart,
    BarChart
  },
  data() {
    return {
      activeName: "dashboard",
      region: '',
      newModal: false,
      dealDrawer: false,
      deal_id: null,
      selectedTask: {},
      selectedDeal: null,
      loadTab: false,
      clientDashboardPeriod: 'yearly'
    };
  },
  watch: {
      clientDashboardPeriod: {
          handler: async function(newVal) {
              if(this.model.id){
                  this.loadTab = true
                  this.updateClientDashboard({client_id: this.model.id, period: newVal})
                    .then(res => {
                      this.loadTab = false
                    })
              }
          },
          deep: true,
          immediate: true
      },
      activeName: {
          handler: async function(newVal) {
              if(newVal === 'deals' && this.model.id){
                  this.loadTab = true
                  this.udpateClientDeals(this.model.id)
                    .then(res => {
                      this.loadTab = false
                    })
              }
              if(newVal === 'dashboard' && this.model.id){
                  this.loadTab = true
                  this.updateClientDashboard({client_id: this.model.id})
                    .then(res => {
                      this.loadTab = false
                    })
              }
          },
          deep: true,
          immediate: true
      },
      deal_id: {
          handler: async function(newVal) {
              if(newVal){
                this.loadTab = true
                this.udpateDealTasks(newVal)
                  .then(res => {
                    this.loadTab = false
                  })
              }else{
                this.emptyDealTask();
                this.deal_id = null;
              }
          },
          deep: true,
          immediate: true
      },
  },
  computed: {
    ...mapGetters({
      model: "clients/model",
      columns: "clients/columns",
      deals: "clients/deals",
      dealTasks: "deals/dealTasks",
      clientDashboard: "clients/clientDashboard",
    }),
  },
  methods: {
    handleClick(tab, event) {
    //   console.log(tab, event);
    },
    ...mapActions({
      show: "clients/show",
      udpateClientDeals: "clients/clientDeals",
      udpateDealTasks: "deals/dealTasks",
      emptyDealTask: "deals/emptyDealTask",
      updateClientDashboard: "clients/clientDashboard",
    }),
    afterOpen() {      
      if (!this.loadingData) {  
          this.loadingData=true        
          this.show(this.selectedItem.id).then(res => {   
              this.loadingData = false   
              if(this.model.id){
                  this.loadTab = true
                  this.updateClientDashboard({client_id: this.model.id})
                    .then(res => {
                      this.loadTab = false
                    })
              }           
          }).catch(err => {
              this.loadingData = false
          });
      }
      
    },
    showSelectedTask(task){
      this.selectedTask = task;
      this.newModal = true;
    },
    showSelectedDeal(deal){
      this.selectedDeal = deal.id;
      this.dealDrawer = true;
    },

    closeDrawer(drawer) {       
        if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
        }
    },
    drawerClosed(ref) {
        if (this.$refs[ref]) {
            this.$refs[ref].closed()
        }
        if (this.reloadList === true) {
            this.fetchData();
            this.afterFetchData();
        }
        if (_.isFunction(this.empty)) {
            this.empty()
        }
    },
    drawerOpened(ref) {        
        if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
                this.$refs[ref].opened()
            }
        }
    },
    afterLeave() {
        this.deal_id = null;
        this.activeName = "dashboard";
        this.emptyDealTask();
        this.selectedDeal = null;
    },
    empty(){
      
    }
    
  },
};
</script>
<style scoped>
.dashboard__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard__scroll {
    overflow-y: auto;
    height: 100%;
}
.dashboard__flex {
    display: flex;
    flex-direction: row;
}
.dashboard__photo img  {
    width: 70px;
    height: 70px;
    object-fit: cover;
    /* border-radius: 50%;  */
}
.dashboard__info h5 {
    margin: 0;
    font-size: 16px;
}
.dashboard__info {
    margin-left: 20px;
}
.dashboard__info p {
    margin: 4px 0;
    font-size: 12px;
}
.dashboard__margin {
    margin-right: 5px;
}
.dashboard__space {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard__item {
    background: #409EFF;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    height: 280px;
    position: relative;
}
.board__right {
    width: 60%;
    padding: 0 30px;
}
.board__left {
    width: 40%;
    padding: 0 30px;
}
.board__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}
.board__flex1 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.dashboard__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 0px;
}
.dashboard__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}
.dashboard__list span{
    margin-top: 5px;
    font-size: 14px;
}
.board__title {
    margin: 0;
    font-size: 16px;
}
.board__title1 {
    margin: 0;
    font-size: 16px;
    position: absolute;
}
</style>
