import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    calculation_type: [
        { required: true, message: i18n.t('message.please_choose_input', {input: i18n.t('message.calculation_type')}), trigger: 'change' },
    ],
    measurement_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.measurement')}), trigger: 'change' },
    ],
    currency_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency')}), trigger: 'change' },
    ],
    
};
