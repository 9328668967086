import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    type: [
      { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.type')}), trigger: 'change' },
    ],
    multiple: [
		  { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.select_type')}), trigger: 'change' },
    ],
};
