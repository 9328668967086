import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    phone: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}), trigger: 'change' },
    ],
    country_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.country_id')}), trigger: 'change' },
    ],
};

export const client_product_rules = {
    filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filial')}), trigger: 'change' },
    ],
    client_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.client')}), trigger: 'change' },
    ],
    date: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.date')}), trigger: 'change' },
    ],
};
