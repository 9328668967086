import { reasonsDeals, activityDiagram, saleByPeriodDiagram, tasksByCompleted, dealPaymentByCountry, countryDeals, 
    dealGoals, dealPayments, dealPaymentTerms, productSellStatistics, categoryStatisticBySellingProduct, 
    shipmentTerms, topSuccessfulDeals, dealTypesChart, getStageDeals, clientStatistics, taskTimeLine} from "@/api/home/home";

export const actions = {
    reasonsDeals({ commit }, params) {
        return new Promise((resolve, reject) => {
            reasonsDeals(params)
                .then(res => {
                    commit("SET_REASONS_DEALS", res.data.result.data.reasons);
                    resolve(res.data.result.data.reasons);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    taskTimeLine({ commit }, params) {
        return new Promise((resolve, reject) => {
            taskTimeLine(params)
                .then(res => {
                    commit("SET_TIME_LINE_TASKS", res.data.result);
                    resolve(res.data.result.data.reasons);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    activityDiagram({ commit }, params) {
        return new Promise((resolve, reject) => {
            activityDiagram(params)
                .then(res => {
                    commit("SET_ACTIVITY_DIAGRAM", res.data.result.data.activities);
                    resolve(res.data.result.data.activities);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    saleByPeriodDiagram({ commit }, params) {
        return new Promise((resolve, reject) => {
            saleByPeriodDiagram(params)
                .then(res => {
                    commit("SET_SALE_PERIOD_DIAGRAM", res.data.result.data.saleByPeriod);
                    resolve(res.data.result.data.saleByPeriod);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    countryDeals({ commit }, params) {
        return new Promise((resolve, reject) => {
            countryDeals(params)
                .then(res => {
                    commit("SET_COUNTRY_DEALS", res.data.result.data);
                    resolve(res.data.result.data.countryDeals);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    dealGoals({ commit }, params) {
        return new Promise((resolve, reject) => {
            dealGoals(params)
                .then(res => {
                    commit("SET_DEAL_GOALS", res.data.result.data);
                    resolve(res.data.result.data.dealGoals);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    dealPayments({ commit }, params) {
        return new Promise((resolve, reject) => {
            dealPayments(params)
                .then(res => {
                    commit("SET_DEAL_PAYMENTS", res.data.result.data);
                    resolve(res.data.result.data.dealPayments);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    tasksByCompleted({ commit }, params) {
        return new Promise((resolve, reject) => {
            tasksByCompleted(params)
                .then(res => {
                    commit("SET_TASK_COUNTS", res.data.result.data.tasks);
                    resolve(res.data.result.data.tasks);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    dealPaymentByCountry({ commit }, params) {
        return new Promise((resolve, reject) => {
            dealPaymentByCountry(params)
                .then(res => {
                    commit("SET_COUNTRY_PAYMENT", res.data.result.data.payments);
                    resolve(res.data.result.data.payments);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    dealPaymentSelectedPoint({ commit }, params) {
        return new Promise((resolve, reject) => {
            dealPayments(params)
                .then(res => {
                    commit("SET_DEAL_PAYMENT_SELECTED_POINT", res.data.result.data);
                    resolve(res.data.result.data.dealPayments);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    dealPaymentTerms({ commit }, params) {
        return new Promise((resolve, reject) => {
            dealPaymentTerms(params)
                .then(res => {
                    commit("SET_DEAL_PAYMENT_TERMS", res.data.result.data);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    productSellStatistics({ commit }, params) {
        return new Promise((resolve, reject) => {
            productSellStatistics(params)
                .then(res => {
                    commit("PRODUCT_SELL_STATISTICS", res.data.result.data);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    categoryStatisticBySellingProduct({ commit }, params) {
        return new Promise((resolve, reject) => {
            categoryStatisticBySellingProduct(params)
                .then(res => {
                    commit("CATEGORY_STATISTIC_BY_SELLING_PRODUCT", res.data.result.data);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    shipmentTerms({ commit }, params) {
        return new Promise((resolve, reject) => {
            shipmentTerms(params)
                .then(res => {
                    commit("SHIPMENT_TERMS", res.data.result.data);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    topSuccessfulDeals({ commit }, params) {
        return new Promise((resolve, reject) => {
            topSuccessfulDeals(params)
                .then(res => {
                    commit("TOP_SUCCESSFUL_DEALS", res.data.result.data);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    dealTypesChart({ commit }, params) {
        return new Promise((resolve, reject) => {
            dealTypesChart(params)
                .then(res => {
                    commit("DEAL_TYPES_CHART", res.data.result.data);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    getStageDeals({ commit }, params) {
        return new Promise((resolve, reject) => {
            getStageDeals(params)
                .then(res => {
                    commit("STAGE_DEALS", res.data.result.data.stageDeals);
                    commit("UPDATE_STAGE_DEAL_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    clientStatistics({ commit }, params) {
        return new Promise((resolve, reject) => {
            clientStatistics(params)
                .then(res => {
                    commit("CLIENT_STATISTICS", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    updateStageDealPagination({ commit }, pagination) {
        commit('UPDATE_STAGE_DEAL_PAGINATION', pagination)
    },
};
