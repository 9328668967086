import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    deal_id : {
        show: true,
        title: i18n.t('message.deal'),
        sortable: true,
        column: 'deal_id'
    },
    process : {
        show: true,
        title: i18n.t('message.process'),
        sortable: true,
        column: 'process'
    },
    user_id : {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user_id'
    },
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
