import {i18n} from '@/utils/i18n';

export const rules = {
    min_shipping_weight: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.min_shipping_weight')}), trigger: 'change' },
    ],
    iin_countries: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.iin_countries')}), trigger: 'change' },
    ],
    filial_order_ids: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filial_order_ids')}), trigger: 'change' },
    ],
    sender_filial_ids: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.sender_filial_ids')}), trigger: 'change' },
    ],
};
