export const model = {
  id : null,  
  name: '', 
  number: '', 
  is_active: true, 
  mfo: '', 
  currency_id: null, 
  created_at: '',
  updated_at: ''
};
  