export const filter = {
    id : '',  
    title: '', 
    name: '', 
    country_id: '', 
    city_id: '', 
    address: '', 
    phone_1: '', 
    phone_2: '', 
    phone_country_code_1: '', 
    phone_country_code_2: '', 
    created_at: '',
    updated_at: ''  
  };
  