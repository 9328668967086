export const model = {
    id: null,
    user_id: '',
    percentage: 0,
    min_deal_sum: 0,
    max_deal_sum: 0,
    currency_id: null,
    created_at: '',
    updated_at: '',
  };
  