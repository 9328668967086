import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    from_filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.from_where')}), trigger: 'change' },
    ],
    to_filial: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.to_where')}), trigger: 'change' },
    ],
};
