import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/dealProducts',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/dealProducts',
        method: 'post',
        data
    })
}
export function show(params) {
    return request({
        url: `/dealProducts/${params.id}`,
        method: 'get',
        params
    })
}

export function filialDealProducts(params) {
    return request({
        url: `/dealProducts/filialDealProducts`,
        method: 'get',
        params
    })
}

export function update(data) {
    return request({
        url: `/dealProducts/${data.deal_id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/dealProducts/${id}`,
        method: 'delete'
    })
}
export function deleteParcelProduct(id) {
    return request({
        url: `/dealProducts/deleteParcelProduct/${id}`,
        method: 'delete'
    })
}

export function waitingParcels(params) {
    return request({
        url: `/dealProducts/waitingParcels`,
        method: 'get',
        params
    })
}

export function getMeasuringTypes() {
    return request({
        url: `/dealProducts/getMeasuringTypes`,
        method: 'get',
    })
}

export function showParcelProducts(id) {
    return request({
        url: `/dealProducts/showParcelProducts/${id}`,
        method: 'get',
    })
}