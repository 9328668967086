import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    client_id : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client_id'
    },
    email : {
        show: true,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    track_code : {
        show: true,
        title: i18n.t('message.track_code'),
        sortable: true,
        column: 'track_code'
    },
    send_date : {
        show: true,
        title: i18n.t('message.send_date'),
        sortable: true,
        column: 'send_date'
    },
    user_id : {
        show: true,
        title: i18n.t('message.message_sent_by_user'),
        sortable: true,
        column: 'user_id'
    },
    deal_user : {
        show: true,
        title: i18n.t('message.created_by_user'),
        sortable: true,
        column: 'deal_user'
    },
    deal_id : {
        show: true,
        title: i18n.t('message.deal'),
        sortable: true,
        column: 'deal_id'
    },
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },

};
