import {i18n} from '@/utils/i18n';

export const rules = {
    client_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.client')}), trigger: 'change' },
    ],
    filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filial')}), trigger: 'change' },
    ],
    payment_type_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.paymentType')}), trigger: 'change' },
    ],
    money_amount: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.money_amount')}), trigger: 'change' },
    ],
    bank_account_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.bankAccount')}), trigger: 'change' },
    ],
    currency_rate: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.rate')}), trigger: 'change' },
    ],
    date: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.date')}), trigger: 'change' },
    ],
};
