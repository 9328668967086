export const filter = {
    id : '',  
    name: '', 
	  currency_id: null,
    service_category_id: null,
    calculation_type: null,
    calculate_by: null,
    created_at: '',
    updated_at: ''  
  };
  