export const filter = {
    id : '',  
    name: '', 
    average_days: null, 
    from_filial_id: '', 
    to_filial_id: '',
    for_systeam: null,
    for_site: null,
    shipping_type_id: null
  };
  