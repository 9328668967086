<template>
    <div>
        <div class="app-modal__box" v-loading="loadingData">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">
                        {{
                            $t("message.emails")
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div
                class="timeline-items__right rounded-sm alimer"
                style="margin: 20px"
            >
                <el-tabs v-model="activeName" class="tab-right">
                    <el-tab-pane :name="'tab-' + mailbox.id" v-for="mailbox in emailMessages" :key="mailbox.id">

                        <span slot="label">
                            {{ mailbox.email }}
                            <span class="udam">
                                 <i class="el-icon-message"></i> {{ mailbox.messages.length }}
                            </span>
                        </span>
                        <div>
                            <div class="toop-email-sorche">
                                <el-checkbox v-model="searchAllEmails" :label="$t('message.all_emails')" border></el-checkbox>
                                <div class="toop-email-sorche-right" > 
                                    <el-date-picker
                                        v-model="mailbox.dates"
                                        type="datetimerange"
                                        range-separator="/"
                                        :start-placeholder="$t('message.from')"
                                        :end-placeholder="$t('message.before')"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                    <el-button @click="searchEmailByDate(mailbox.id, mailbox.dates)" icon="el-icon-search" type="primary">{{$t('message.search')}}</el-button>
                                </div>
                            </div>

                            <select-email-deals
                                v-if=" mailbox.messages ? mailbox.messages.filter(item => item.save_email === true).length > 0 : false"
                                :deal_email="deal_email"
                                :size="'medium'"
                                placeholder="deals"
                                v-model="mailbox.deal_ids"
                            >
                            </select-email-deals>

                            <div v-for="message in mailbox.messages" :key="message.uid">
                                <div class="gc-card__body px-3 py-2">
                                    <div class="text-descr">
                                        <span class="text-descr__label d-block">
                                            <el-checkbox v-model="message.save_email">
                                                {{ message.email_from }}
                                            </el-checkbox>
                                            <span>
                                                <i class="el-icon-document ml-5"> - {{ message.attachments_count }}</i>
                                            </span>
                                            <span>
                                                <i class="el-icon-message ml-5" :class="message.flag" > - {{ $t('message.' + message.flag)  }}</i>
                                            </span>
                                        </span>
                                        <div
                                            class="d-flex align-center font-medium"
                                        >
                                            <div
                                                class="
                                                    text-descr__phone
                                                    f-fill
                                                    text-reset
                                                "
                                                style="
                                                    font-size: 12px;
                                                    font-weight: 300;
                                                "
                                            >
                                                {{ message.text }}
                                            </div>
                                            <span class="phone-detect pl-2 w-15q">
                                                {{ message.date }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectEmailDeals from "@/components/multiSelects/multi-select-deals";

export default {
    mixins: [form, drawer],
    components: {
        selectEmailDeals
    },
    props: {
        deal_id: {
            default: null,
        },
        deal_email: {
            default: null,
        },
        type_id: {
            default: null,
        },
    },
    data() {
        return {
            activeName: "first",
            checked: false,
            region: '',
            loadingButton: false,
            loadingData: false,
            emailMessages: [],
            deal_ids: [],
            form: [],
            loadingTab: false,
            searchAllEmails: false
        };
    },
    computed: {
        ...mapGetters({
            all_emails_messages: "dealCorrespondences/all_emails_messages",
        }),
    },
    methods: {
        ...mapActions({
            updateList: "dealCorrespondences/getEmailsCount",
            save: "dealCorrespondences/saveEmailToDeal",
        }),
        afterOpen(){
            this.emailMessages = JSON.parse(JSON.stringify(this.all_emails_messages));
            if(this.emailMessages.length > 0){
                this.activeName = "tab-" + this.emailMessages[0].id;
            }
        },
        searchEmailByDate(email_id, dates){
            if(this.deal_email){
                if(dates){
                    const date1 = new Date(dates[0]);
                    const date2 = new Date(dates[1]);
                    let diffTime = Math.abs(date2 - date1);
                    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    if((dates && email_id && diffDays <= 10 && !this.searchAllEmails) || (dates && email_id && this.searchAllEmails && diffDays <= 4)){

                        let email_ids = [email_id];
                        if(this.searchAllEmails){
                            email_ids = this.emailMessages.map(item => item.id);
                        }

                        this.loadingData = true;
                        this.updateList({type_id: this.type_id, deal_id: this.deal_id, email_ids: email_ids, dates: dates})
                            .then(res => {
                                this.emailMessages = JSON.parse(JSON.stringify(this.all_emails_messages));
                                this.loadingData = false;
                            }).catch(err => {
                                this.$notify({
                                    title: this.$t('message.error_m'),
                                    type: "error",
                                    offset: 130,
                                    message: this.$t('message.something_wrong_with_connection_of_email')
                                });
                                this.loadingData = false;
                            })
                    }else if(diffDays > 4 && this.searchAllEmails){
                        this.$notify({
                            title: this.$t('message.error_m'),
                            type: "warning",
                            offset: 130,
                            message: this.$t('message.Date difference should not be more than 4 days to search all email addresses !')
                        });
                    }else{
                        this.$notify({
                            title: this.$t('message.error_m'),
                            type: "warning",
                            offset: 130,
                            message: this.$t('message.The date difference should not be more than 10 days for the search!')
                        });
                    }

                }else{
                    this.$notify({
                            title: this.$t('message.error_m'),
                            type: "warning",
                            offset: 130,
                            message: this.$t('message.no_data_to_search')
                        });
                }
            }else{
                this.$notify({
                    title: this.$t('message.error_m'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.no_email_to_search_messages')
                });
            }
        },
        submit(close){
            let filter_email = JSON.parse(JSON.stringify(this.emailMessages));
            let sending_email = [];
            let selected_messages_count = 0;
            let selected_deal_ids_count = 0;
            filter_email.forEach((element, index) => {
                let selected_email_messages = {};
                let filtered_messages = element.messages.filter(message => message.save_email === true);
                if(element.deal_ids.length > 0){
                    selected_deal_ids_count += element.deal_ids.length;
                }
                if(filtered_messages.length > 0) {
                    selected_messages_count += filtered_messages.length;
                }
                
                selected_email_messages.email_id = element.id;
                selected_email_messages.correspondence_type_id = this.type_id;
                selected_email_messages.deal_id = this.deal_id;
                selected_email_messages.deal_ids = element.deal_ids;
                selected_email_messages.messages = filtered_messages;
                sending_email.push(selected_email_messages);
            });
            
            if(this.deal_email){
                if(sending_email.length > 0 && selected_messages_count > 0 && selected_deal_ids_count > 0){
                    this.loadingButton = true; 
                    this.save({selected_messages: sending_email})
                        .then(res => {
                            this.parent().emptyDealCorrespondences()
                                .then(() => {
                                    this.parent().fileList = [];
                                    this.parent().getMore();
                                });
                            this.$alert(res);
                            this.loadingButton = false;
                            this.emailMessages = JSON.parse(JSON.stringify(this.all_emails_messages));
                            if(close) this.close();
                        })
                        .catch(err => {
                            this.loadingButton = false; 
                        });
                }else if(sending_email.length === 0){
                    this.$message({
                        type: "warning",
                        message: this.$t('message.no_email')
                    });
                }else if(selected_messages_count === 0){
                    this.$message({
                        type: "warning",
                        message: this.$t('message.no_selected_deal_to_save')
                    });
                }else if(selected_deal_ids_count === 0){
                    this.$message({
                        type: "warning",
                        message: this.$t('message.no_selected_deal_to_save')
                    });
                }
            }else{
                this.$notify({
                    title: this.$t('message.error_m'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.no_email_to_search_messages')
                });
            }
            
        }
    }
};
</script>
<style>
.toop-email-sorche{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 7%);
}
.toop-email-sorche-right{
    display: flex;
    align-items: center;
}
.toop-email-sorche-right .el-date-editor{
    min-width: 650px;
    margin-right: 10px;
}
.w-15q{ 
    width: 150px;
    display: inline-block;
}
.Unseen{
    color: red;
    font-weight: 600;
}
.Seen{
    color: rgb(7, 211, 68);
    font-weight: 600;
}
.Answered{
    color: #409eff;
    font-weight: 600;
}
</style>