import { index, inventory, show, store, update, destroy, setAirwayBillNumber, readyToSendToFlight, printContainerBarcode, scanContainer,
    sendReadyContainersToFlight, setContainersInFilial, showIncomingFilialContainers, storeContainerProductsInFilial,
    removeProductFromContainer, removePackageFromContainer, getAllPackageAndProductsToSend } from "@/api/warehouses/containers";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.containers);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.containers);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    showIncomingFilialContainers({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            showIncomingFilialContainers(params)
                .then(res => {
                    commit("SET_INCOMING_FILIAL_CONTAINER", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    printContainerBarcode({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            printContainerBarcode(params)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },

    scanContainer({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            scanContainer(params)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.containers);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, params) {
        return new Promise((resolve, reject) => {
            show(params)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.container);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    readyToSendToFlight({ commit }, params) {
        return new Promise((resolve, reject) => {
            readyToSendToFlight(params)
                .then(res => {
                    commit('SET_READY_TO_FLIGHT', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    sendReadyContainersToFlight({ commit }, data) {
        return new Promise((resolve, reject) => {
            sendReadyContainersToFlight(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getAllPackageAndProductsToSend({ commit }, params) {
        return new Promise((resolve, reject) => {
            getAllPackageAndProductsToSend(params)
                .then(res => {
                    commit('SET_ALL_PACKAGES_AND_PRODUCTS', res.data.result.data.products);
                    commit("UPDATE_FILIAL_PRODUCTS_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    setContainersInFilial({ commit }, data) {
        return new Promise((resolve, reject) => {
            setContainersInFilial(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    storeContainerProductsInFilial({ commit }, data) {
        return new Promise((resolve, reject) => {
            storeContainerProductsInFilial(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    setAirwayBillNumber({ commit }, data) {
        return new Promise((resolve, reject) => {
            setAirwayBillNumber(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            commit("EMPTY_INVENTARIZATION");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    removeProductFromContainer({ commit }, params) {
        return new Promise((resolve, reject) => {
            removeProductFromContainer(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    removePackageFromContainer({ commit }, params) {
        return new Promise((resolve, reject) => {
            removePackageFromContainer(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    updateFilialProductsPagination({ commit }, pagination) {
        commit('UPDATE_FILIAL_PRODUCTS_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
