<template>
    <div>
        <div class="khan-chart-a">
            <div id="chart">
                <apexchart
                    type="bar"
                    height="350"
                    :options="apexChartsBar.chartOptions"
                    :series="apexChartsBar.series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import { formatMoney } from "@/filters/index";

export default {
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        }
    },
    data() {
        return {
            radio1: "Неделя",
            apexChartsBar: {
                series: [
                    {
                        name: 'sale',
                        data: [],
                    },
                    {
                        name: 'profit',
                        data: [],
                    },
                ],
                chartOptions: {
                    chart: {
                        type: "bar",
                        height: 350,
                        toolbar: {
                            show: true,
                        },
                    },

                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "70%",
                            endingShape: "rounded",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            rotate: -50,
                            maxHeight: 80,

                            style: {
                                cssClass: "apexcharts-xaxis-label",
                                fontSize: "10px",
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return formatMoney(val);
                            },
                        },
                        
                    },
                    fill: {
                        opacity: 1,
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return formatMoney(val);
                            },
                        },
                        
                    },
                },
            },
        };
    },

    watch: {
        watchAll: {
            handler: async function(val) {
                // setTimeout(() => {
                //     this.updateChartData({period: this.period, filter: this.filterForm});
                // }, 500);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            saleByPeriod: "home/saleByPeriod"
        }),

        watchAll() {
            return  (JSON.stringify(this.filterForm) + this.period);
        }
    },
    methods: {
        ...mapActions({
            saleByPeriodDiagram: "home/saleByPeriodDiagram"
        }),

        updateChartData(data){
            // this.debouncedFetchData(data, this)
        },

        debouncedFetchData: _.debounce((data, self) => {
            if (_.isFunction(self.saleByPeriodDiagram)) {
                self.saleByPeriodDiagram(data)
                    .then(() => {
                        self.apexChartsBar.series = [
                            {
                                name: self.$t('message.sale'),
                                data: self.saleByPeriod.money_amounts,
                            },
                            {
                                name: self.$t('message.profit'),
                                data: self.saleByPeriod.profit_money_amounts,
                            },
                        ]
                        self.apexChartsBar.chartOptions.xaxis.categories = self.saleByPeriod.dates

                        self.apexChartsBar = {...self.apexChartsBar, ...{
                            chartOptions: {
                                xaxis: {
                                    categories: self.saleByPeriod.dates
                                }
                            },
                        }}
                    
                    });
            }
        }, 500),
    }
};
</script>
